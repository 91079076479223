// components/CodeBlock.tsx

import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface CodeBlockProps {
    code: string;
}

const CodeBlock: FC<CodeBlockProps> = ({ code }) => {
    return (
        <Box
            component="pre"
            sx={{
                backgroundColor: '#2d2d2d',
                color: '#f8f8f2',
                padding: 2,
                borderRadius: 1,
                overflowX: 'auto',
                fontFamily: 'monospace',
            }}
        >
            <Typography variant="body2" component="code">
                {code}
            </Typography>
        </Box>
    );
};

export default CodeBlock;
