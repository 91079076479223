import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';
import { CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import Info from './Info';

export const panel_Part1 = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>

                <Info></Info>

            </div>

        </>
    )
}
