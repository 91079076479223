/*
    this.minY      =  1.25;
         this.maxY = 2.26;
         this.minZ = 18.26;
         this.maxZ = 19.26;
*/

import * as THREE from 'three';
import { MathUtils } from 'three';

class OrbitalCamera {
    constructor(camera, target = new THREE.Vector3(0, 0, 0)) {
        this.camera = camera;
        this.target = target;
        this.currentPosition = new THREE.Vector3();
        this.currentLookAt = new THREE.Vector3();
        this.spherical = new THREE.Spherical();
        this.sphericalDelta = new THREE.Spherical();
        this.lerpFactor = 0.1;
        this.minPolarAngle = 0; // Minimum polar angle (top view)
        this.maxPolarAngle = Math.PI; // Maximum polar angle (bottom view)

        // Set clamping values for spherical coordinates
        this.minPhi =1.465;
        this.maxPhi = 1.67;
        this.minTheta = 18.26;
        this.maxTheta =19.26;
        this.minRadius = 10;
        this.maxRadius = 55;

        // Set initial position
        this.spherical.set(50, Math.PI / 4, 0);
        this.updateCameraPosition();
    }

    updateCameraPosition() {
        const sinPhiRadius = Math.sin(this.spherical.phi) * this.spherical.radius;
        const position = new THREE.Vector3(
            sinPhiRadius * Math.sin(this.spherical.theta),
            this.spherical.radius * Math.cos(this.spherical.phi),
            sinPhiRadius * Math.cos(this.spherical.theta)
        );
        position.add(this.target);
        this.currentPosition.lerp(position, this.lerpFactor);
        this.camera.position.copy(this.currentPosition);
    }

    setTarget(newTarget) {
        this.target.copy(newTarget);
    }

    rotate(deltaTheta, deltaPhi) {
        this.sphericalDelta.theta -= deltaTheta;
        this.sphericalDelta.phi -= deltaPhi;
    }

    zoom(delta) {
        this.spherical.radius += delta;
        this.spherical.radius = Math.max(this.minRadius, Math.min(this.maxRadius, this.spherical.radius));
    }

    update() {
        this.spherical.theta += this.sphericalDelta.theta * this.lerpFactor;
        this.spherical.phi += this.sphericalDelta.phi * this.lerpFactor;

        // Constrain the polar angle (phi) and azimuthal angle (theta)
        this.spherical.phi = Math.max(this.minPhi, Math.min(this.maxPhi, this.spherical.phi));
        this.spherical.theta = Math.max(this.minTheta, Math.min(this.maxTheta, this.spherical.theta));

        this.sphericalDelta.set(0, 0, 0);

        this.updateCameraPosition();
        this.currentLookAt.lerp(this.target, this.lerpFactor);
        this.camera.lookAt(this.currentLookAt);
    }
}

export default OrbitalCamera;
