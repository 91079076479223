import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Iconify from 'src/components/iconify';
import { varFade, MotionViewport } from 'src/components/animate';
import { minWidth } from '@mui/system';

// ----------------------------------------------------------------------

/*
const FEATURE_HIGHLIGHTS = [
    { title: '5 Prebuilt Websites', icon: 'carbon:application-web' },
    { title: '60+ Demo Page', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Easy to Customize', icon: 'carbon:settings-adjust' },
    { title: 'Color Presets', icon: 'carbon:color-switch' },
    { title: 'Dark Mode', icon: 'carbon:asleep' },
    { title: 'Awesome Animation', icon: 'carbon:boolean' },
    { title: 'Google Fonts', icon: 'carbon:text-font' },
    { title: 'Figma Design', icon: 'ph:figma-logo' },
    { title: 'Fully Responsive', icon: 'carbon:devices' },
    { title: 'Mega Menu', icon: 'carbon:list-dropdown' },
    { title: 'Clean Markup', icon: 'carbon:script' },
    { title: 'Free Updates', icon: 'carbon:update-now' },
    { title: 'Fast Support', icon: 'carbon:headset' },
    { title: 'Well Documented', icon: 'carbon:notebook' },
];
*/
const FEATURE_HIGHLIGHTS = [
    { title: 'Web Design', icon: 'carbon:application-web' },
    { title: 'Web Development', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Full Responsive', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Frontend', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Backend', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Application', icon: 'carbon:settings-adjust' },
    { title: 'Interactive experiences', icon: 'carbon:color-switch' },
    { title: 'Game', icon: 'carbon:asleep' },
    { title: 'Event campaigns', icon: 'carbon:boolean' },

];

// ----------------------------------------------------------------------

export default function Features() {
    return (
        <Container
            component={MotionViewport}

            sx={{
                pt: { xs: 5, md: 10 },
                pb: { xs: 10, md: 15 },
            }}
        >
            <Grid container spacing={{ xs: 8, md: 3 }} justifyContent={{ md: 'space-between' }}>
                <Grid
                    xs={12}
                    md={4}
                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    <m.div >
                        <Typography fontFamily='Glacial' fontWeight="100" variant="h2" sx={{ my: 3 }}>

                            Our services for <b>your needs</b>
                        </Typography>
                    </m.div>

                    <m.div >
                        <Typography sx={{ color: '#ffffff' }}>
                            {`See the services that could suit your business needs.`}
                        </Typography>
                    </m.div>
                </Grid>

                <Grid xs={12} md={7}>
                    <Box
                        sx={{
                            rowGap: 6,
                            columnGap: 3,
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: 'repeat(2, 1fr)',
                                sm: 'repeat(3, 1fr)',
                            },
                        }}
                    >
                        {FEATURE_HIGHLIGHTS.map((feature) => (
                            <m.div>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Iconify icon={feature.icon} width={32} />

                                    <Typography variant="subtitle2" component="div" sx={{ mt: 2 }}>
                                        {feature.title}
                                    </Typography>
                                </Box>
                            </m.div>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
