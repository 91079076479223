import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import ReloadIcon from '@mui/icons-material/Autorenew';
import HealthIcon from '@mui/icons-material/Favorite';

interface UpgradeMenuProps {
  waveNumber: number;
  onUpgradeSelect: (upgrade: string) => void;
  onNextWave: () => void;
  onClose: () => void;
  isVisible: boolean;
}

const upgrades = [
  { name: 'Increased Ammo', description: 'Increases ammo capacity by 2 rounds', icon: <BlurOnIcon sx={{color:'#242424', fontSize: 48 }} /> },
  { name: 'Faster Reload', description: 'Reduces reload time by 0.5 seconds', icon: <ReloadIcon sx={{color:'#242424', fontSize: 48 }} /> },
  { name: 'Increased Health', description: 'Restores 50 health', icon: <HealthIcon sx={{ color:'#242424', fontSize: 30 }} /> },
];

const UpgradeMenu: React.FC<UpgradeMenuProps> = ({ waveNumber, onUpgradeSelect, onNextWave, onClose, isVisible }) => {
  const [randomUpgrades, setRandomUpgrades] = useState(upgrades);

  useEffect(() => {
    const shuffleAndSelectUpgrades = () => {
      const shuffled = upgrades.sort(() => 0.5 - Math.random());
      setRandomUpgrades(shuffled.slice(0, 2));
    };

    shuffleAndSelectUpgrades();
  }, [waveNumber]);

  return (
    <Box
      sx={{
        backdropFilter: 'blur(10px)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        backgroundColor: 'transparent', // Fully transparent background
        padding: { xs: '16px', md: '0px' }, // Responsive padding
      }}
    >
      <Typography
        fontFamily={'roboto-mono'}
        variant="h4"
        sx={{ 
          mb: 2, 
          fontWeight: 'bold',
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } // Responsive font size
        }}
      >
        WAVE {waveNumber + 1}
      </Typography>
      <Typography
        fontFamily={'roboto-mono'}
        variant="h6"
        sx={{ 
          mb: 4,
          fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } // Responsive font size
        }}
      >
        Which upgrade would you like?
      </Typography>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: { xs: 2, sm: 4 },  // Adjust gap based on screen size
          flexDirection: { xs: 'column', sm: 'row' }  // Stack buttons vertically on small screens
        }}
      >
        {randomUpgrades.map((upgrade, index) => (
          <Button
            key={upgrade.name}
            onClick={() => {
              onUpgradeSelect(upgrade.name);
              onNextWave();
            }}
            sx={{
              fontWeight: 1000,
              width:'200px', // Full width on mobile, fixed width on larger screens
              height: 'auto', // Adjusted height for mobile
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'transparent',
              color: 'white',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
              borderRadius: 2,
            }}
          >
           <Box
  sx={{
    width: 60,
    height: 60,
    borderRadius: '50%',
    bgcolor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 1,
    padding: 0,  // Ensure no extra padding
    boxSizing: 'border-box',  // Include padding/border in the element’s width and height
  }}
>
  {upgrade.icon}
</Box>
  
            <Typography 
              fontFamily={'roboto-mono'} 
              variant="h6" 
              fontWeight={'999'}
              sx={{ mb: 1, fontSize: { xs: '1rem', sm: '1.25rem' } }} // Responsive font size
            >
              {upgrade.name}
            </Typography>
            <Typography 
              fontFamily={'roboto-mono'} 
              variant="body2" 
              align="center" 
              paddingTop="20px"
              sx={{ px: 2, fontSize: { xs: '0.0rem', sm: '1rem' } }} // Responsive font size
            >
              {upgrade.description}
            </Typography>
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default UpgradeMenu;
