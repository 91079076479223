

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

const TopbarParade = () => {
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Link
                    underline="none"
                    component="a"
                    href="/"
                    fontFamily={'Glacial'}
                    style={{ letterSpacing: '5px' }}
                >
                    <Typography fontFamily="Akira"
                        paddingBottom="30px"
                        paddingTop="25px"
                        align="left"
                        color={'#E71E59FF'}
                        variant="h3"
                        lineHeight={'0.0rem'}
                        fontSize="1.95rem"
                        fontWeight="100"
                        fontStyle={'regular'}>

                    </Typography>


                </Link>
            </Box>

            <Box paddingRight="0px">
                <Link
                    underline="none"
                    component="a"
                    href="mailto: theon@artcodestudio.co" target="_blank"

                    fontFamily={'Glacial'}
                    style={{ letterSpacing: '5px' }}
                >

                </Link>
            </Box>

        </Box>
    );
};

TopbarParade.propTypes = {
};

export default TopbarParade;
