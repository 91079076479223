import * as THREE from "three";

const AtmosphericSkyShader = {
    name: 'AtmosphericSkyShader',
    uniforms: {
        time: { value: 0.0 },
        topColor: { value: new THREE.Color(0xdddddd) }, // Light sky blue
        bottomColor: { value: new THREE.Color(0x595959) }, // Soft orange at horizon
        sunColor: { value: new THREE.Color(0xde9e62) }, // Sun color
        sunPosition: { value: new THREE.Vector3(10.0, 100.0, 100.0) },
        sunIntensity: { value: 1.25 }
    },

    vertexShader: `
        varying vec3 vWorldPosition;

        void main() {
            vec4 worldPosition = modelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;

            gl_Position = projectionMatrix * viewMatrix * worldPosition;
        }
    `,

    fragmentShader: `
        uniform float time;
        uniform vec3 topColor;
        uniform vec3 bottomColor;
        uniform vec3 sunColor;
        uniform vec3 sunPosition;
        uniform float sunIntensity;

        varying vec3 vWorldPosition;

        void main() {
            float heightFactor = (normalize(vWorldPosition).y);

            // Sky color gradient
            vec3 skyColor = mix(bottomColor, topColor, smoothstep(0.0, 1.0, heightFactor));

            // Sun effect
            vec3 toSun = normalize(sunPosition - vWorldPosition);
            float sunAmount = max(dot(normalize(vWorldPosition), toSun), 0.0) ;
            vec3 sunEffect = sunColor * pow(sunAmount, sunIntensity);

            // Final color with sun effect
            vec3 finalColor = skyColor + sunEffect;

            gl_FragColor = vec4(finalColor, 1.0);
        }
    `
};

export { AtmosphericSkyShader };
