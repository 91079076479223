import * as THREE from "three";

const PropShader = {
	name: 'PropShader',
	uniforms: {
	},

	vertexShader: [

		`
		varying vec2 vUv;
		varying vec3 vNormal;

		void main()
		{
			vec4 modelPosition = modelMatrix * vec4(position, 1.0);
			vec4 viewPosition = viewMatrix * modelPosition;
			vec4 projectionPosition = projectionMatrix * viewPosition;
			gl_Position = projectionPosition;

			vNormal = normal;
			vUv = uv;
		}`

	].join("\n"),

	fragmentShader: [
		`
		uniform vec3 downsideColor;

		varying vec2 vUv;
		varying vec3 vNormal;
		vec2 matcap(vec3 eye, vec3 normal) {
			vec3 reflected = reflect(eye, normal);
			float m = 2.8284271247461903 * sqrt( reflected.z+1.0 );
			return reflected.xy / m + 0.5;
		  }
		void main()
		{

			vec3 downsideColor3 = vec3(1,1,1);
			vec3 downsideColor2 = vec3(1,1,1);
			vec3 sunDir =normalize(vec3(0,1,0));
			float directionalLightIntensity = max(0.0, dot(vNormal, normalize(-sunDir))) *0.2;
			vec3 sunDir2 =normalize(vec3(0.3,0,1));
			vec3 sunDir3 =normalize(vec3(-0.3,1,1));
			float directionalLightIntensity2 = max(0.0, dot(vNormal, normalize(-sunDir2))) * 0.25;
			float directionalLightIntensity3 = max(0.0, dot(vNormal, normalize(sunDir3))) * 0.15;
			vec3 Color = downsideColor;
			Color += vec3(0.00,0.00,0.0) +  downsideColor3 * directionalLightIntensity3;
			gl_FragColor = vec4(Color, 1.0);
		}
		`

	].join("\n")

};
export { PropShader };
