export default [
    {
        name: 'base',
        data: {},
        items:
            [

                { name: 'paletteTexture', source: '/assets/palette.jpg', type: 'texture' },
                { name: 'bgModel', source: '/assets/bg_1.glb' },
                { name: 'titleModel', source: '/assets/title_1.glb' },

                //uob prototype
                { name: 'uobModel', source: '/assets/uob_model.glb' },
                { name: 'uobPlaneModel', source: '/assets/uob_model_floor.glb' },

                { name: 'uobTexture', source: '/assets/uob.jpg', type: 'texture' },

                // island asset
                //{ name: 'uobModel', source: '/assets/uob_model.glb' },
                //{ name: 'uobPlaneModel', source: '/assets/uob_model_floor.glb' },

                //{ name: 'uobTexture', source: '/assets/uob.jpg', type: 'texture' },


            ]
    }
]
