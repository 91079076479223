// AudioManager.ts
import { Howl } from 'howler';

class AudioManager {
  private static instance: AudioManager;
  private sounds: { [key: string]: Howl } = {};

  private constructor() {
    // Prevent instantiation
  }

  public static getInstance(): AudioManager {
    if (!AudioManager.instance) {
      AudioManager.instance = new AudioManager();
    }
    return AudioManager.instance;
  }
  public playRandomPrepareSound() {
    const randomIndex = Math.floor(Math.random() * 3) + 1; // Generates a number between 1 and 3
    const soundName = `prepare_${randomIndex}`;
    this.playSound(soundName);
  }

  public loadSounds() {
    this.sounds['shoot'] = new Howl({
      src: ['/sounds/fire_1.wav'],
      volume: 0.125,
    });
    this.sounds['shoot_pistol'] = new Howl({
      src: ['/sounds/fire_pistol.wav'],
      volume: 0.075,
    });
    this.sounds['beep'] = new Howl({
    src: ['/sounds/beep.wav'],
        volume: 0.125,
      });
      this.sounds['explode'] = new Howl({
          src: ['/sounds/explode.wav'],
          volume: 0.125,
        });
    this.sounds['reload_out'] = new Howl({
      src: ['/sounds/rifle_out.wav'],
      volume: 0.125,
    });
    this.sounds['reload_in'] = new Howl({
        src: ['/sounds/rifle_in.wav'],
        volume: 0.125,
      });
      this.sounds['prepare_1'] = new Howl({
        src: ['/sounds/rifle_prepare_1.wav'],
        volume: 0.125,
      });
      this.sounds['prepare_2'] = new Howl({
        src: ['/sounds/rifle_prepare_2.wav'],
        volume: 0.125,
      });
      this.sounds['prepare_3'] = new Howl({
        src: ['/sounds/rifle_prepare_3.wav'],
        volume: 0.125,
      });
    console.log("loading sound");

    // Load other sounds as needed
  }

  public playSound(name: string) {
    const sound = this.sounds[name];
    if (sound) {
        console.log(`sound play: ${name}`);
      sound.play();
    } else {
      console.error(`Sound ${name} not found!`);
    }
  }
}

export default AudioManager.getInstance();