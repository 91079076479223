import * as THREE from "three";

const PlayerAnimationShader = {
  uniforms: {
    idleTexture: { value: null }, // Idle texture
    movementTexture: { value: null }, // Movement texture
    frameIndex: { value: 0 }, // Frame index for the animation
    atlasGrid: { value: new THREE.Vector2(10, 10) }, // Grid size of the texture atlas
    isMoving: { value: false }, // Flag to determine which texture to use
    colorMultiplier: { value: new THREE.Vector4(1.0, 1.0, 1.0,1.0) }, // Color multiplier
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D idleTexture;
    uniform sampler2D movementTexture;
    uniform sampler2D deathTexture;

    uniform int frameIndex;
    uniform vec2 atlasGrid;
    uniform bool isMoving;
    uniform bool isDead;

    uniform vec4 colorMultiplier;
    varying vec2 vUv;

    void main() {
      vec2 uv = vUv;
      
      // Calculate the UV offset based on the current frame index
      int columns = int(atlasGrid.x);
      int rows = int(atlasGrid.y);
      int totalFrames = columns * rows;
      int currentFrame = frameIndex % totalFrames;
      
      // Calculate the row and column index for the current frame
      int column = currentFrame % columns;
      int row = currentFrame / columns;

      // Calculate the offset UV coordinates
      vec2 frameUv = vec2(
        (float(column) + uv.x) / atlasGrid.x,
        (float(row) + uv.y) / atlasGrid.y
      );

      // Choose the correct texture based on movement state
      vec4 color;
      if(isDead) {
            color = texture2D(deathTexture, frameUv);
      }else{
       if (isMoving) {
              color = texture2D(movementTexture, frameUv);
            } else {
              color = texture2D(idleTexture, frameUv);
            }
      }
 if(color.a<0.5)
      {
      discard;
      }
      // Apply the color multiplier
      color.rgba *= colorMultiplier;

      gl_FragColor = color;
    }
  `,
  transparent: true,
};

export default PlayerAnimationShader;
