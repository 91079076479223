import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Button, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box, Typography, Paper } from '@mui/material';
import { useScroll } from 'framer-motion';
import gsap from 'gsap';
import CodeMirror from '@uiw/react-codemirror';

import './Home.css';
import ContentUob from './ContentUob';
import TopbarParade from '../components/TopBarParade';
import Bottombar from '../components/BottomBar';
import LoadingManager from '../Render/LoadingManager';
import MovingFrames from 'src/components/Scenes/MovingFrames';
import Overlay from './Overlay';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import LightModeIcon from '@mui/icons-material/LightMode';
import FlareIcon from '@mui/icons-material/Flare';
import InfoIcon from '@mui/icons-material/Info';
import CodeIcon from '@mui/icons-material/Code';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import ImageIcon from '@mui/icons-material/Image';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


import voyageCode from './example/movingFramesExample';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

import { useMediaQuery } from '@mui/material';

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const MovingFramesPage: React.FC = () => {
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [isSectionActive, setIsSectionActive] = useState(false);

  const movingFramesRef = useRef<any>(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const buttonColors = {
    fireworksRight: '#00000044',
    parachute: '#00000044',
    helicopter: '#00000044',
    p3: '#00000044',
    p4: '#00000044',
  };

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  }

  const { scrollYProgress } = useScroll();

  const handleLeftClick = () => {
    if (movingFramesRef.current) {
      movingFramesRef.current.changeImage('prev');
    }
  };

  const handleRightClick = () => {
    if (movingFramesRef.current) {
      movingFramesRef.current.changeImage('next');
    }
  };
  const handleButtonClick = (action: string) => {
    if (movingFramesRef.current && movingFramesRef.current[action]) {
      movingFramesRef.current[action]();
    }
  };
  const handleInfoButtonClick = () => {
    setShowInfoPopup(true);
    setIsSectionActive(true);
    if (movingFramesRef.current) {
      movingFramesRef.current.setSectionActive(true); // Update the state within Voyage
    }
  };
  
  const handleCodeButtonClick = () => {
    setShowCodePopup(true);
    setIsSectionActive(true);
    if (movingFramesRef.current) {
      movingFramesRef.current.setSectionActive(true); // Update the state within Voyage
    }
  };
  
  const closePopup = () => {
    setShowInfoPopup(false);
    setShowCodePopup(false);
    setIsSectionActive(false);
    if (movingFramesRef.current) {
      movingFramesRef.current.setSectionActive(false); // Reset the state within Voyage
    }
  };
  useEffect(() => {
    console.log('isSectionActive changed:', isSectionActive);
  }, [isSectionActive]);
  useEffect(() => {
    if (movingFramesRef.current) {
      movingFramesRef.current.setButtonVisibilityCallback(() => {
        setShowButtons(true);
        const buttons = document.querySelectorAll('.action-button');
        buttons.forEach((button, index) => {
          gsap.fromTo(button, { scale: 0 }, { scale: 1, duration: 0.5, delay: index * 0.1 });
        });
      });
    }
  }, []);
  const [code, setCode] = useState('');

  useEffect(() => {
    // Load the code content when the component mounts
    setCode(voyageCode);
  }, []);

  const isLandscape = useMediaQuery('(orientation: landscape)');

  return (
    <div className="pageGlobal">
      <LoadingManager />
      <Grid container style={{ position: 'fixed', zIndex: -1 }}>
      <MovingFrames
  ref={movingFramesRef}
/>
      </Grid>

      <HideOnScroll>
        <AppBar position={'fixed'} sx={{ boxShadow: 'none', backgroundColor: '#00000000' }}>
          <TopbarParade />
        </AppBar>
      </HideOnScroll>

      <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
        <Bottombar />
      </AppBar>

      <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
      {!isSectionActive && isLandscape && (
  <>
    <Box
      sx={{
        position: 'fixed',
        top: '15%',
        left: '10%',
        zIndex: 2,
        backgroundColor: 'rgba(1, 1, 1, 0.35)', // Semi-transparent black background
        padding: '8px 16px', // Padding inside the box
        borderRadius: '8px', // Optional: add rounded corners
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          fontWeight: '100',
          fontFamily: 'roboto-mono',
        }}
      >
        <FlareIcon
          sx={{
            width: 20,
            height: 20,
            marginRight: 1,
          }}
        />
        VOYAGE - VISUAL EXPERIMENTS
      </Typography>
    </Box>
    <Box
      sx={{
        position: 'fixed',
        top: '15%',
        right: '10%',
        zIndex: 2,
        backgroundColor: 'rgba(1, 1, 1, 0.35)',
        padding: '8px 16px',
        borderRadius: '8px',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          fontWeight: '100',
          fontFamily: 'roboto-mono',
          textAlign: 'right',
        }}
      >
        CHAPTER 2 - Moving Frames
        <ImageIcon
          sx={{
            width: 20,
            height: 20,
            marginLeft: 1,
          }}
        />
      </Typography>
    </Box>
  </>
)}

        {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
          <Button
            variant='contained'
            onClick={() => {
              setInfoValue(contentValue);
              setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
            }}
            sx={{
              position: 'fixed',
              justifyContent: 'center',
              alignItems: 'center',
              bottom: 100,
              mb: '30px',
              height: '40px',
              fontFamily: 'DMSans',
              fontSize: '1.0rem',
              fontWeight: '100',
              color: '#000000',
              backgroundColor: '#ffffff',
              borderRadius: '30px',
              boxShadow: 'none',
            }}>
            Click to see {buttonInfo}
          </Button>
        }
     
        <Box component="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: 2 }}>
        </Box>
      </Grid>

      {showButtons && !isSectionActive && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            position: 'fixed',
            top: '90%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <Grid item>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => handleLeftClick()}
              sx={{
                width: 60,
                height: 60,
                borderRadius: '50%',
                backgroundColor: buttonColors.fireworksRight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#666666', // Change hover color to gray
                },
              }}
            >
              <ArrowCircleLeftIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="action-button"
              variant="contained"
              onClick={() => handleRightClick()}
              sx={{
                width: 60,
                height: 60,
                borderRadius: '50%',
                backgroundColor: buttonColors.parachute,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#666666', // Change hover color to gray
                },
              }}
            >
              <ArrowCircleRightIcon />
            </Button>
          </Grid>
          <Grid item>
           
          </Grid>
          <Grid item>
            <Button
              className="action-button"
              variant="contained"
              onClick={handleInfoButtonClick}
              sx={{
                width: 60,
                height: 60,
                borderRadius: '50%',
                backgroundColor: buttonColors.p3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#666666', // Change hover color to gray
                },
              }}
            >
              <InfoIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="action-button"
              variant="contained"
              onClick={handleCodeButtonClick}
              sx={{
                width: 60,
                height: 60,
                borderRadius: '50%',
                backgroundColor: buttonColors.p4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#666666', // Change hover color to gray
                },
              }}
            >
              <CodeIcon />
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Custom Info Popup */}
      {showInfoPopup && (
      <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300,
      }}
      onClick={closePopup}
    >
      <Paper
        onClick={(e) => e.stopPropagation()}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.0)', // Fully transparent background
          backdropFilter: 'blur(15px)', // Apply blur effect
          padding: '20px',
          maxWidth: '600px',
          width: '80%',
          maxHeight: '65vh', // Restrict the height of the content area
          overflowY: 'auto', // Make the content scrollable
        }}
      >
        <Typography
          fontFamily={'monospace'}
          fontSize={'1.0rem'}
          variant="h5"
          sx={{ color: '#ffffff', fontWeight: 'bold', marginBottom: '10px' }}
        >
          CHAPTER 2 - Moving Frames
        </Typography>
        <Typography
          fontFamily={'monospace'}
          variant="body2"
          sx={{ color: '#ffffff', marginBottom: '10px' }}
        >
This chapter takes a step back to embrace simplicity, focusing on the pure artistry of real-time shadows to define the scene. Just as a frame captures a moment, life itself is ever-moving, more akin to a video than a static image. I've always been drawn to how shadows infuse environments with depth and emotion. While the previous chapter relied on pre-baked lighting, this time, I'm introducing dynamic realism with a moving directional light.</Typography>
<br></br>
        <Typography
          fontFamily={'monospace'}
          variant="body2"
          sx={{ color: '#ffffff', marginBottom: '10px' }}
        >
To enhance the scene, I've also added normal maps to the plaster background and the painting canvas, giving the surfaces a richer, more tactile quality.

</Typography>
        <br></br>
        <Typography
          fontFamily={'monospace'}
          variant="body2"
          sx={{ color: '#ffffff', marginBottom: '10px' }}
        >
But the journey doesn't stop here—I'm also tackling the exciting challenge of integrating videos into a 3D space, a concept I’m eager to explore even further in the chapters ahead.
</Typography>
        <br></br>
        
      </Paper>
    
      <Button
        onClick={closePopup}
        sx={{
          borderRadius:'100px',
          position: 'absolute',
          bottom: '60px', // Space from the bottom of the screen
          backgroundColor: '#ffffff',
          color: '#000000',
          alignSelf: 'center', // Center the button horizontally
        }}
      >
        X
      </Button>
    </Box>
    
      )}

      {/* Custom Code Popup */}
      {showCodePopup && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1300,
          }}
          onClick={closePopup}
        >
          <Paper
            onClick={(e) => e.stopPropagation()}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(15px)',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '800px',
              width: '90%',
            }}
          >

            <CodeMirror
  value={code}
  extensions={[
    javascript({ jsx: true }),
  ]}
 
  theme={oneDark}
  height="400px" // Set a fixed height to enable scrolling
  style={{ overflowY: 'auto' }} // Enable scrolling
/>

          </Paper>
          <Button
        onClick={closePopup}
        sx={{
          borderRadius:'100px',
          position: 'absolute',
          bottom: '75px', // Space from the bottom of the screen
          backgroundColor: '#ffffff',
          color: '#000000',
          alignSelf: 'center', // Center the button horizontally
        }}
      >
        X
      </Button>
        </Box>
      )}
    </div>
  );
};

export default MovingFramesPage;
