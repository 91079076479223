import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

//store all pages
import MainPage from './Pages/MainPage';
import UobPage from './Pages/UobPage';
import IslandPage from './Pages/IslandPage';
import VoyagePage from './Pages/VoyagePage';
import InteriorPage from './Pages/InteriorPage';
import MovingFramesPage from './Pages/MovingFramesPage';
import Documentation from './Pages/Documentation';
import MiniGamePage from './Pages/MiniGamePage';
import StormTheRoofPage from './Pages/StormTheRoofPage';
import BadBloodPage from './Pages/BadBloodPage';

import LinkProfile from './Pages/LinkProfile';

const App: React.FC = () => {
  return (
    <div className="main">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={MainPage} />
          <Route path="/uob" exact component={UobPage} />
          <Route path="/parade" exact component={IslandPage} />

          <Route path="/voyage/storm-the-roof" exact component={StormTheRoofPage} />
          <Route path="/voyage/bad-blood" exact component={BadBloodPage} />

          <Route path="/voyage/point-cloud" exact component={VoyagePage} />
          <Route path="/voyage/moving-frames" exact component={MovingFramesPage} />

          <Route path="/interior" exact component={InteriorPage} />

          <Route path="/doc" exact component={Documentation} />

          <Route path="/minigame" exact component={MiniGamePage} />
          <Route path="/theon" exact component={LinkProfile} />
          <Route path="/Main" exact component={MainPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
