import * as THREE from "three";
import { MathUtils, Vector2, Vector3, Euler } from "three";
import { Mesh, SphereGeometry, MeshBasicMaterial } from 'three';

//renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { StandardShader } from "../../Shaders/StandardShader";
import { PropShader } from "../../Shaders/PropShader";
import { EmmisiveShader } from "../../Shaders/EmmisiveShader";

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
class MiniGame extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;
    this.collision_none = _options.collision_none_callback;

    this.score_update = _options.score_callback;
    this.countdown_update = _options.countdown_callback;

    this.gameover_update = _options.gameover_callback;

    this.hide = false;
    this.current = 0;

    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = Date.now();
    this.totalTime = 0.0;

    this.dirLight = new THREE.DirectionalLight(0xffffff, 0.95);
    this.ambient = new THREE.AmbientLight(0xffffff, 0.15, 0);

    let d = 50;
    let r = 1;
    let mapSize = 2048;
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(-20, 20, 20);
    //const helper = new THREE.CameraHelper(this.dirLight.shadow.camera);
    this.scene.add(this.ambient);
    this.scene.add(this.dirLight);
    this.scene.add(this.dirLight.target);

    this.storeTop = 0.0;

    // Set initial camera position and rotation
    this.newCamera.threeCamera.position.set(0, 0, -30);
    this.newCamera.threeCamera.rotation.set(MathUtils.degToRad(0), MathUtils.degToRad(0), MathUtils.degToRad(0));

    //add event listeners
    this.loadListener();
    this.startRender();

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.loadAllModels();

    // Add event listeners for dragging
    this.isDragging = false;
    this.previousMousePosition = new Vector2();

    // For smoother camera movement
    this.targetCameraPosition = new Vector3().copy(this.newCamera.threeCamera.position);

    this.models = [];
    this.points = 0;
    this.countdown = 30;
    this.countdown_update(this.countdown);
    this.spawnInterval = 400; // Spawn a model every 400 milliseconds
    this.countdownInterval = 1000;

  }

  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }

  loadAllModels() {
    // render given model
    this.loader.waitForAssets(() => {
      var uobTexture = this.loader.getAsset("uobTexture");

      this.p1Material = new Material(
        {
          uniforms: {
            downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
          },
          vertexShader: PropShader.vertexShader,
          fragmentShader: PropShader.fragmentShader
        }
      );

      var computer = this.detectDevice();
      const standardMaterial = new THREE.MeshPhongMaterial();

      standardMaterial.map = uobTexture;
      //standardMaterial.map.encoding = THREE.sRGBEncoding;
      standardMaterial.receiveShadow = true;
      standardMaterial.shininess = 0;
      standardMaterial.dithering = true;
      standardMaterial.castShadow = true;


      this.fullLoaded = true;
      this.startSpawning();
      this.startCountdown();
    });
  }

  componentDidMount() {
    console.log("scene 1 mounted")
    this.SceneMount();
    this.setCamera(60, 60, this.detectDevice() == 'computer' ? 35 : 35);
  }

  componentWillUnmount() {
  }

  loadListener() {
    document.addEventListener("mousemove",
      (e) => {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
      });
    document.addEventListener("click",
      (e) => {
        this.handleClick(e);
      });
    document.addEventListener("touchstart", (e) => {
      this.handleTouch(e);
      //this.handleClick(e);
    });
    document.addEventListener("touchend", (e) => {
      this.handleTouch(e);
    });
  }

  startSpawning() {
    setInterval(() => {
      if (this.countdown > 0)
        this.spawnModel();
    }, this.spawnInterval);
  }
  startCountdown() {
    setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.countdown_update(this.countdown);
      }
    }, this.countdownInterval);
  }
  spawnModel() {
    const modelGeometry = new SphereGeometry(1.5, 32, 32);
    const modelMaterial = new THREE.MeshPhongMaterial({ color: '#b50b46' });
    const model = new Mesh(modelGeometry, modelMaterial);
    model.position.set(Math.random() * 10 - 5, -15, Math.random() * 10 - 5); // Random spawn position out of sight
    model.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI); // Random initial rotation
    model.name = `model_${this.models.length}`;
    this.scene.add(model);
    this.models.push({ mesh: model, velocity: new Vector3(((Math.random()) - 0.5) / 50, (Math.random() + 1.5) / 4, 0) }); // Initial upward velocity
  }

  handleClick(event) {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );

    raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
    const intersects = raycaster.intersectObjects(this.scene.children);

    for (let i = 0; i < intersects.length; i++) {
      const intersectedObject = intersects[i].object;
      if (intersectedObject.name.startsWith('model_')) {
        this.scene.remove(intersectedObject);
        this.models = this.models.filter(model => model.mesh !== intersectedObject);
        this.points++;
        this.score_update(this.points);
        //console.log(`Points: ${this.points}`);
        break;
      }
    }
  }

  handleTouch(event) {
    const raycaster = new THREE.Raycaster();
    const touch = event.changedTouches[0];
    const mouse = new THREE.Vector2(
      (touch.clientX / window.innerWidth) * 2 - 1,
      -(touch.clientY / window.innerHeight) * 2 + 1
    );
    //console.log(mouse);
    raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
    const intersects = raycaster.intersectObjects(this.scene.children);

    for (let i = 0; i < intersects.length; i++) {
      const intersectedObject = intersects[i].object;
      if (intersectedObject.name.startsWith('model_')) {
        this.scene.remove(intersectedObject);
        this.models = this.models.filter(model => model.mesh !== intersectedObject);
        this.points++;
        this.score_update(this.points);
        //console.log(`Points: ${this.points}`);
        break;
      }
    }
  }

  Update() {
    // Update models' positions
    this.models.forEach(model => {
      model.velocity.y -= 0.0005 * this.dt; // Gravity effect
      model.mesh.position.add(model.velocity);
      model.mesh.updateMatrixWorld();
      // Rotate the models
      model.mesh.rotation.x += 0.01;
      model.mesh.rotation.y += 0.01;
      model.mesh.rotation.z += 0.01;
      // Remove model if it goes out of sight
      if (model.mesh.position.y < -30) {
        this.scene.remove(model.mesh);
        this.models = this.models.filter(m => m !== model);
      }
    });

    this.lookPosition = new THREE.Vector3(this.newCamera.threeCamera.position.x, this.newCamera.threeCamera.position.y, this.newCamera.threeCamera.position.z);
    this.lookPosition.add(new THREE.Vector3(0, 0, 1));
    this.newCamera.threeCamera.lookAt(this.lookPosition);

    //update delta time
    var now = Date.now();
    this.dt = now - this.lastUpdate;
    this.lastUpdate = now;
    this.totalTime += this.dt;
    // Smoothly lerp the camera position to the target position
    this.newCamera.threeCamera.position.lerp(this.targetCameraPosition, 0.1);

  }
}

export default MiniGame
