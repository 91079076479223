import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Button } from '@mui/material';
import Features from './Features';


import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';

import Iconify from 'src/components/iconify';
import { varFade, MotionViewport } from 'src/components/animate';
import { minWidth } from '@mui/system';



// ----------------------------------------------------------------------

/*
const FEATURE_HIGHLIGHTS = [
    { title: '5 Prebuilt Websites', icon: 'carbon:application-web' },
    { title: '60+ Demo Page', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Easy to Customize', icon: 'carbon:settings-adjust' },
    { title: 'Color Presets', icon: 'carbon:color-switch' },
    { title: 'Dark Mode', icon: 'carbon:asleep' },
    { title: 'Awesome Animation', icon: 'carbon:boolean' },
    { title: 'Google Fonts', icon: 'carbon:text-font' },
    { title: 'Figma Design', icon: 'ph:figma-logo' },
    { title: 'Fully Responsive', icon: 'carbon:devices' },
    { title: 'Mega Menu', icon: 'carbon:list-dropdown' },
    { title: 'Clean Markup', icon: 'carbon:script' },
    { title: 'Free Updates', icon: 'carbon:update-now' },
    { title: 'Fast Support', icon: 'carbon:headset' },
    { title: 'Well Documented', icon: 'carbon:notebook' },
];
*/
const FEATURE_HIGHLIGHTS = [
    { title: 'Bank', icon: 'carbon:application-web' },
    { title: 'Bank Wealth', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Corporate', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Campaign', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Broker', icon: 'carbon:stacked-scrolling-2' },
    { title: 'Application', icon: 'carbon:settings-adjust' },
    { title: 'Fiance', icon: 'carbon:color-switch' },

];

// ----------------------------------------------------------------------

export const panel_Part2 = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Typography fontFamily="Akira"
                    paddingTop="100px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'1.5rem'}
                    fontSize="2.5rem"
                    fontWeight="100"
                    fontStyle={'normal'}>

                </Typography>
                <Container
                    component={MotionViewport}

                    sx={{
                        pt: { xs: 5, md: 10 },
                        pb: { xs: 10, md: 15 },
                    }}
                >
                    <Grid container spacing={{ xs: 8, md: 3 }} justifyContent={{ md: 'space-between' }}>
                        <Grid
                            xs={12}
                            md={4}
                            sx={{
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <m.div >
                                <Typography fontFamily='Glacial' fontWeight="100" variant="h2" sx={{ my: 3 }}>

                                    Our services for <b>your needs</b>
                                </Typography>
                            </m.div>

                        </Grid>

                        
                    </Grid>
                </Container>
            </div>
        </>
    )
}
