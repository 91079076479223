import * as THREE from 'three';
import { MathUtils } from 'three';

import { Vector3 } from "three";
class OrbitalCameraGame {
    constructor(camera, target = new THREE.Vector3(0, 0, 0)) {
        this.recoilOffset= new THREE.Vector3();
        this.camera = camera;
        this.target = target;
        this.currentPosition = new THREE.Vector3();
        this.currentLookAt = new THREE.Vector3();
        this.spherical = new THREE.Spherical();
        this.sphericalDelta = new THREE.Spherical();
        this.lerpFactor = 0.1;  // Start with a base lerp factor
        this.currentLerpFactor = 0.05;  // Actual lerp factor that is smoothed over time
        this.lerpSmoothingFactor = 0.05;  // Controls the speed of lerp factor smoothing
    }

    updateTargetWithDoubleLerp(averagePosition, recoilOffset) {
        this.recoilOffset = recoilOffset;
        const targetPosition = averagePosition.clone().add(recoilOffset);

        // Smoothly interpolate the lerp factor
        this.currentLerpFactor = THREE.MathUtils.lerp(this.currentLerpFactor, this.lerpFactor, this.lerpSmoothingFactor);

        // Apply the smoothed lerp factor to the target position
        this.target.lerp(targetPosition, this.currentLerpFactor);
    }

    setPosition(x, y, z) {
        this.currentPosition.set(x, y, z);
        this.camera.position.copy(this.currentPosition);
    }

    updateTarget(activeEnemies) {
        if (activeEnemies.length > 0) {
            const averagePosition = new THREE.Vector3();
            let validEnemyCount = 0;

            activeEnemies.forEach(enemy => {
                if (enemy && enemy.position) {
                    averagePosition.add(enemy.position);
                    validEnemyCount++;
                }
            });

            if (validEnemyCount > 0) {
                averagePosition.divideScalar(validEnemyCount);
                this.target.lerp(averagePosition, this.lerpFactor);
            }
        }
    }

    update() {
        // Smooth movement on the Y-axis using cos function
        const time = performance.now() * 0.0005;
        const offsetY = Math.cos(time) * 0.5; // Adjust the multiplier to control Y-axis range
        const targetPosition = this.target.clone();
        targetPosition.y += offsetY;

        // Only update the look target, no camera position change
        this.currentLookAt.lerp(targetPosition, this.lerpFactor);
        this.camera.lookAt(this.currentLookAt.add( this.recoilOffset));
    }
}

export default OrbitalCameraGame;
