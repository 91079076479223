import * as THREE from "three";

class ParticleEmitter {
    constructor(scene) {
        this.scene = scene;
        this.particles = [];
        this.particleGeometry = new THREE.SphereGeometry(0.25, 5, 5); // Small sphere geometry for particles
        this.particleMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,  // Red color for blood effect
            transparent: true,
            opacity: 0.8,
        });
    }

    spawn(position,count=20) {
        for (let i = 0; i < count; i++) {
            const particle = new THREE.Mesh(this.particleGeometry, this.particleMaterial);
            particle.position.set(
                position.x + THREE.MathUtils.randFloatSpread(0.5),
                position.y + THREE.MathUtils.randFloatSpread(0.5),
                position.z + THREE.MathUtils.randFloatSpread(0.5)
            );
            particle.velocity = new THREE.Vector3(
                THREE.MathUtils.randFloatSpread(0.9),  // Random horizontal velocity
                THREE.MathUtils.randFloat(0.3, 0.8),   // Upward velocity
                THREE.MathUtils.randFloatSpread(0.9)   // Random horizontal velocity
            );
            particle.lifetime = 600;  // Number of frames the particle exists

            this.scene.add(particle);
            this.particles.push(particle);
        }
    }

    update() {
        this.particles.forEach((particle, index) => {
            particle.velocity.y -= 0.02;  // Apply gravity
            particle.position.add(particle.velocity);
            particle.scale.multiplyScalar(0.95);  // Shrink the particle over time
            particle.lifetime--;

            if (particle.lifetime <= 0) {
                this.scene.remove(particle);
                this.particles.splice(index, 1);
            }
        });
    }

    remove() {
        this.particles.forEach(particle => this.scene.remove(particle));
        this.particles = [];
    }
}

export default ParticleEmitter;
