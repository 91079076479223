/*****************************************************************************/
/*!
\file Scene.js
\author Theon Teo
\par email: theonteo96@gmail.com
\date 2021
\brief
This project contains portfolio / web-mobile responsive application
\Not for distribution
*/
/*****************************************************************************/
import React, { Component } from "react";
import * as THREE from "three";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { VignetteShader } from "../../Shaders/VignetteShader";
import { ChromaticAberrationShader } from "../../Shaders/ChromaticAberrationShader"; // Update path if necessary
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass.js";
import './Scene.css';
import Camera from "../../Render/Camera";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { TAARenderPass } from 'three/examples/jsm/postprocessing/TAARenderPass.js';

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
class Scene extends Component {
  constructor(_options) {
    super(_options);
    function detectDevice() {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
      ) {
        return 'phone';
      } else {
        return 'computer';
      }
    }
    //init variables
    var device = detectDevice();

    this.usePostprocess = true;
    this.container = [];
    this.pageLerp = 0;
    this.scene = new THREE.Scene();

    //add Camera
    //this.setCamera(1200, 768);
  }
  /******************************************************************************/
  /*!
  \brief  create new camera
  */
  /******************************************************************************/
  setCamera(width, height, fov) {
    //add Camera
    this.newCamera =
      new Camera({
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0, 0, 0),
        width: width,
        height: height,
        fov: fov,
      });
  }
  /******************************************************************************/
  /*!
  \brief  component mounted - three.js
  */
  /******************************************************************************/
  SceneMount() {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ castShadow: true });
    this.renderer.setClearColor("#ffdddd", 1);
    this.renderer.setSize(width, height);
    this.renderer.colorSpace = THREE.LinearSRGBColorSpace;
    this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    this.mount.appendChild(this.renderer.domElement);
    this.addListener();
    //add Camera
    this.setCamera(width, height);

    this.renderer.setPixelRatio(1);

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    //this.setPostProcess();
  }

  setPostProcess() {
    this.postProcess = {};

    /**
     * Render pass
     */
    this.postProcess.renderPass = new RenderPass(this.scene, this.newCamera.threeCamera);

    //bloom Pass
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 0.1, 2.0, 0.5);
    bloomPass.enabled = true;
    this.postProcess.bloomPass = bloomPass;
    var vignette = new ShaderPass(VignetteShader);
    vignette.uniforms["resolution"].value = new THREE.Vector2(window.innerWidth, window.innerHeight);
    vignette.uniforms["horizontal"].value = true; // default is false
    vignette.uniforms["radius"].value = .75; // default is 0.75
    vignette.uniforms["softness"].value = .5; // default is 0.3
    vignette.uniforms["gain"].value = 0.825; // default is 0.9

    // TAA Pass
    const taaRenderPass = new TAARenderPass(this.scene, this.newCamera.threeCamera);
    taaRenderPass.unbiased = false;
    taaRenderPass.sampleLevel = 4; // Increase for higher quality, decrease for performance

    // Chromatic Aberration Pass
    const chromaticAberrationPass = new ShaderPass(ChromaticAberrationShader);
    chromaticAberrationPass.uniforms['amount'].value = 0.015; // Adjust for effect strength

    // Film Grain Pass
    const filmPass = new FilmPass(
      0.15,   // noise intensity
      0.025,  // scanline intensity
      1296,    // scanline count
      true,  // grayscale
    );

    // Depth of Field Pass
    /*
    const bokehPass = new BokehPass(this.scene, this.newCamera.threeCamera, {
      focus: 28.0, // Adjust for focus distance
      aperture: 0.000025, // Adjust for aperture size
      maxblur: 0.01, // Adjust for maximum blur strength
    });
    this.postProcess.bokehPass = bokehPass;
    */
    /**
     * Effect composer
     */
    const RenderTargetClass = THREE.WebGLRenderTarget;
    this.renderTarget = new RenderTargetClass({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    this.postProcess.composer = new EffectComposer(this.renderer);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight);
    this.postProcess.composer.setPixelRatio(1);
    this.postProcess.composer.addPass(this.postProcess.renderPass);
    this.postProcess.composer.addPass(taaRenderPass);
    this.postProcess.composer.addPass(chromaticAberrationPass);
    this.postProcess.composer.addPass(vignette);

    this.postProcess.composer.addPass(filmPass);
    //this.postProcess.composer.addPass(bokehPass);
    this.postProcess.composer.addPass(bloomPass);

  }
  addListener() {
    window.addEventListener('resize', () => {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      if (this.postProcess && this.postProcess.composer) {
        this.postProcess.composer.setSize(window.innerWidth, window.innerHeight);
      }
    });
  }

  /******************************************************************************/
  /*!
  \brief  start main render - derived class should start
  */
  /******************************************************************************/
  startRender() {
    //loop through all models and add to scene container

    this.container.forEach(function (item, index) {
      //this.scene.add(item);
    });
    this.scene.castShadow = true;

    //render scene
    this.renderScene();

    console.log(this.scene);

    //start animation
    this.start();
  }

  /******************************************************************************/
  /*!
  \brief  component unmounted - three.js
  */
  /******************************************************************************/
  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }
  start = () => {
    if (!this.frameId)
      this.frameId = requestAnimationFrame(this.animate);

  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    //main scene update function
    this.Update();
    this.renderScene();

    this.frameId =
      window.requestAnimationFrame(this.animate);
  };
  /******************************************************************************/
  /*!
  \brief render final scene
  */
  /******************************************************************************/
  renderScene = () => {
    if (this.renderer) {
      if (this.usePostprocess && this.postProcess && this.postProcess.composer) {
        this.postProcess.composer.render();
      }
      else {
        this.renderer.render(this.scene, this.newCamera.threeCamera);
      }
    }
  };

  /******************************************************************************/
  /*!
  \brief  return - composite html
  */
  /******************************************************************************/
  render() {
    return (
      <div className='render-window'
        ref={mount => {
          this.mount = mount;
        }}
      />
    );
  }
}
export default Scene;
