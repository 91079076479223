import React, { useState } from 'react';
import { AppBar, Button, Container, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box } from '@mui/material';
import { useScroll } from 'framer-motion';
import gsap from 'gsap';

import Card from '@mui/material/Card';
import './Home.css';
import ContentUob from './ContentUob';
import TopbarParade from '../components/TopBarParade';
import Bottombar from '../components/BottomBar';
import LoadingManager from '../Render/LoadingManager';
import Interior from 'src/components/Scenes/Interior';
import Overlay from './Overlay'; // Ensure the import is correct

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const InteriorPage: React.FC = () => {
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  }

  const { scrollYProgress } = useScroll();

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{ position: 'fixed', zIndex: -1 }}>
          <Interior
            collision_part1_callback={OpenContent}
            collision_part2_callback={OpenContent}
            collision_part3_callback={OpenContent}
            collision_none_callback={CloseContent}
            modelcallback={'main'}
          >
          </Interior>
        </Grid>

        <HideOnScroll>
          <AppBar position={'fixed'} sx={{ boxShadow: 'none', backgroundColor: '#00000000' }}>
              <TopbarParade />
             
          </AppBar>
        </HideOnScroll>

        <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
            <Bottombar />
        </AppBar>


        <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
          {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Button
              variant='contained'
              onClick={() => {
                setInfoValue(contentValue);
                setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
              }}
              sx={{
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 100,
                mb: '30px',
                height: '40px',
                fontFamily: 'DMSans',
                fontSize: '1.0rem',
                fontWeight: '100',
                color: '#000000',
                backgroundColor: '#ffffff',
                borderRadius: '30px',
                boxShadow: 'none',
              }}>
              Click to see {buttonInfo}
            </Button>
          }
          {infoValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <ContentUob
              content_panel_value={contentValue}
              content_open={contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN}
              content_close={CloseContent}
            />
          }
          <Box component="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: 2 }}>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default InteriorPage;
