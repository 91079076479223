import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import LaptopIcon from '@mui/icons-material/Laptop';
import { Grid } from '@mui/material';

import { Box } from '@mui/system';
export const panel_Part3 = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Typography fontFamily="Glacial"
                    paddingTop="105px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'1rem'}
                    fontSize="2rem"
                    fontWeight="100"
                    fontStyle={'normal'}>
                    Sustainability
                </Typography>

                <Typography
                    style={{ backgroundColor: '#fc7b03ff' }}
                    fontFamily="Glacial"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="25px"
                    marginTop="75px"
                    marginBottom="25px"
                    borderRadius={'0px'}
                    align="left"
                    color={'#000000ff'}
                    variant="h3"
                    fontSize="1rem"
                    fontWeight="600">
                    How is works
                </Typography>
                <Box
                    sx={{
                        margin: '50px',
                        display: 'grid',

                        justifyContent: 'center',
                        textAlign: 'center',

                        gridTemplateColumns: {
                            xs: 'repeat(3, 1fr)',
                            md: 'repeat(3, 1fr)',
                        },
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <TabletMacIcon sx={{ width: '60px', height: '60px' }} ></TabletMacIcon>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <PhoneIphoneIcon sx={{ width: '60px', height: '60px' }} ></PhoneIphoneIcon>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <LaptopIcon sx={{ width: '60px', height: '60px' }} ></LaptopIcon>
                    </Box>
                </Box >

                <div style={{ padding: 25 }}>

                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        To place a new fixed deposit



                        Step 1: Transfer the minimum required fresh funds to your existing savings/current account



                        Via UOB Personal Internet Banking



                        Step 2: Login to UOB Personal Internet Banking Click "Investment"  Select "Fixed Deposit"



                        Step 3: Fill in the requested details and click confirm to proceed.



                        Via UOB TMRW App



                        Step 2: Login to UOB TMRW App  Select “Services”  Select “Fixed deposit placement”



                        Step 3: Fill in the requested details and swipe confirm to proceed.



                        Once your transaction is successful, your new placement will be shown when you click into the account.





                        To amend maturity instructions



                        Step 1: Login to UOB Personal Internet Banking



                        Step 2: Click "Investment"  Select "Fixed Deposit"



                        Step 3: Click "Amend" and amend your maturity instruction



                        Once you have confirmed to proceed, your new maturity instructions will be shown when you click into the account.

                    </Typography>

                </div>


            </div>
        </>
    )
}
