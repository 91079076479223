import * as THREE from "three";

class BloodEmitter {
    constructor(scene) {
        this.scene = scene;
        this.particles = [];
        this.color = 0xff0000;
        this.count=15;
        this.spread=1.0;
        this.type=0;
        this.fallSpeed =      0.01+ THREE.MathUtils.randFloatSpread(0.0005);  // Random horizontal velocity

        this.particleGeometry = new THREE.SphereGeometry(0.02+ THREE.MathUtils.randFloatSpread(0.04), 5, 5); // Small sphere geometry for particles

    }

    spawn(position,type) {
        this.type=type;
        if(type == 0)
        {
            this.count=17;
            this.spread=1.5;
            this.color = 0xff0000;
        }
        if(type == 1)
        {
            this.count=3;
            this.spread=0.5;
            this.color = 0x00ff00;
        }
        for (let i = 0; i < this.count; i++) {

            const particle = new THREE.Mesh(
                new THREE.SphereGeometry(0.02+ THREE.MathUtils.randFloatSpread(0.04), 5, 5), 
                new THREE.MeshBasicMaterial({
                color: this.color,  // Red color for blood effect
                transparent: false,
                opacity: 0.8,
            }));
            particle.position.set(
                position.x + THREE.MathUtils.randFloatSpread(0.5*   this.spread),
                position.y + THREE.MathUtils.randFloatSpread(0.035*   this.spread),
                position.z + THREE.MathUtils.randFloatSpread(0.35*   this.spread)
            );
            particle.velocity = new THREE.Vector3(
                THREE.MathUtils.randFloatSpread(0.1),  // Random horizontal velocity
                THREE.MathUtils.randFloat(0.03, 0.3),   // Upward velocity
                THREE.MathUtils.randFloatSpread(0.1)   // Random horizontal velocity
            );
            particle.lifetime = 600;  // Number of frames the particle exists
            particle.type=type;
            this.scene.add(particle);
            this.particles.push(particle);
        }
    }

    update(world) {
        this.particles.forEach((particle, index) => {
            particle.velocity.y -=this.fallSpeed;  // Apply gravity
            particle.position.add(particle.velocity);
            particle.scale.multiplyScalar(0.99);  // Shrink the particle over time
            particle.lifetime--;


            if (particle.position.y<0 || particle.lifetime <= 0) {

              
                world.paintFloor(particle.position,0.4,particle.type);
               
                this.scene.remove(particle);
                this.particles.splice(index, 1);
            }
        });
    }

    remove() {
        this.particles.forEach(particle => this.scene.remove(particle));
        this.particles = [];
    }
}

export default BloodEmitter;
