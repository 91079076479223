const voyageCode = `
// code snippets for moving frames chapter
// contains main elements required for the demo seen

class MovingFrames extends Scene {
    constructor(_options) {
      super(_options);
  
      // Color settings for different scenes
      this.colorSettings = [
        { color: 0xffffff, intensity: 0.65, dirIntensity: 7.15 },
        { color: 0xe0d7ce, intensity: 1.5, dirIntensity: 8.5 },
        { color: 0xb9dbf0, intensity: 0.8, dirIntensity: 7.5 },
      ];
  
      // Initialize directional light
      this.dirLight = new THREE.DirectionalLight(0xffffff, 0.05);
      this.setupDirectionalLight();
  
      // Initialize ambient light
      this.ambient = new THREE.AmbientLight(0xffffff, 0.2);
      this.scene.add(this.ambient);
  
      // Other initializations
      this.currentColorIndex = 0;
      this.buttonVisibilityCallback = null;
    }
  
    setupDirectionalLight() {
      const d = 30;
      const r = 2;
      const mapSize = 2048;
  
      this.dirLight.castShadow = true;
      this.dirLight.shadow.radius = r;
      this.dirLight.shadow.bias = 0.0001;
      this.dirLight.shadow.mapSize.set(mapSize, mapSize);
      this.dirLight.shadow.camera.set(-d, d, d, -d, 0.01, 80);
      this.dirLight.position.set(11, 10, -10);
  
      this.scene.add(this.dirLight);
      this.scene.add(this.dirLight.target);
    }
  
    loadCurrentColor() {
      const { color, intensity, dirIntensity } = this.colorSettings[this.currentColorIndex];
      this.triggerColorTransition(color, intensity, dirIntensity);
    }
  
    changeColorSettings(direction) {
      if (direction === 'next') {
        this.currentColorIndex = (this.currentColorIndex + 1) % this.colorSettings.length;
      } else if (direction === 'prev') {
        this.currentColorIndex = (this.currentColorIndex - 1 + this.colorSettings.length) % this.colorSettings.length;
      }
      this.loadCurrentColor();
    }
  
    triggerColorTransition(color, intensity, dirIntensity) {
      if (!this.newCamera) return;
  
      const timeline = gsap.timeline();
  
      // Step 1: Change lighting
      timeline.add(() => {
        this.switchColor(new THREE.Color(color), intensity, dirIntensity);
      });
  
      // Step 2: Handle model movement
      timeline.add(() => {
        // Move all models out of view
        this.moveModelOutOfView(this.shadow1Model);
        this.moveModelOutOfView(this.shadow2Model);
        this.moveModelOutOfView(this.shadow3Model);
  
        // Move the selected model to the origin
        if (this.currentColorIndex === 0) {
          this.moveModelToOrigin(this.shadow1Model);
        } else if (this.currentColorIndex === 1) {
          this.moveModelToOrigin(this.shadow2Model);
        } else if (this.currentColorIndex === 2) {
          this.moveModelToOrigin(this.shadow3Model);
        }
      });
    }
  
    moveModelOutOfView(model) {
      gsap.to(model.all_model[0].position, {
        x: -100,
        y: 0,
        z: -100,
        duration: 2,
        ease: 'power4.inOut',
      });
    }
  
    moveModelToOrigin(model) {
      gsap.to(model.all_model[0].position, {
        x: 0,
        y: 0,
        z: 0,
        duration: 2,
        ease: 'power4.inOut',
      });
    }
  
    switchColor(targetColor, targetIntensity = 1, dirIntensity = 1) {
      gsap.to(this.ambient.color, {
        r: targetColor.r,
        g: targetColor.g,
        b: targetColor.b,
        duration: 1,
      });
  
      gsap.to(this.dirLight.color, {
        r: targetColor.r,
        g: targetColor.g,
        b: targetColor.b,
        duration: 1,
      });
  
      gsap.to(this.ambient, {
        intensity: targetIntensity,
        duration: 1,
      });
  
      gsap.to(this.dirLight, {
        intensity: dirIntensity,
        duration: 1,
      });
    }
  
    startIntroSequence() {
      // Load the first sequence
      this.loadCurrentColor();
  
      if (this.buttonVisibilityCallback) {
        this.buttonVisibilityCallback();
      }
    }
  
    createSceneObjects() {
      // Load assets and materials
      const paletteTexture = this.loader.getAsset("concreteTexture");
      const paletteNormalTexture = this.loader.getAsset("concreteNormalTexture");
      const plantCasterTexture = this.loader.getAsset("movingFramesShadowCasterPlantTexture");
  
      // Standard material
      this.standardMaterial = new THREE.MeshStandardMaterial({
        normalMap: paletteNormalTexture,
        normalScale: new Vector2(0.5, 0.5),
        receiveShadow: true,
        roughness: 0.5,
        dithering: true,
        castShadow: true,
      });
  
      // Plant material with alpha map
      this.plantMaterial = new THREE.MeshStandardMaterial({
        alphaMap: plantCasterTexture,
        transparent: true,
        alphaTest: 0.5,
        side: THREE.DoubleSide,
        dithering: true,
        receiveShadow: true,
        castShadow: true,
      });
  
      // Initialize models
      this.initModels();
    }
  
    initModels() {
      this.p1model = this.createModel('movingFramesModel', this.standardMaterial, new THREE.Vector3(0, 0, 0));
  
      this.shadow1Model = this.createModel('movingFramesShadowCasterModel', this.standardMaterial, new THREE.Vector3(-9, -9, -9));
      this.shadow2Model = this.createModel('movingFramesShadowCasterPlantModel', this.plantMaterial, new THREE.Vector3(-9, -9, -9));
      this.shadow3Model = this.createModel('movingFramesShadowCasterBinderModel', this.standardMaterial, new THREE.Vector3(-9, -18, -18));
  
      // Add video plane
      this.createVideoMesh();
    }
  
    createModel(modelLink, material, position) {
      const model = new Model({
        modelLink,
        material,
        position,
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: modelLink,
      });
      return model;
    }
  
    createVideoMesh() {
      const video = document.createElement('video');
      video.src = '/assets/artcode_chapter_2_video.mp4';
      video.loop = true;
      video.muted = true;
      video.play().catch(error => console.log('Video playback failed:', error));
  
      const videoTexture = new THREE.VideoTexture(video);
      videoTexture.minFilter = THREE.LinearFilter;
      videoTexture.magFilter = THREE.LinearFilter;
      videoTexture.format = THREE.RGBFormat;
  
      const canvasTexture = this.loader.getAsset("canvasNormalTexture");
  
      const videobasicMaterial = new THREE.MeshStandardMaterial({
        map: videoTexture,
        normalMap: canvasTexture,
      });
  
      this.canvasModel = new Model({
        modelLink: 'canvasModel',
        material: videobasicMaterial,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'canvasModel',
      });
    }
  
    Update() {
      const now = performance.now();
      this.dt = (now - this.lastUpdate) * 0.001;
      this.lastUpdate = now;
      this.totalTime = (now - this.startTime) * 0.001;
  
      // Directional light animation
      this.animateDirectionalLight();
  
      // Update camera
      this.orbitalCamera.update();
    }
  
    animateDirectionalLight() {
      this.dirLight.position.set(
        10 + Math.cos(this.totalTime / 2) * 2,
        20 + Math.cos(this.totalTime),
        -40 + Math.cos(this.totalTime * 2) * 3
      );
    }
  }
  
  export default React.forwardRef((props, ref) => <MovingFrames {...props} ref={ref} />);
  
`;

export default voyageCode;
