const VertexColorShader = {
    vertexShader: `
      varying vec2 vUv;
      varying vec3 vFragPos;
      varying vec3 vNormal;
      varying vec3 vColor;   // Use this for vertex color
      varying mat3 vTBN;
  
      attribute vec3 tangent;
      attribute vec3 bitangent;
  
      void main() {
          vUv = uv;
          vFragPos = (modelMatrix * vec4(position, 1.0)).xyz;
          vNormal = normalize(normalMatrix * normal);
          vColor = color.rgb;  // Retrieve the vertex color
  
          vec3 T = normalize(normalMatrix * tangent);
          vec3 B = normalize(normalMatrix * bitangent);
          vec3 N = normalize(normalMatrix * normal);
          vTBN = mat3(T, B, N);
  
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying vec2 vUv;
varying vec3 vFragPos;
varying vec3 vNormal;
varying vec3 vColor;   // Use vertex color from model
varying mat3 vTBN;

uniform sampler2D theTexture;
uniform sampler2D theTextureNormal;
uniform sampler2D theTextureNoise;
uniform sampler2D theTextureNormalDetail;
uniform float effect_x;
uniform float effect_y;
uniform float tiling_x;
uniform float tiling_y;
uniform float tiling_x_detail;
uniform float tiling_y_detail;
uniform bool flipHorizontal;
uniform float effectBurn;
uniform vec3 lightDir;       // Directional light direction
uniform vec3 viewPos;        // Camera position

vec2 GetTiledTexCoord() {
    float tcx = vUv.x;
    if (flipHorizontal) tcx = 1.0 - tcx;
    return vec2(effect_x + (tcx * tiling_x), effect_y + (vUv.y * tiling_y));
}

void main() {
    vec2 targetUV = GetTiledTexCoord();

    // Normal mapping
    vec3 normalmap = normalize(texture2D(theTextureNormal, targetUV).rgb * 2.0 - 1.0);
    vec3 combinedNormal = normalize(vTBN * normalmap);

    // Detailed normal mapping
    vec3 detailNormal1 = normalize(texture2D(theTextureNormalDetail, vUv / 4.0).rgb * 2.0 - 1.0);
    vec3 detailNormal2 = normalize(texture2D(theTextureNormalDetail, vUv / 1.5).rgb * 2.0 - 1.0);
    combinedNormal = normalize(combinedNormal + normalize(vTBN * detailNormal1) + normalize(vTBN * detailNormal2));

    // Main texture and noise
    vec4 texColor = texture2D(theTexture, vUv);
    vec4 noiseTexture = texture2D(theTextureNoise, vUv * 7.0);
        vec4 noiseTexture2 = texture2D(theTextureNoise, vUv * 9.0);
        vec4 noiseTexture3 = texture2D(theTextureNoise, vUv * 4.0);

    vec4 noiseTextureDetail = texture2D(theTextureNoise, vUv * 8.0);

    // Paint effect based on vertex color red channel and noise
    float paintThreshold = clamp(vColor.r - 0.25 + noiseTexture.r , 0.0, 1.0);
    vec4 paintEffect = vec4(clamp(paintThreshold - 0.5, 0.0, 1.0));
    float paintThreshold2 = clamp(vColor.r - 0.85 + noiseTexture2.r, 0.0, 1.0);
    vec4 paintEffect2 = vec4(clamp(paintThreshold2 - 0.65, 0.0, 2.0));

    float gPaintThreshold = clamp(vColor.g - 0.25 + noiseTexture.r , 0.0, 1.0);
    vec4 gPaintEffect = vec4(clamp(gPaintThreshold - 0.5, 0.0, 1.0));
    float gPaintThreshold2 = clamp(vColor.g - 0.85 + noiseTexture2.r, 0.0, 1.0);
    vec4 gPaintEffect2 = vec4(clamp(gPaintThreshold2 - 0.65, 0.0, 2.0));

    // Combine the paint effect with a yellow tint
    vec4 finalColorTexture = texColor*clamp(1.0-clamp(paintEffect*2.0,0.0,2.0)-clamp(gPaintEffect*2.0,0.0,2.0),0.0,1.0);

    vec4 finalColorRed = paintEffect * noiseTexture3.r*vec4(255.0 / 255.0, 0.0 / 255.0, 0.0/255.0, 1.0)+paintEffect2*0.5 * vec4(120.0 / 255.0, 255.0 / 255.0, 30.0/255.0, 1.0);
   
    vec4 finalColorGreen =  gPaintEffect * noiseTexture3.r*vec4(0.0 / 255.0, 255.0 / 255.0, 0.0/255.0, 1.0)+gPaintEffect2*0.5 * vec4(60.0 / 255.0, 255.0 / 255.0, 0.0/255.0, 1.0);

    vec4 finalColor = finalColorTexture + finalColorRed + finalColorGreen;
    // Specular highlights
    vec3 lightDirection = normalize(lightDir);
    vec3 viewDirection = normalize(viewPos - vFragPos);
    vec3 reflectDir = reflect(-lightDirection, combinedNormal);
    float spec = pow(max(dot(viewDirection, reflectDir), 0.0), 32.0); // Adjust shininess with the exponent
    vec3 specular = vec3(1.0) * spec; // White specular color

    // Combine specular with final color
    finalColor.rgb += specular;

    gl_FragColor = finalColor;
}

    `,
  };
  
  export default VertexColorShader;
  