import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';
import { CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';

// ----------------------------------------------------------------------

const STEPS = [
    {
        title: 'Dedicated Relationship Manager',
        description: 'You will have a dedicated Relationship Manager who will work with a team of specialists to provide you with tailored financial solutions that match your risk profile.',
        icon: '/assets/icons/ic_resume_job.svg',
        color: '#87cdff'
    },
    {
        title: 'Advisory for all your wealth goals',
        description: 'You have got goals, and we are here with advice to help you build your wealth and achieve those goals.',
        icon: '/assets/icons/ic_resume_job.svg',
        color: '#ffba6b'
    },
    {
        title: 'Wealth Banking Centres',
        description: 'Meet your Relationship Manager in the comfort and privacy of our Wealth Banking Centres.',
        icon: '/assets/icons/ic_resume_job.svg',
        color: '#ff5ec9'
    },
];

// ----------------------------------------------------------------------

export const panel_Part1 = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>

                <Box
                    sx={{
                        textAlign: 'center',
                        pt: { xs: 10, md: 15 },
                        pb: { xs: 5, md: 10 },

                    }}
                >
                    <Container >
                        <Grid item>
                            <Typography color={"#cebaff"} fontFamily="Glacial" variant="h3" fontWeight="900" sx={{ my: 3 }}>
                                Connectivity
                            </Typography>
                        </Grid>
                        <Typography fontSize='1.25rem' fontFamily="Glacial" variant="subtitle1" fontWeight="100" sx={{ color: '#ffffff', maxWidth: 550, mx: 'auto' }}>
                            Isn't it time a bank believes in the potential of your wealth? We certainly think so.

                            That’s why at UOB Wealth Banking, we invest in you by making it our priority to build your wealth and deliver the banking experience you’ve been seeking.


                        </Typography>

                    </Container >
                </Box >

            </div>

        </>
    )
}
