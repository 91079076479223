import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';

//renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { PropShader } from "../../Shaders/PropShader";
import { loadFont, loadCurveFromJSON, createText } from "./Helper";

import OrbitalCamera from './OrbitalCamera';
import gsap from "gsap";
/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
let COLLISION_LAYER = 1;

class Interior extends Scene {
  //constructor
  constructor(_options) {
    super(_options);

    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    // call back for mouse collision detection
    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;
    this.collision_none = _options.collision_none_callback;

    this.hide = false;
    this.current = 0;

    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = performance.now()
    this.totalTime = 0.0;
    this.startTime = performance.now()

    // helicopter
    this.helicopterMove = false;

    //camera vectors
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);


    let d = 25;
    let r = 1;
    let mapSize = 1024;
    this.ambient = new THREE.AmbientLight(0xffffff, 2.5, 0);

    this.dirLight = new THREE.DirectionalLight(0xffffff, 3.95);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(40, 25, 20);
    this.spot = new THREE.SpotLight(0xffffff, 100.0, 80.0, 5, 10, 10);

    //const helper = new THREE.CameraHelper(this.dirLight.shadow.camera);
    this.scene.add(this.ambient);
    this.scene.add(this.spot);
    //this.scene.add(this.dirLight);


    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;



    //add event listeners
    this.loadListener();
    this.startRender();
    this.generateCamCurve();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.dronePointLerped = new Vector3(0, 30, 0);

    this.lookDown = new Vector3(0, -30, 0);
    this.currPointLerped.set
      (-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set
      (-0.605, 0.054, 0.281);

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();

    this.requiredAssets = [


      // background asset

      // interior asset
      { name: 'interiorModel', source: '/assets/room.glb' },
      { name: 'interiorTexture', source: '/assets/room_render.jpg', type: 'texture' },

      //logo asset
      { name: 'logoModel', source: '/assets/logo.glb' },
    ];

    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    // Track which text is hovered over
    this.hoveredText = null;
  }

  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'interiorAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  onAllAssetsLoaded() {
    console.log("All assets loaded");
    this.createSceneObjects();


    // setup stage interaction
    this.setupStageInteractions();

    // Start the introduction sequence
    //this.startIntroSequence();

    let clickEnabled = false;


    // Update raycaster logic in t function and updateHoverAnimation method
    const t = (event) => {
      if (this.hoverAnimationsEnabled == false)
        return;
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
      raycaster.layers.set(COLLISION_LAYER); // Set raycaster layer to collision layer
      const intersects = raycaster.intersectObjects(this.scene.children, true);
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name === 'collision_parachute') {

          break;
        }

      }
    }

    function onMouseClick(event) {
      if (clickEnabled) {
        // Your click handling code here
        console.log("Mouse clicked");

        // Disable further clicks
        clickEnabled = false;
        t(event);
        // Remove the event listener to prevent further clicks
        window.removeEventListener('click', onMouseClick);
        window.removeEventListener('touchstart', onMouseClick);
      }
    }

    function onMouseUp(event) {
      // Re-enable clicks
      clickEnabled = true;
      console.log("Mouse up");

      // Re-attach the event listener
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('touchstart', onMouseClick, false);
    }

    // Initial event listeners
    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);

    // Event listeners to re-enable clicks
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }
  // Introduction sequence with scaling in the logo from 0 to full size
  startIntroSequence() {
    this.newCamera.threeCamera.position.set(20, 5, 20); // Set fixed camera position
    console.log(this.introLogo.all_model[0]);
    this.newCamera.threeCamera.lookAt(this.introLogo.all_model[0].position); // Look at the logo mesh
    gsap.to(this.introLogo.all_model[0].scale, {
      duration: 1,
      ease: "elastic.inOut",
      x: 3,
      y: 3,
      z: 3,
      onComplete: () => {
        gsap.to(this.introLogo.all_model[0].scale, {
          duration: 1,
          ease: "elastic.inOut",
          x: 0,
          y: 0,
          z: 0,
          delay: 0.4,
          onComplete: () => {
            this.scene.remove(this.introLogo.all_model[0]);
          }
        });


      }
    });
  }

  // Start hover animation after intro sequence
  startParadeModelAnimation() {
    this.ambient.intensity = 3;
    this.dirLight.intensity = 0;
    this.orbitalCamera.target = new Vector3(0, -2, 0);
  }






  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("interiorTexture");

    console.log(paletteTexture);
    this.p1Material = new Material({
      uniforms: {
        downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
      },
      vertexShader: PropShader.vertexShader,
      fragmentShader: PropShader.fragmentShader
    });

    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });

    standardMaterial.shininess = 5;
    const modelName = 'interiorModel';
    this.p1model = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(17, 1, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'interiorModel'
    });
    this.p1model.all_model[0].position.set(0, 0, 0);


    this.introLogo = new Model({
      modelLink: 'logoModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'introLogo'
    });

    this.introLogo.all_model[0].scale.set(0, 0, 0);




    this.fullLoaded = true;

  }

  // Update create_collision_box to use the collision layer
  create_collision_box = (position, size, name) => {
    this.cube = new Mesh(
      new BoxGeometry(size.x, size.y, size.z),
      new MeshBasicMaterial({ color: 0x22ffffff, opacity: 0.1 }),
    );
    this.cube.position.set(position.x, position.y, position.z);

    this.cube.visible = false;

    this.cube.name = name;
    this.cube.layers.set(COLLISION_LAYER); // Set collision layer
    this.scene.add(this.cube);
  }

  generateCamCurve() {
    //position points
    var posPoints = [];
    var dronePoints = [];
    dronePoints.push(new THREE.Vector3(-50, 24, -40));
    dronePoints.push(new THREE.Vector3(-8, 4, -19));


    posPoints.push(new THREE.Vector3(50, 24, -40));
    posPoints.push(new THREE.Vector3(50, 24, 40));


    this.spline = new THREE.CatmullRomCurve3(posPoints);

    this.points = this.spline.getPoints(200);

    //rotation points
    var lookAtPoints = [];
    lookAtPoints.push(new THREE.Vector3(5, 3, -1));
    lookAtPoints.push(new THREE.Vector3(5, 3, 1));


    this.lookAtSpline = new THREE.CatmullRomCurve3(lookAtPoints);
    this.lookAtPoints = this.lookAtSpline.getPoints(200);

    this.droneSpline = new THREE.CatmullRomCurve3(dronePoints);
    this.dronePoints = this.droneSpline.getPoints(200);
  }
  async setupStageInteractions() {

  }


  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }
  componentDidMount() {
    console.log("scene 1 mounted")
    this.SceneMount();
    this.setCamera(40, 40, this.detectDevice() == 'computer' ? 15 : 15);
    this.setPostProcess();
    this.orbitalCamera = new OrbitalCamera(this.newCamera.threeCamera);
    this.orbitalCamera.spherical.set(18, 1.5,18.8 );
    this.cameraTarget = new THREE.Vector3(0, 0, 0);
  }
  componentWillUnmount() {
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    document.addEventListener("mousedown", this.onPointerDown.bind(this));
    document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));

    // Touch events
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));

    // Zoom event
    document.addEventListener("wheel", this.onZoom.bind(this));
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    const deltaX = clientX - this.lastPointerPosition.x;
    const deltaY = clientY - this.lastPointerPosition.y;

    const deltaTheta = deltaX * 0.05;
    const deltaPhi = deltaY * 0.05;

    this.orbitalCamera.rotate(deltaTheta, deltaPhi);

    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    const delta = event.deltaY * 0.05;
    this.orbitalCamera.zoom(delta);
  }

  lerp_model() {
    this.spot.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));
    this.spot.position.set(-15, 25, 15);

  }
  Update() {
    console.log( this.orbitalCamera.spherical);
    //update delta time
    const now = performance.now();
    this.dt = (now - this.lastUpdate); // Delta time in seconds
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime); // Total time in seconds


    this.lerp_model();
    if (this.fullLoaded) {

    }

    if (this.storeTop > 0.1 ) {

    }
    // const t2 = document.getElementById('scc').scrollTop / window.innerHeight / 2;
    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
  
      this.append = 0;
      if (this.detectDevice() == 'computer') {
        this.lerpedMouse.x = MathUtils.lerp(this.lerpedMouse.x, this.mouseNormalized.x, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouse.y = MathUtils.lerp(this.lerpedMouse.y, this.mouseNormalized.y, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.x = MathUtils.lerp(this.lerpedMouseRaw.x, this.mouse.x, 0.01 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.y = MathUtils.lerp(this.lerpedMouseRaw.y, this.mouse.y, 0.01 * 1 / this.dt * 1.5);
      }
    

    //get page position and lerp camera 

    this.pageLerp = 0.005;
    this.pageLerp2 = 0.005;
    this.pageLerp3 = 0.005;

    var index = MathUtils.clamp(this.pageLerp2, 0.0, 0.99);

    let currPoint = this.points[parseInt(index * 200, 10)];
    let currPointLookAt = this.lookAtPoints[parseInt(index * 200, 10)];
    let currPointDrone = this.dronePoints[parseInt(index * 200, 10)];
    this.tempPoint = currPoint;
    this.tempPointLookAt = currPointLookAt;

    this.sendPoint.copy(this.camPos);
    this.sendPoint.add(currPoint);
    this.sendPoint.add(this.camDirection);
    this.sendPoint.add(this.camSide);
    this.sendPoint.add(this.camUp);

    this.currDronePoint.copy(new Vector3(0, 0, 0));
    this.currDronePoint.add(currPointDrone);

    this.sendPointLookAt.copy(this.camLookAt);
    this.sendPointLookAt.add(currPointLookAt);


    this.currPointLerped.lerp(this.sendPoint, 0.05);
    this.dronePointLerped.lerp(this.currDronePoint, 0.01);

    this.currPointLookAtLerped.lerp(this.sendPointLookAt, 0.05);


    // Update orbital camera
    this.orbitalCamera.update();
    // Ensure the Three.js camera is updated
    this.newCamera.threeCamera.position.copy(this.orbitalCamera.camera.position);
    this.newCamera.threeCamera.lookAt(this.orbitalCamera.target);

    // Force the camera to update its matrix
    this.newCamera.threeCamera.updateMatrixWorld(true);

    let targeted = false;
    // Optionally, update the camera target if needed

    if (targeted == false) {
      this.orbitalCamera.target = new Vector3(1, 0.5, 0);
    }
    if (document.getElementById('scontent')) {

      // const contentTop = document.getElementById('scontent').scrollTop / window.innerHeight / 2;
      // console.log(contentTop);
    }

    this.boot = true;
  }

}

export default Interior;
