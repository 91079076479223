const voyageCode = `
// Code snippet for image point cloud implementation

// Define an array of images with their corresponding properties, including texture name, color, light intensity, and height offset.
// This array is used to store settings for each image that can be transitioned to.
this.imageSettings = [
  { textureName: "voyageTexture", color: 0xffffff, intensity: 2.0, heightOffset: 0.0 },
  { textureName: "sunsetTexture", color: 0xffcc66, intensity: 1.0, heightOffset: 5.0 },
  { textureName: "iceCreamTexture", color: 0xc672db, intensity: 0.8, heightOffset: 0.0 },
];

// Load the first image from the settings array.
// This is called initially to set up the first image when the component is mounted.
this.loadCurrentImage();

// Function to load the current image based on the currentImageIndex.
// It triggers an image transition using the properties defined in imageSettings.
loadCurrentImage() {
  const { textureName, color, intensity, heightOffset } = this.imageSettings[this.currentImageIndex];
  this.triggerImageTransition(textureName, color, intensity, heightOffset);
}

// Function to change the image based on the direction (next or previous).
// It updates the currentImageIndex and then loads the new image.
changeImage(direction) {
  if (direction === 'next') {
    // Move to the next image, wrapping around if necessary.
    this.currentImageIndex = (this.currentImageIndex + 1) % this.imageSettings.length;
  } else if (direction === 'prev') {
    // Move to the previous image, wrapping around if necessary.
    this.currentImageIndex = (this.currentImageIndex - 1 + this.imageSettings.length) % this.imageSettings.length;
  }
  this.loadCurrentImage();  // Load the updated image based on the new index.
}

// Function to initialize the point cloud using instanced buffer geometry based on the image passed in.
// It creates and animates the point cloud, starting either in a randomized state or in its final position.
// startInFizzleState determines whether the points start in random positions.
initTestInstancedPointCloud(imageName, startInFizzleState = false, heightOffset = 0) {
  const paletteTexture = this.loader.getAsset(imageName);  // Load the image texture.
  const circleTexture = this.loader.getAsset("circleTexture");  // Load a circle texture for the points.
  const image = paletteTexture.image;

  // Create a canvas to draw the image and extract pixel data.
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;
  context.drawImage(image, 0, 0, image.width, image.height);

  const imageData = context.getImageData(0, 0, image.width, image.height).data;  // Get pixel data.
  const imageWidth = image.width;
  const imageHeight = image.height;

  const aspectRatio = imageWidth / imageHeight;  // Calculate aspect ratio for the image.
  const geometry = new THREE.InstancedBufferGeometry();  // Create instanced geometry for the points.

  // Set up a buffer attribute for the positions, initialized with a single point.
  const positions = new Float32Array([0, 0, 0]);
  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

  const offsets = [];  // Array to store the offsets for each instance.
  const colors = [];  // Array to store colors for each point.
  const finalPositions = [];  // Array to store the final positions of each point.
  const pixelCount = 25000;  // Desired number of points in the cloud.
  const pixelStep = Math.ceil(Math.sqrt((imageWidth * imageHeight) / pixelCount));  // Step size to get the correct number of points.

  // Calculate the range and offsets for the points based on the aspect ratio.
  const xRange = aspectRatio > 1 ? 24 : 24 * aspectRatio;
  const yRange = aspectRatio > 1 ? 24 / aspectRatio : 24;
  const xOffset = -xRange / 2;
  const yOffset = yRange + heightOffset;  // Apply the height offset.

  // Iterate over the image pixels to determine where to place points.
  for (let y = 0; y < imageHeight; y += pixelStep) {
    for (let x = 0; x < imageWidth; x += pixelStep) {
      const index = (y * imageWidth + x) * 4;  // Calculate pixel index in image data.
      const r = imageData[index] / 255;
      const g = imageData[index + 1] / 255;
      const b = imageData[index + 2] / 255;
      const a = imageData[index + 3] / 255;

      if (a > 0.5) {  // Only use pixels with significant alpha.
        let posX, posY, posZ;

        // Calculate final position based on the image and aspect ratio.
        const finalX = (x / imageWidth) * xRange + xOffset;
        const finalY = (y / imageHeight) * -yRange + yOffset;
        const finalZ = (Math.random() * 2 - 1) / 10;  // Add depth variation.

        if (startInFizzleState) {
          // Start with random positions if in fizzle state.
          posX = (Math.random() - 0.5) * 100;
          posY = (Math.random() - 0.5) * 100;
          posZ = (Math.random() - 0.5) * 100;
        } else {
          // Start in the calculated final position.
          posX = finalX;
          posY = finalY;
          posZ = finalZ;
        }

        offsets.push(posX, posY, posZ);  // Store the offset for this instance.
        colors.push(r, g, b);  // Store the color.
        finalPositions.push({ x: finalZ, y: finalY, z: -finalX });  // Store the final position.
      }
    }
  }

  // Set up the geometry attributes for offsets and colors.
  geometry.setAttribute('instanceOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
  geometry.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(new Float32Array(colors), 3));

  // Create the shader material for the point cloud.
  const material = new THREE.ShaderMaterial({
    uniforms: {
      alpha: { value: 0.0 },  // Initial alpha value (fully transparent).
      time: { value: 0.0 },  // Time uniform for wave motion.
      amplitude: { value: 0.5 },  // Amplitude for wave motion.
      frequency: { value: 0.2 },  // Frequency for wave motion.
      pointTexture: { value: circleTexture },  // Texture for the points.
      opacityCutoff: { value: 0.1 },  // Opacity cutoff to discard low-opacity pixels.
    },
    vertexShader: \`
      attribute vec3 instanceOffset;
      attribute vec3 instanceColor;
      uniform float alpha;
      uniform float time;
      uniform float amplitude;
      uniform float frequency;
      varying vec3 vColor;
      varying float vAlpha;

      void main() {
        vColor = instanceColor;
        vAlpha = alpha;

        // Apply sinusoidal wave motion to the position for subtle animation.
        vec3 animatedPosition = instanceOffset;
        animatedPosition.y += sin(animatedPosition.x * frequency + time) * amplitude;
        animatedPosition.x += cos(animatedPosition.y * frequency / 1.5 + time) * amplitude * 0.3;
        animatedPosition.z += sin(animatedPosition.y * frequency / 1.2 + time) * amplitude * 0.4;

        vec4 mvPosition = modelViewMatrix * vec4(position + animatedPosition, 1.0);
        gl_PointSize = 5.0;
        gl_Position = projectionMatrix * mvPosition;
      }
    \`,
    fragmentShader: \`
      uniform sampler2D pointTexture;
      uniform float opacityCutoff;
      varying vec3 vColor;
      varying float vAlpha;

      void main() {
        vec4 textureColor = texture2D(pointTexture, gl_PointCoord);
        float finalAlpha = vAlpha * textureColor.a;  // Combine the alpha value with the texture alpha.

        if (finalAlpha < opacityCutoff) {
          discard;  // Discard the fragment if alpha is below the cutoff.
        }

        gl_FragColor = vec4(vColor, finalAlpha);  // Set the final color and alpha.
      }
    \`,
    vertexColors: true,
    transparent: true,
  });

  // Create the point cloud using the geometry and material.
  const pointCloud = new THREE.Points(geometry, material);
  this.pointCloud = pointCloud;
  this.scene.add(pointCloud);  // Add the point cloud to the scene.

  return finalPositions;  // Return the calculated final positions for further use.
}

// Function to fizzle out the point cloud by moving points to random positions.
fizzleOutPointCloud() {
  const positions = this.pointCloud.geometry.attributes.instanceOffset.array;
  const numPoints = positions.length / 3;

  const randomPositions = [];
  for (let i = 0; i < numPoints; i++) {
    // Generate random positions for each point.
    randomPositions.push({
      x: (Math.random() - 0.5) * 200,
      y: (Math.random() - 0.5) * 200,
      z: (Math.random() - 0.5) * 200,
    });
  }
  return randomPositions;  // Return the random positions for animation.
}

// Function to handle the entire image transition process.
// It fades out the current image, switches the lighting, and loads the new image.
triggerImageTransition(textureName, color, intensity, heightOffset = 0) {
  const timeline = gsap.timeline();

  // Step 1: Fizzle out the current image, if there's an existing point cloud.
  if (this.pointCloud && this.pointCloud.geometry && this.pointCloud.geometry.attributes.instanceOffset) {
    const fizzlePositions = this.fizzleOutPointCloud();  // Get the random positions.

    // Animate the point cloud to fizzle out to random positions.
    this.animatePointCloud(fizzlePositions);

    // Animate alpha to fade out during the fizzle out process.
    timeline.to(this.pointCloud.material.uniforms.alpha, { value: 0, duration: 1.5, ease: 'power2.inOut' });

    // Delay the timeline by 1.5 seconds after the fizzle out effect.
    timeline.to({}, { duration: 1.5 });
  } else {
    console.warn("Point cloud or instanceOffset attribute is missing, skipping fizzle out.");
  }

  // Step 2: Change the lighting to match the new image.
  timeline.add(() => {
    this.switchColor(new THREE.Color(color), intensity);  // Switch to the target color and intensity.
  }, "+=1.5");

  // Step 3: Remove the current point cloud from the scene.
  timeline.add(() => {
    if (this.pointCloud) {
      this.pointCloud.geometry.dispose();  // Dispose of old geometry.
      this.scene.remove(this.pointCloud);
      this.pointCloud = null;  // Clear the reference after removal.
    }
  });

  // Step 4: Load the new image and start the point cloud from a fizzle state.
  let finalPositions;
  timeline.add(() => {
    finalPositions = this.initTestInstancedPointCloud(textureName, true, heightOffset);  // Initialize the point cloud with the new image.
  });

  // Step 5: Transition points to their final positions using the calculated \`finalPositions\`.
  timeline.add(() => {
    this.animatePointCloud(finalPositions, true);

    // Animate alpha to fade in during the transition to the final position.
    timeline.to(this.pointCloud.material.uniforms.alpha, { value: 1, duration: 1.5, ease: 'power2.inOut' });
  });
}

// Function to switch the ambient light color and intensity smoothly.
// This is called during image transitions to match the lighting with the image.
switchColor(targetColor, targetIntensity = 1) {
  gsap.to(this.ambient.color, {
    r: targetColor.r,
    g: targetColor.g,
    b: targetColor.b,
    duration: 1,  // Duration of the color change.
  });

  gsap.to(this.ambient, {
    intensity: targetIntensity,  // Change the intensity.
    duration: 1,  // Duration of the intensity change.
  });
}

// Function to animate the point cloud to the target positions.
// It uses GSAP to smoothly transition the points.
animatePointCloud(targetPositions, startIdleWave = false) {
  if (!this.pointCloud || !this.pointCloud.geometry || !this.pointCloud.geometry.attributes.instanceOffset) {
    console.error("PointCloud or its geometry is not properly initialized.");
    return;
  }

  const geometry = this.pointCloud.geometry;
  const offsets = geometry.attributes.instanceOffset.array;
  const numPoints = targetPositions.length;

  const initialPositions = [];
  for (let i = 0; i < numPoints; i++) {
    // Store the initial positions of each point.
    initialPositions.push({
      x: offsets[i * 3],
      y: offsets[i * 3 + 1],
      z: offsets[i * 3 + 2],
    });
  }

  // Use GSAP to animate the points from their initial positions to the target positions.
  gsap.to(initialPositions, {
    duration: 3,
    x: (i) => targetPositions[i].x,
    y: (i) => targetPositions[i].y,
    z: (i) => targetPositions[i].z,
    ease: "power4.inOut",  // Ease function for smooth animation.
    onUpdate: () => {
      for (let i = 0; i < numPoints; i++) {
        // Update the offsets for each point.
        offsets[i * 3] = initialPositions[i].x;
        offsets[i * 3 + 1] = initialPositions[i].y;
        offsets[i * 3 + 2] = initialPositions[i].z;
      }
      geometry.attributes.instanceOffset.needsUpdate = true;  // Mark the geometry for update.
    },
  });
}

// Update function to manage the animation timing.
// It updates the time uniform for wave motion in the shader.
Update() {
  const now = performance.now();
  this.dt = (now - this.lastUpdate) * 0.001;  // Calculate delta time.
  this.lastUpdate = now;
  this.totalTime = (now - this.startTime) * 0.001;  // Calculate total elapsed time.

  // Update the time uniform for wave motion in the shader.
  if (this.pointCloud && this.pointCloud.material && this.pointCloud.material.uniforms.time) {
    this.pointCloud.material.uniforms.time.value += this.dt;
  }
}
`;

export default voyageCode;
