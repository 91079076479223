import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

import { useResponsive } from 'src/hooks/use-responsive';
import { bgGradient } from 'src/theme/css';


// ----------------------------------------------------------------------

export default function Landing() {
    const theme = useTheme();

    const mdUp = useResponsive('up', 'md');

    return (
        <Box
            sx={{
                ...bgGradient({
                    imgUrl: '/assets/background/overlay_1.jpg',
                }),
                overflow: 'hidden',
            }}
        >
            <Container
                style={{
                    py: 15,
                    display: { md: 'flex' },
                    alignItems: { md: 'center' },

                }}
            >
                <Grid container style={{

                    height: `90vh`,
                }}>
                    <Grid>
                        <Typography paddingTop='125px' fontFamily="Glacial" fontWeight='100' fontStyle='regular' sx={{ color: '#FFFFFF', fontSize: '1.1rem', my: 3 }}>
                            We create <b> Stunning 3D Web Designs!</b>
                        </Typography>
                        <Stack
                            spacing={3}
                            direction={{ xs: 'column', sm: 'column' }}
                            alignItems={{ xs: 'center', md: 'center' }}
                            justifyContent={{ xs: 'center', md: 'center' }}
                        >
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
