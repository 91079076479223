import React from 'react';
import Card from '@mui/material/Card';
import './Home.css';
import { useScroll } from 'framer-motion';
import { ThemeProvider, createTheme } from '@mui/material/styles';


import { Grid } from '@mui/material';
import ContentUob from './ContentUob';
import TopbarUob from 'src/components/TopBarUob';
import Bottombar from 'src/components/BottomBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Container from 'src/components/Container';
import LoadingManager from '../Render/LoadingManager';
import ScrollProgress from 'src/components/scroll-progress';
import Uob from 'src/components/Scenes/Uob';
import { Button } from '@mui/material';
import { Box, Link, Typography } from '@mui/material';

import Features from './Features';
import Landing from './Landing';
import Contact from './Contact';
import Info from './Info';
import HomeFAQs from './faq';


const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

var t = false;
const UobPage: React.FC = () => {

  const [contentValue, setContentValue] = React.useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = React.useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = React.useState<string>('main');

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },

    }

  });
  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }
  const CloseContent = () => {
    t = false;
    console.log("close content");
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }
  const OpenContent_Stage_Part1 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {

      setButtonInfo('connectivity');
      OpenContent(ContentPanel.CONTENT_STAGE_PART_1);
    }
  }
  const OpenContent_Stage_Part2 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      setButtonInfo('personalisation');
      OpenContent(ContentPanel.CONTENT_STAGE_PART_2);
    }
  }
  const OpenContent_Stage_Part3 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      setButtonInfo('sustainability');
      OpenContent(ContentPanel.CONTENT_STAGE_PART_3);
    }
  }
  const OpenContent_Stage_None = () => {
    t = false;
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);

  }
  const ReturnState = () => {
    return t;
  }
  const { scrollYProgress } = useScroll();

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{
          position: 'fixed', // Fix the Three.js viewport position
          zIndex: -1, // Place it behind the content
        }} >
          <Uob
            collision_part1_callback={OpenContent_Stage_Part1}
            collision_part2_callback={OpenContent_Stage_Part2}
            collision_part3_callback={OpenContent_Stage_Part3}
            collision_none_callback={OpenContent_Stage_None}
            statecallback={ReturnState}
            modelcallback={'main'}
          >
          </Uob >
        </Grid>

        {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
          <>
            <HideOnScroll>
              <AppBar
                position={'fixed'}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#00000000',
                }}
              >
                <Container  sx={{ py: { xs: 2, sm: 4 } }}>
                  <TopbarUob />
                </Container>
              </AppBar>
            </HideOnScroll>
            <AppBar
              position={'fixed'}
              sx={{
                top: 'auto', bottom: 0,
                boxShadow: 'none',
                backgroundColor: '#00000000',
              }}
            >
              <Container   sx={{ py: { xs: 2, sm: 4 } }}>
                <Bottombar />
              </Container>
            </AppBar>
          </>
        }
        <Grid id='scc' container style={{
          position: 'fixed', // Fix the Three.js viewport position
          height: '100vh',
          width: '100vw',
          zIndex: -1, // Place it behind the content
          overflowY: 'scroll',
          overflowX: 'hidden',

        }} >
          {
            <>


              <>
                <Grid container item direction="column" style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#ffffff',
                  overflowY: 'scroll', // Allow vertical scrolling
                }}>
                  <ThemeProvider theme={theme}>
                    <Grid container style={{

                      height: `300vh`,
                    }}>

                    </Grid>


                  </ThemeProvider>
                </Grid>
              </>

            </>
          }
        </Grid>
        <Grid container item direction="column" style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',

          height: '00%', // Fill the full viewport height
          overflowY: 'scroll', // Allow vertical scrolling
        }}>
          {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Button
              variant='contained'
              onClick={() => {
                t = true;
                setInfoValue(contentValue);
                setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
              }}

              style={{
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 100,
                marginBottom: '30px',
                height: '40px',
                fontFamily: 'DMSans',
                fontSize: '1.0rem',
                fontWeight: '100',
                color: '#000000',
                backgroundColor: '#ffffffff',
                borderRadius: '30px',
                boxShadow: 'none',
              }}>
              Click to see {buttonInfo}</Button>
          }
          {infoValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <ContentUob
              content_panel_value={contentValue}
              content_open={contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN}
              content_close={CloseContent}>
            </ContentUob>
          }
          <Box
            component="footer"
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              textAlign: 'center',
              padding: 2,
            }}
          >
            <Typography color={'#ffffff'} variant="body2">
              Powered by{' '}
              <Link
                href="https://artcodestudio.co"
                target="_blank"
                color={'#ffffff'}
              >
                artcodestudio.co
              </Link>
            </Typography>
          </Box>

        </Grid>
      </div>
    </>
  );
};
export default UobPage;
