import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';
import { gsap } from "gsap";
import React, { Component } from 'react';
import { useMediaQuery } from '@mui/material';
// Renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { PropShader } from "../../Shaders/PropShader";
import OrbitalCamera from './OrbitalCamera';

const COLLISION_LAYER = 1;

class MovingFrames extends Scene {
  constructor(_options) {
    super(_options);
    this.isSectionActive = _options.isSectionActive; // Receive isSectionActive from props

    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;
    this.collision_none = _options.collision_none_callback;


    this.mouse = new THREE.Vector2();
    window.addEventListener('mousemove', this.onMouseMove.bind(this));

    this.hide = false;
    this.current = 0;
    this.clickEnabled = true;

    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;

    this.lastUpdate = performance.now();
    this.totalTime = 0.0;
    this.startTime = performance.now();

    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);

    this.dirLight = new THREE.DirectionalLight(0xffffff,0.05);

    let d = 20;
    let mapSize = 2048;
    this.dirLight.castShadow = true;

    this.dirLight.shadow.bias = 0.001;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.01;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(11, 10, -10);
    this.scene.add(this.dirLight);
    this.ambient = new THREE.AmbientLight(0xffffff, 0.2);

    this.buttonVisibilityCallback = null;

    this.scene.add(this.ambient);

    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;

    this.loadListener();
    this.startRender();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.lookDown = new Vector3(0, -30, 0);
    this.currPointLerped.set(-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set(-0.605, 0.054, 0.281);

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();

    this.requiredAssets = [
      { name: 'movingFramesModel', source: '/assets/moving_frames_1.glb' },
      { name: 'movingFramesShadowCasterModel', source: '/assets/moving_frames_1_shadow_caster.glb' },
      { name: 'movingFramesShadowCasterPlantModel', source: '/assets/moving_frames_1_plant.glb' },
      { name: 'movingFramesShadowCasterBinderModel', source: '/assets/moving_frames_1_binder.glb' },

      { name: 'movingFramesShadowCasterPlantTexture', source: '/assets/moving_frames_1_plant_opacity.jpg', type: 'texture' },

      { name: 'concreteTexture', source: '/assets/concrete_albedo.jpg', type: 'texture' },
      { name: 'concreteNormalTexture', source: '/assets/concrete_normal.jpg', type: 'texture' },
      { name: 'canvasNormalTexture', source: '/assets/canvas_normal.jpg', type: 'texture' },

      { name: 'mesh1Model', source: '/assets/moving_frames_1_mesh_1.glb' },

      { name: 'movingFramesTexture', source: '/assets/moving_frames_render.jpg', type: 'texture' },
    ];
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    this.hoveredText = null;
    
 // Initialize the current image index
 this.currentImageIndex = 0;

 // Define an array of images with their corresponding properties
 this.imageSettings = [
   {  color: 0xffffff, intensity: 0.65, dirIntensity: 7.15 },
   {  color: 0xbf9d84, intensity: 1.5, dirIntensity: 20.5 },
   {  color: 0xb9dbf0, intensity: 1.36, dirIntensity: 7.5 },
 ];

 // Load the first image
 this.loadCurrentImage();
}

loadCurrentImage() {
 const { color, intensity,dirIntensity } = this.imageSettings[this.currentImageIndex];
 this.triggerImageTransition(color, intensity,dirIntensity);
}

changeImage(direction) {
 if (direction === 'next') {
   this.currentImageIndex = (this.currentImageIndex + 1) % this.imageSettings.length;
 } else if (direction === 'prev') {
   this.currentImageIndex = (this.currentImageIndex - 1 + this.imageSettings.length) % this.imageSettings.length;
 }
 this.loadCurrentImage();
  }

  initializeLoading() {
    this.loader.groups.assets = [{ name: 'paradeAssets', items: this.requiredAssets }];
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance }) {
    if (this.postProcess) {
        if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
            this.postProcess.bloomPass.strength = bloomIntensity;
        }
  
        if (bokehAperture !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['aperture'].value = bokehAperture;
        }

        if (focusDistance !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['focus'].value = focusDistance;
        }
    }
}

triggerImageTransition(color, intensity, dirIntensity) {
  if (!this.newCamera) return;

  const timeline = gsap.timeline();

  // Step 1: Change lighting
  timeline.add(() => {
      this.switchColor(new THREE.Color(color), intensity, dirIntensity);
  });

  // Step 2: Handle model movement and visibility
  timeline.add(() => {
      // Move all models out of view and hide them when off-screen
      gsap.to(this.shadow1Model.all_model[0].position, {
          x: -100,
          y: 0,
          z: -100,
          duration: 2,
          ease: 'power4.inOut',
          onComplete: () => { 
              if (this.currentImageIndex !== 0) {
                  this.shadow1Model.all_model[0].visible = false;
              }
          }
      });
      gsap.to(this.shadow2Model.all_model[0].position, {
          x: -100,
          y: 0,
          z: -100,
          duration: 2,
          ease: 'power4.inOut',
          onComplete: () => { 
              if (this.currentImageIndex !== 1) {
                  this.shadow2Model.all_model[0].visible = false;
              }
          }
      });
      gsap.to(this.shadow3Model.all_model[0].position, {
          x: -100,
          y: 0,
          z: -100,
          duration: 2,
          ease: 'power4.inOut',
          onComplete: () => { 
              if (this.currentImageIndex !== 2) {
                  this.shadow3Model.all_model[0].visible = false;
              }
          }
      });

      // Move the selected model to the origin and keep it visible
      if (this.currentImageIndex === 0) {
          this.shadow1Model.all_model[0].visible = true;
          gsap.to(this.shadow1Model.all_model[0].position, {
              x: 0,
              y: 0,
              z: 0,
              duration: 2,
              ease: 'power4.inOut'
          });
      } else if (this.currentImageIndex === 1) {
          this.shadow2Model.all_model[0].visible = true;
          gsap.to(this.shadow2Model.all_model[0].position, {
              x: 0,
              y: 0,
              z: 0,
              duration: 2,
              ease: 'power4.inOut'
          });
      } else if (this.currentImageIndex === 2) {
          this.shadow3Model.all_model[0].visible = true;
          gsap.to(this.shadow3Model.all_model[0].position, {
              x: 0,
              y: 0,
              z: 0,
              duration: 2,
              ease: 'power4.inOut'
          });
      }
  });
}


  
  switchColor(targetColor, targetIntensity = 1, dirIntensity = 1) {
    gsap.to(this.ambient.color, {
      r: targetColor.r,
      g: targetColor.g,
      b: targetColor.b,
      duration: 1,
    });
    gsap.to(this.dirLight.color, {
      r: targetColor.r,
      g: targetColor.g,
      b: targetColor.b,
      duration: 1,
    });
    gsap.to(this.ambient, {
      intensity: targetIntensity,
      duration: 1,
    });
    gsap.to(this.dirLight, {
      intensity: dirIntensity,
      duration: 1,
    });
  }

  onAllAssetsLoaded() {
 // Ensure video plays on user interaction
 document.addEventListener('mousemove', () => {
  if ( this.mainVideo.paused) {
    this.mainVideo.play().catch((error) => {
          console.log('Video playback failed:', error);
      });
  }
}, { once: true }); // Remove the event listener after it fires once
    console.log("All assets loaded");
    this.createSceneObjects();
    this.startIntroSequence();
    this.mainVideo.play();
    const onMouseClick = (event) => {
      if (this.clickEnabled) {
        console.log("Mouse clicked");
        this.clickEnabled = false;
        window.removeEventListener('click', onMouseClick);
        window.removeEventListener('touchstart', onMouseClick);
      }
    };

    const onMouseUp = (event) => {
      this.clickEnabled = true;
      console.log("Mouse up");
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('touchstart', onMouseClick, false);
    };

    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }


  startIntroSequence() { 
    // Load the first sequence
    this.loadCurrentImage();
    if (this.buttonVisibilityCallback) {
      this.buttonVisibilityCallback();
    }
  }

  onMouseMove(event) {

  }

  

  createSceneObjects() {

    // get all asset references
    const paletteTexture = this.loader.getAsset("concreteTexture");
    const paletteNormalTexture = this.loader.getAsset("concreteNormalTexture");
    const plantCasterTexture = this.loader.getAsset("movingFramesShadowCasterPlantTexture");

    this.standardMaterial = new THREE.MeshStandardMaterial({
        normalMap:paletteNormalTexture,
        receiveShadow: true,
        normalScale:new Vector2(0.5,0.5),
        roughness:0.5,
        dithering: true,
        castShadow: true
    });
    this.standardMaterial.shininess = 5;

    this.p1model = new Model({
        modelLink: 'movingFramesModel',
        material: this.standardMaterial,  // Store the material here
        position: new THREE.Vector3(0 ,0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(0, 0, 0),
        scene: this.scene,
        name: 'movingFramesModel'
    });
    this.p1model.all_model[0].position.set(0, 0, 0);

    // shadow caster
    this.shadow1Model = new Model({
      modelLink: 'movingFramesShadowCasterModel',
      material: this.standardMaterial,  // Store the material here
      position: new THREE.Vector3(17, 1, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'movingFramesModel'
  });
  this.shadow1Model.all_model[0].position.set(-9,-9,-9);
  this.shadow1Model.all_model[0].scale.set(1, 1, 1);

  // plant texture
  this.plantMaterial = new THREE.MeshStandardMaterial({
    alphaMap: plantCasterTexture, // Opacity texture (alpha map)
    transparent: true, // Enable transparency for the alpha map
    alphaTest: 0.5, // Discard pixels with alpha below 0.5 (cutoff for transparency)
    side: THREE.DoubleSide, // Render both sides of the material
    dithering: true,
    receiveShadow: true,
    castShadow: true
});

  // shadow caster binder
  this.shadow2Model = new Model({
    modelLink: 'movingFramesShadowCasterPlantModel',
    material: this.plantMaterial,  // Store the material here
    position: new THREE.Vector3(0, 0, 0),
    rotation: new THREE.Vector3(0.0, 0.0, 0.0),
    scale: new THREE.Vector3(1, 1, 1),
    scene: this.scene,
    name: 'movingFramesPlantModel'
});
this.shadow2Model.all_model[0].position.set(-9,-9,-9);
  // shadow caster plant
  this.binderMaterial = new THREE.MeshStandardMaterial({
    normalMap:paletteNormalTexture,

    dithering: true,
    receiveShadow: true,
    castShadow: true
});

  // shadow caster binder
  this.shadow3Model = new Model({
    modelLink: 'movingFramesShadowCasterBinderModel',
    material: this.binderMaterial,  // Store the material here
    position: new THREE.Vector3(0, 0, 0),
    rotation: new THREE.Vector3(0.0, 0.0, 0.0),
    scale: new THREE.Vector3(1, 1, 1),
    scene: this.scene,
    name: 'movingFramesBinderModel'
});


this.shadow2Model.all_model[0].position.set(-999,-999,-999);

  this.shadow3Model.all_model[0].position.set(-9, -18, -18);

    // Add a plane with a video texture
    this.createVideoPlane();

    this.fullLoaded = true;
}

createVideoPlane() {

  const paletteTexture = this.loader.getAsset("canvasNormalTexture");

  // Load video texture
  const video = document.createElement('video');
  video.src = '/assets/artcode_chapter_2_video.mp4'; // Path to your local video file
  video.loop = true;
  video.muted = true;
  video.setAttribute('muted', ''); // Ensure it's muted
  video.playsInline = true; // Important for mobile devices
  video.autoplay = true; // Autoplay the video
  this.mainVideo = video;
  // Attempt to play the video periodically
  const playInterval = setInterval(() => {
      if (video.paused) {
          video.play().catch((error) => {
              console.log('Video playback attempt failed:', error);
          });
      }
  }, 2000); // Try every 2 seconds

  video.play().catch((error) => {
      console.log('Initial video playback failed:', error);
  });

  const videoTexture = new THREE.VideoTexture(video);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;
  videoTexture.format = THREE.RGBFormat;

  // Create a material using the video texture
  const videobasicMaterial = new THREE.MeshStandardMaterial({
      map: videoTexture,
      normalMap: paletteTexture,
      emissiveMap: videoTexture,
      emissiveIntensity: 1,
  });

  // Create a model with the custom shader material
  this.mesh1model = new Model({
      modelLink: 'mesh1Model',
      material: videobasicMaterial,  // Use the custom shader material here
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'mesh12Model'
  });

  // Stop the interval once the video starts playing
  video.addEventListener('play', () => {
      clearInterval(playInterval);
  });
}



  detectDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent)) {
      return 'phone';
    }
    return 'computer';
  }

  componentDidMount() {
    this.SceneMount();

    this.setCamera(40, 40,window.innerHeight>window.innerWidth ? 55 : 35);
    this.setPostProcess();
    this.orbitalCamera = new OrbitalCamera(this.newCamera.threeCamera);
    this.orbitalCamera.spherical.set(21, 999, 40);
    this.orbitalCamera.target = new THREE.Vector3(0, 12, -1);
    this.orbitalCamera.minPhi =1.2;
    this.orbitalCamera.maxPhi =1.6;
    this.orbitalCamera.minTheta =38.7;
    this.orbitalCamera.maxTheta =39.6;
    this.orbitalCamera.minRadius = 10;
    this.orbitalCamera.maxRadius = 21.5;
  }

  componentWillUnmount() {
    // Clean up if necessary
  }

  loadListener() {
    document.addEventListener("mousedown", this.onPointerDown.bind(this));
    document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));
    document.addEventListener("wheel", this.onZoom.bind(this));
  }
  setSectionActive(isActive) {
    this.isSectionActive = isActive;
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    const deltaX = clientX - this.lastPointerPosition.x;
    const deltaY = clientY - this.lastPointerPosition.y;

    const deltaTheta = deltaX * 0.05;
    const deltaPhi = deltaY * 0.05;

    this.orbitalCamera.rotate(deltaTheta, deltaPhi);

    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    const delta = event.deltaY * 0.05;
    this.orbitalCamera.zoom(delta);
  }


  Update() {
    if (this.isSectionActive) {
        // Skip camera updates when a section is active
        return;
    }

    if (this.mainVideo && this.mainVideo.paused) {
      this.mainVideo.play().catch((error) => {
            console.log('Video playback failed:', error);
        });
    }
    const now = performance.now();
    this.dt = (now - this.lastUpdate) * 0.001;
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime) * 0.001;


    // Update the time uniform for the wave motion
  
    this.dirLight.position.set(18+ (Math.cos(this.totalTime/2))*2, 28 + Math.cos(this.totalTime), -48 + Math.cos(this.totalTime*2)*3 );

    this.orbitalCamera.update();
    this.newCamera.threeCamera.position.copy(this.orbitalCamera.camera.position);
    this.newCamera.threeCamera.lookAt(this.orbitalCamera.target);
    this.newCamera.threeCamera.updateMatrixWorld(true);

    this.boot = true;
}

}

export default React.forwardRef((props, ref) => <MovingFrames {...props} ref={ref} />);
