import React from 'react';
import Card from '@mui/material/Card';
import './Home.css';
import { useScroll } from 'framer-motion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from '@mui/material';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import ContentUob from './ContentUob';
import TopbarMiniGame from 'src/components/TopBarMiniGame';
import Bottombar from 'src/components/BottomBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Container from 'src/components/Container';
import LoadingManager from '../Render/LoadingManager';
import ScrollProgress from 'src/components/scroll-progress';
import Box from '@mui/material';
import MiniGame from 'src/components/Scenes/MiniGame';
import { Button } from '@mui/material';


const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

var t = false;
const MiniGamePage: React.FC = () => {

  const [contentValue, setContentValue] = React.useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = React.useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = React.useState<string>('main');
  const [score, setScore] = React.useState<Number>(0);
  const [countdown, setCountdown] = React.useState<Number>(0);

  const [gameOver, setGameOver] = React.useState<Boolean>(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },

    }

  });
  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }
  const CloseContent = () => {
    t = false;
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);

    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }
  const OpenContent_Stage_Part1 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {

      setButtonInfo('connectivity');
      OpenContent(ContentPanel.CONTENT_STAGE_PART_1);
    }
  }
  const OpenContent_Stage_Part2 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      setButtonInfo('personalisation');
      OpenContent(ContentPanel.CONTENT_STAGE_PART_2);
    }
  }
  const OpenContent_Stage_Part3 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      setButtonInfo('sustainability');
      OpenContent(ContentPanel.CONTENT_STAGE_PART_3);
    }
  }
  const OpenContent_Stage_None = () => {
    t = false;
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);

  }
  const ScoreUpdate = (number: Number) => {
    setScore(number);
    console.log(number);
    return;
  }
  const CountdownUpdate = (number: Number) => {
    setCountdown(number);
    console.log(number);
    return;
  }
  const GameOverUpdate = (over: Boolean) => {
    setGameOver(over);
    //OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);

  }
  const ReturnState = () => {
    return t;
  }
  const { scrollYProgress } = useScroll();

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{
          position: 'fixed', // Fix the Three.js viewport position
          zIndex: -1, // Place it behind the content
        }} >
          <MiniGame
            collision_part1_callback={OpenContent_Stage_Part1}
            collision_part2_callback={OpenContent_Stage_Part2}
            collision_part3_callback={OpenContent_Stage_Part3}
            collision_none_callback={OpenContent_Stage_None}
            score_callback={ScoreUpdate}
            gameover_callback={GameOverUpdate}
            countdown_callback={CountdownUpdate}
            statecallback={ReturnState}
            modelcallback={'main'}
          >
          </MiniGame >
        </Grid>

        {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
          <>
            <HideOnScroll>
              <AppBar
                position={'fixed'}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#00000000',
                }}
              >
                <Container   sx={{ py: { xs: 2, sm: 4 } }}>
                  <TopbarMiniGame />
                </Container>
              </AppBar>
            </HideOnScroll>
            <AppBar
              position={'fixed'}
              sx={{
                top: 'auto', bottom: 0,
                boxShadow: 'none',
                backgroundColor: '#00000000',
              }}
            >
              <Container   sx={{ py: { xs: 2, sm: 4 } }}>
                <Bottombar />
              </Container>
            </AppBar>
          </>
        }
        <Grid id='scc' container style={{
          position: 'fixed', // Fix the Three.js viewport position
          height: '100vh',
          width: '100vw',
          zIndex: -1, // Place it behind the content
          overflowY: 'scroll',
          overflowX: 'hidden',

        }} >
          {
            <>
              {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
                <>
                  <Grid container item direction="column" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '8vh',
                    color: '#ffffff',
                    overflowY: 'scroll', // Allow vertical scrolling
                  }}>
                    <ThemeProvider theme={theme}>
                      <Typography color={"#ffffff77"} fontSize="1rem" fontFamily="Glacial" variant="h3" fontWeight="900">
                        Tap circles - {countdown} seconds left!
                      </Typography>
                      <Typography color={"#cebaff"} fontFamily="Glacial" variant="h3" fontWeight="900">
                        {score}
                      </Typography>

                      {(countdown < 1) &&
                        <>
                          <Link
                            underline="none"
                            component="a"
                            href="/"
                            fontFamily={'Glacial'}
                            style={{ letterSpacing: '5px' }}
                          >
                            <Button
                              variant='contained'
                              onClick={() => {
                                window.location.reload();

                              }}

                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '10vh',
                                height: '40px',
                                fontFamily: 'DMSans',
                                fontSize: '1.0rem',
                                fontWeight: '100',
                                color: '#000000',
                                backgroundColor: '#ffffffff',
                                borderRadius: '30px',
                                boxShadow: 'none',
                              }}>
                              See artcode website</Button>
                          </Link>
                          <Button
                            variant='contained'
                            onClick={() => {
                              window.location.reload();

                            }}

                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '25px',
                              height: '40px',
                              fontFamily: 'DMSans',
                              fontSize: '1.0rem',
                              fontWeight: '100',
                              color: '#000000',
                              backgroundColor: '#ffffffff',
                              borderRadius: '30px',
                              boxShadow: 'none',
                            }}>
                            Click to restart</Button>

                        </>
                      }

                    </ThemeProvider>
                  </Grid>
                </>
              }
            </>
          }
        </Grid>
        <Grid container item direction="column" style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',

          height: '00%', // Fill the full viewport height
          overflowY: 'scroll', // Allow vertical scrolling
        }}>
          {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Button
              variant='contained'
              onClick={() => {
                t = true;
                setInfoValue(contentValue);
                setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
              }}

              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '75vh',
                marginBottom: '30px',
                height: '40px',
                fontFamily: 'DMSans',
                fontSize: '1.0rem',
                fontWeight: '100',
                color: '#000000',
                backgroundColor: '#ffffffff',
                borderRadius: '30px',
                boxShadow: 'none',
              }}>
              Click to see {buttonInfo}</Button>
          }



        </Grid>
      </div>
    </>
  );
};
export default MiniGamePage;
