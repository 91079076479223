import React from 'react';
import Card from '@mui/material/Card';
import './Home.css';
import { useScroll } from 'framer-motion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


import { Grid } from '@mui/material';
import Content from './Content';
import Topbar from 'src/components/TopBar';
import Bottombar from 'src/components/BottomBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Container from 'src/components/Container';
import LoadingManager from '../Render/LoadingManager';
import ScrollProgress from 'src/components/scroll-progress';
import Box from '@mui/material';
import Main from 'src/components/Scenes/Main';
import Features from './Features';
import Landing from './Landing';
import Contact from './Contact';
import Info from './Info';
import HomeFAQs from './faq';


const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_ABOUT_ME,
  CONTENT_STAGE_MOTIONAL,
  CONTENT_STAGE_CONTACT,
  CONTENT_STAGE_PROJECT
}
var t = false;
const LinkProfile: React.FC = () => {

  const [contentValue, setContentValue] = React.useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#ffffff'
      },

    }

  });
  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }
  const CloseContent = () => {
    t = false;
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }
  const OpenContent_Stage_AboutMe = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_ABOUT_ME);
    }
  }
  const ReturnState = () => {
    return t;
  }
  const { scrollYProgress } = useScroll();

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{
          position: 'fixed', // Fix the Three.js viewport position
          zIndex: -1, // Place it behind the content

        }} >
          <Main
            collision_about_me_callback={OpenContent_Stage_AboutMe}
            statecallback={ReturnState}
            modelcallback={'title'}
          >
          </Main >
        </Grid>

        {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
          <>
            <HideOnScroll>
              <AppBar
                position={'fixed'}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#00000000',
                }}
              >
                <Container   sx={{ py: { xs: 2, sm: 4 } }}>
                  <Topbar />
                </Container>
              </AppBar>
            </HideOnScroll>
            <AppBar
              position={'fixed'}
              sx={{
                top: 'auto', bottom: 0,
                boxShadow: 'none',
                backgroundColor: '#00000000',
              }}
            >
              <Container   sx={{ py: { xs: 2, sm: 4 } }}>
                <Bottombar />
              </Container>
            </AppBar>
          </>}
        <Grid id='scc' container style={{
          position: 'fixed', // Fix the Three.js viewport position
          height: '100vh',
          width: '100vw',
          zIndex: -1, // Place it behind the content
          overflowY: 'scroll',
          overflowX: 'hidden',

        }} >
          {
            <>

              {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
                <>
                  <Grid container item direction="column" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                    overflowY: 'scroll', // Allow vertical scrolling
                  }}>
                    <ThemeProvider theme={theme}>

                      <Typography fontFamily="Glacial" fontWeight='100' fontStyle='regular' sx={{ color: '#FFFFFF', fontSize: '2.5rem', }}>
                        Theon Teo
                      </Typography>
                      <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', marginBottom: '150px', color: '#ffffff' }}>
                        DEVELOPER | FOUNDER
                      </Typography>

                      <Grid id='c' container style={{

                        height: '20vh',


                      }} ></Grid>
                      <Link href="https://artcodestudio.co" target="_blank" >
                        <Button variant="contained" color="secondary" size="large" sx={{ marginTop: '200px', margin: '10px', width: '250px', borderRadius: '30px' }}>
                          artcode website
                        </Button>
                      </Link>
                      <Link href=" https://api.whatsapp.com/send?phone=6597726655" target="_blank" >
                        <Button variant="contained" color="secondary" size="large" sx={{ margin: '10px', width: '250px', borderRadius: '30px' }}>
                          phone
                        </Button>
                      </Link>
                      <Link href="mailto: theon@artcodestudio.co" target="_blank" >
                        <Button variant="contained" color="secondary" size="large" sx={{ margin: '10px', width: '250px', borderRadius: '30px' }}>
                          email
                        </Button>
                      </Link>
                    </ThemeProvider>
                  </Grid>
                </>
              }
            </>
          }
        </Grid>
        <Grid container item direction="column" style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',

          height: '00%', // Fill the full viewport height
          overflowY: 'scroll', // Allow vertical scrolling
        }}>
          <Content
            content_panel_value={contentValue}
            content_open={contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN}
            content_close={CloseContent}>
          </Content>
        </Grid>
      </div>
    </>
  );
};
export default LinkProfile;
