import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';

//renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { StandardShader } from "../../Shaders/StandardShader";
import { PropShader } from "../../Shaders/PropShader";
import { EmmisiveShader } from "../../Shaders/EmmisiveShader";

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
class Main extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    // call back for mouse collision detection
    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;


    this.hide = false;
    this.current = 0;

    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = Date.now();
    this.totalTime = 0.0;
    //camera vectors
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);

    this.dirLight = new THREE.DirectionalLight(0xffffff, 1.5);
    this.ambient = new THREE.AmbientLight(0xffffff, 1.5, 0);

    let d = 50;
    let r = 1;
    let mapSize = 2048;
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(-20, 20, 20);
    this.scene.add(this.ambient);
    this.scene.add(this.dirLight);
    this.scene.add(this.dirLight.target);

    //this.scene.add(helper);
    this.storeTop = 0.0;

    this.create_collision_box(new THREE.Vector3(0, -19, 18), 'collision_part1');
    this.create_collision_box(new THREE.Vector3(-16, -39, 18), 'collision_part2');
    this.create_collision_box(new THREE.Vector3(-16, -59, -1), 'collision_part3');

    //add event listeners
    this.loadListener();
    this.startRender();
    this.generateCamCurve();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.currPointLerped.set
      (-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set
      (-0.605, 0.054, 0.281);


    this.requiredAssets = [
      { name: 'titleModel', source: '/assets/title_1.glb' },
      { name: 'paletteTexture', source: '/assets/palette.jpg', type: 'texture' },
    ];

    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

  }

  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'uobAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  onAllAssetsLoaded() {
    console.log("All assets loaded");
    this.createSceneObjects();
  }

  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("paletteTexture");

    console.log(paletteTexture);
    this.p1Material = new Material({
      uniforms: {
        downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
      },
      vertexShader: PropShader.vertexShader,
      fragmentShader: PropShader.fragmentShader
    });

    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });
    //standardMaterial.map.encoding = THREE.sRGBEncoding;

    const modelName = 'titleModel';
    this.p1model = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'bgmainScene'
    });
    this.fullLoaded = true;
  }

  create_collision_box = (position, name) => {
    this.cube = new Mesh(
      new BoxGeometry(8, 8, 8),
      new MeshBasicMaterial({ color: 0xffffffff }),
    );
    this.cube.position.set(position.x, position.y, position.z);
    this.cube.visible = false;
    this.cube.name = name;
    this.scene.add(this.cube);
  }

  generateCamCurve() {
    //position points
    var posPoints = [];

    posPoints.push(new THREE.Vector3(-25, 3, 22));
    posPoints.push(new THREE.Vector3(-15, -18, 40));
    posPoints.push(new THREE.Vector3(-41, -35, 3));
    posPoints.push(new THREE.Vector3(-31, -55, -29));
    posPoints.push(new THREE.Vector3(-69, -94, 0));



    this.spline = new THREE.CatmullRomCurve3(posPoints);
    this.points = this.spline.getPoints(200);

    //rotation points
    var lookAtPoints = [];
    lookAtPoints.push(new THREE.Vector3(-3, -4, 0));
    lookAtPoints.push(new THREE.Vector3(-2, -20, 17));
    lookAtPoints.push(new THREE.Vector3(-18, -40, 13));
    lookAtPoints.push(new THREE.Vector3(-18, -63, -9));
    lookAtPoints.push(new THREE.Vector3(-33, -99, 0));

    ;

    this.lookAtSpline = new THREE.CatmullRomCurve3(lookAtPoints);
    this.lookAtPoints = this.lookAtSpline.getPoints(200);
  }
  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }
  loadAllModels() {


    // render given model
    this.loader.waitForAssets(() => {
      var paletteTexture = this.loader.getAsset("paletteTexture");

      console.log(paletteTexture);
      this.p1Material = new Material(
        {
          uniforms: {
            //standardTexture: { value: p1Texture },
            downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
          },
          vertexShader: PropShader.vertexShader,
          fragmentShader: PropShader.fragmentShader
        }
      );

      var computer = this.detectDevice();
      const standardMaterial = new THREE.MeshPhongMaterial();

      standardMaterial.map = paletteTexture;
      //standardMaterial.map.encoding = THREE.sRGBEncoding;
      standardMaterial.receiveShadow = true;
      standardMaterial.shininess = 65;
      //standardMaterial.envMapIntensity = 2.0;
      standardMaterial.dithering = true;


      // standardMaterial.metalness = 20;
      standardMaterial.castShadow = true;
      // standardMaterial.envMap = paletteTexture;
      console.log(computer);
      var modelName = this.modelLoad == 'main' ? 'bgModel' : 'titleModel';
      this.p1model = new Model({
        modelLink: modelName,
        material: standardMaterial,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'bgmainScene'
      });
      this.fullLoaded = true;
    });


  }
  componentDidMount() {
    console.log("scene 1 mounted")
    this.SceneMount();
    this.setPostProcess();
    //this.setCamera(60, 60, this.detectDevice() == 'computer' ? 40 : 80);
  }
  componentWillUnmount() {
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    document.addEventListener("mousemove",
      (e) => {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
      });
  }

  lerp_model(model, time) {
    if (model && model.all_model && model.all_model.length > 0) {
      for (var i = 0; i < model.all_model.length; ++i) {
        if (model.all_model[i].name.includes("move")) {
          var cosy = Math.cos(time * 0.001 + i * 0.676598) * 0.0125;
          model.all_model[i].position.setY(cosy + model.all_model_og_position[i].y);
        }
      }
    }
  }
  Update() {
    //update delta time
    var now = Date.now();
    this.dt = now - this.lastUpdate;
    this.lastUpdate = now;
    this.totalTime += this.dt;
    this.lerp_model(this.p1model, this.totalTime);
    //this.lerp_model(this.p2model, this.totalTime);

    if (this.storeTop > 0.1 && this.getState() === false) {
      console.log("scroll target : " + this.storeTop);
      document.getElementById('scc').scrollTop = this.storeTop;
      this.storeTop = 0;
    }
    const t2 = document.getElementById('scc').scrollTop / window.innerHeight / 2;
    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
    if (this.getState() === false) {
      this.append = 0;
      if (this.detectDevice() == 'computer') {
        this.lerpedMouse.x = MathUtils.lerp(this.lerpedMouse.x, this.mouseNormalized.x, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouse.y = MathUtils.lerp(this.lerpedMouse.y, this.mouseNormalized.y, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.x = MathUtils.lerp(this.lerpedMouseRaw.x, this.mouse.x, 0.01 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.y = MathUtils.lerp(this.lerpedMouseRaw.y, this.mouse.y, 0.01 * 1 / this.dt * 1.5);
      }
    }
    //get page position and lerp camera 

    this.pageLerp = t2 * 0.005;
    this.pageLerp2 = t2;
    this.pageLerp3 = t2;


    //modify camera position
    this.newCamera.threeCamera.getWorldDirection(this.camDirection);

    this.camSide.crossVectors(this.camDirection, this.newCamera.threeCamera.up);
    this.camUp.crossVectors(this.camSide, this.newCamera.threeCamera.up);


    this.camPos.set(0.0, 0.0, 0.0);
    this.camLookAt.set(0.0, 0, 0);

    this.camSide.multiplyScalar(this.lerpedMouse.x * 25);

    var index = MathUtils.clamp(this.pageLerp2, 0.0, 0.99);

    let currPoint = this.points[parseInt(index * 200, 10)];
    let currPointLookAt = this.lookAtPoints[parseInt(index * 200, 10)];
    this.tempPoint = currPoint;
    this.tempPointLookAt = currPointLookAt;
    if (this.getState() === false) {
      this.sendPoint.copy(this.camPos);
      this.sendPoint.add(currPoint);
      this.sendPoint.add(this.camDirection);
      this.sendPoint.add(this.camSide);
      this.sendPoint.add(this.camUp);


      this.sendPointLookAt.copy(this.camLookAt);
      this.sendPointLookAt.add(currPointLookAt);
    } else {
      //var offset = new THREE.Vector3(0, 0, 0);

      //var distance = 30;
      // offset.y = this.camPosOverride.y;
      //offset.x = this.lookPosOverride.x + distance * Math.sin(this.totalTime * 0.001);
      // offset.z = this.lookPosOverride.z + distance * Math.cos(this.totalTime * 0.001);
      // this.sendPoint = offset;
      // console.log(this.sendPoint);
      this.sendPoint = this.camPosOverride;
      this.sendPointLookAt = this.lookPosOverride;
    }

    this.currPointLerped.lerp(this.sendPoint, 0.05);
    this.currPointLookAtLerped.lerp(this.sendPointLookAt, 0.05);
    this.dirLight.position.set(this.currPointLerped.x - 30, this.currPointLerped.y + 30, this.currPointLerped.z - (index * 60 - 30));

    this.dirLight.target.position.set(this.currPointLerped.x, this.currPointLerped.y, this.currPointLerped.z);
    // this.dirLight.shadow.camera.position.set(-30 + this.currPointLerped.x, 30 + this.currPointLerped.y, -(index * 60 - 30) + this.currPointLerped.z);
    if (this.getState() === 0) {

      this.clickTimeout++;
    } else {
      this.clickTimeout = 0;
    }
    this.newCamera.setPosition(this.currPointLerped);

    this.newCamera.threeCamera.lookAt(this.currPointLookAtLerped);
    if (document.getElementById('scontent')) {

      const contentTop = document.getElementById('scontent').scrollTop / window.innerHeight / 2;
      console.log(contentTop);
    }
    if (!this.boot && this.newCamera !== undefined) {
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();

      const t = () => {
        if (this.getState() === true) return;

        raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
        const intersects = raycaster.intersectObjects(this.scene.children);
        for (let i = 0; i < intersects.length; i++) {

          if (intersects[i].object.name === 'collision_part1') {
            this.storeTop = document.getElementById('scc').scrollTop;
            this.camPosOverride = new THREE.Vector3
              (-10, -9, 38);
            this.lookPosOverride = new THREE.Vector3
              (0, -19, 18);
            this.collision_part1();
            break;
          }
          if (intersects[i].object.name === 'collision_part2') {
            this.storeTop = document.getElementById('scc').scrollTop;
            this.camPosOverride = new THREE.Vector3
              (-28, -30, 3);
            this.lookPosOverride = new THREE.Vector3
              (-18, -40, 13);
            this.collision_part2();
            break;
          }
          if (intersects[i].object.name === 'collision_part3') {
            this.storeTop = document.getElementById('scc').scrollTop;
            this.camPosOverride = new THREE.Vector3
              (-28, -53, -12);
            this.lookPosOverride = new THREE.Vector3
              (-18, -63, -9);
            this.collision_part3();
            break;
          }
        }
      }

      const f = (delta) => {

        this.scrollDelta -= delta * 0.0125;

        const scrollMin = 20;
        const scrollMax = 75;
        if (this.scrollDelta < scrollMin) {
          this.scrollDelta = scrollMin;
        }
        if (this.scrollDelta > scrollMax) {
          this.scrollDelta = scrollMax;
        }
      }
      function onMouseWheel(event) {
        f(event.deltaY);
      }

      function onMouseClick(event) {
        t();
      }
      function onMouseMove(event) {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
      }
      window.addEventListener('mousewheel', onMouseWheel, false);
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('mousemove', onMouseMove, false);
      this.boot = true;
    }
  }
}

export default Main
