import * as THREE from "three";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

export function loadJSON(path) {
    return new Promise((resolve, reject) => {
        fetch(path)
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function loadCurveFromJSON(jsonPath) {

    let curveJSON = await loadJSON(jsonPath);
    let curve = createCurveFromJSON(curveJSON);
    console.log(curve);

    return curve
}

function createCurveFromJSON(json) {

    // Extract the vertices array from the JSON object
    const vertices = json.points;

    // Create an empty array to store THREE.Vector3 instances
    const points = [];

    // Iterate over the vertices and push THREE.Vector3 instances to the points array
    for (let i = 0; i < vertices.length; i += 3) {
        const x = vertices[i].x;
        const y = vertices[i].y;
        const z = vertices[i].z;
        points.push(new THREE.Vector3(x, y, z));
    }

    // Create a CatmullRomCurve3 using the points array
    const curve = new THREE.CatmullRomCurve3(points);

    curve.closed = json.closed;

    return curve;
}

export async function createText(text, position, rotation) {
    const font = await loadFont();
    const geometry = new TextGeometry(text, {
        font,
        size: 0.5,
        height: 0.1,
    });
    const material = new THREE.MeshPhongMaterial({ color: '#ffffff' });
    const textMesh = new THREE.Mesh(geometry, material);
    textMesh.position.set(position.x, position.y, position.z);
    textMesh.rotation.set(rotation.x, rotation.y, rotation.z);
    return textMesh;
}
export const loadFont = () => {
    return new Promise((resolve) => {
        const fontLoader = new FontLoader();
        fontLoader.load('/fonts/Akira Expanded_Regular.json', (font) => {
            resolve(font);
        });
        fontLoader.load('/fonts/Akira Expanded_Bold.json', (font) => {
            resolve(font);
        });
    });
};


const easeInQuad = (t, b, c, d) => {
	t /= d;
	return c * t * t + b;
};

const easeOutQuad = (t, b, c, d) => {
	t /= d;
	return -c * t * (t - 2) + b;
};

const easeInOutQuad = (t, b, c, d) => {
	t /= d / 2;
	if (t < 1) return c / 2 * t * t + b;
	t--;
	return -c/2 * (t * (t - 2) - 1) + b;
};

const easeInOutQuart = (t, b, c, d) => {
	if ((t /= d / 2) < 1) {
		return c / 2 * t * t * t * t + b;
	} else {
		return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
	}
};

const easeInSine = (t, b, c, d) => {
	return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
};

const easeOutSine = (t, b, c, d) => {
	return c * Math.sin(t/d * (Math.PI/2)) + b;
};

const easeInOutSine = (t, b, c, d) => {
	return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
};

export { easeInQuad, easeOutQuad, easeInOutQuad, easeInOutQuart, easeInSine, easeOutSine, easeInOutSine };