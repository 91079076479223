import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';
import React, { Component } from 'react';
import  { Gunner } from './BulletEmitter'; 

//renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { PropShader } from "../../Shaders/PropShader";
import { loadFont, loadCurveFromJSON, createText } from "./Helper";
import ParticleEmitter from './ParticleEmitter';
import OrbitalCameraGame from './OrbitalCameraGame';
import gsap from "gsap";
import AudioManager from './AudioManager';
import { AtmosphericSkyShader } from '../../Shaders/AtmosphericSkyShader';

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
let COLLISION_LAYER = 1;
class UpgradeManager {
  constructor(scene) {
    this.scene=scene;
    this.reloadDuration=3000;
    this.ammoIncrease = 0;
    this.healthIncrease = 0;
    this.reloadSpeedIncrease = 0;
  }

  applyUpgrade(upgrade) {
    if (upgrade === 'Faster Reload') {
      this.reloadDuration -= 500; // Reduce reload time, min 1 second
    } else if (upgrade === 'Increased Ammo') {
      this.ammoIncrease += 2; // Increase ammo capacity
    } else if (upgrade === 'Increased Health') {
      this.scene.playerHealth  = Math.min(this.scene.playerHealth+50,100); // Increase health
    }
  }

  getReloadDuration() {
    return this.reloadDuration; // Return current reload duration
  }

  getAmmoBonus() {
    return this.ammoIncrease;
  }

  getHealthBonus() {
    return this.healthIncrease;
  }
}

class Bomb {
  constructor(scene, position, stormTheRoof, removeBombCallback,fireworks,bombModel) {
    this.bombModel = bombModel;
    this.fireworks = fireworks;
    this.scene = scene;
    this.destroyed = false;
    this.stormTheRoof = stormTheRoof; // Pass the StormTheRoof instance
    this.health = 30; // Health of the bomb
    this.velocity = new THREE.Vector3(0, -0.05, 0); // Speed and direction of the bomb's movement
    
    this.model = this.bombModel.all_model[0].clone();

    this.model.position.copy(position);
    this.scene.add(this.model);

    // Callback to remove the bomb
    this.removeBombCallback = removeBombCallback;

    // Create a hitbox for the bomb
    this.hitbox = new THREE.Mesh(
      new THREE.BoxGeometry(4, 4, 4),
      new THREE.MeshBasicMaterial({ visible: false })
    );
    this.hitbox.position.copy(this.model.position);
    this.hitbox.layers.set(COLLISION_LAYER);
    this.scene.add(this.hitbox);
  }

  update() {
    this.model.position.add(this.velocity);
    this.hitbox.position.copy(this.model.position);
    
    // Check if the bomb has reached the ground
    if (this.model.position.y <= 0 && ! this.destroyed) {
      this.stormTheRoof.takeDamage(10);
      this.explode();
    }
    if (this.stormTheRoof.waveManager.spawnCounters.target<0) {
      this.explode();
    }
  }

  explode() {
    AudioManager.playSound('explode'); // Play reload sound
    this.destroyed = true;

    this.scene.remove(this.model);
    this.scene.remove(this.hitbox);
    this.fireworks.push(new Firework(this.scene,this.model.position));

    // Reduce player health using the stormTheRoof instance
    // Remove bomb from the array via callback
    this.removeBombCallback(this);
  }
}

class HelicopterPoint {
  constructor(scene) {
    this.body = null;
    this.blade_1 = null;
    this.blade_2 = null;
    this.position = new THREE.Vector3(0, 0, 0);
    this.health = 100; // Add health to helicopter

    // Create hitbox
    this.hitbox = new THREE.Mesh(
      new THREE.BoxGeometry(5, 5, 10), // Adjust the size as needed
      new THREE.MeshBasicMaterial({ color: 0x00ff00, visible: false }) // Make hitbox invisible
    );
    this.hitbox.position.copy(this.position);
    this.hitbox.layers.set(COLLISION_LAYER); // Set collision layer
    scene.add(this.hitbox);
    this.destroyed = false;
  }
  takeDamage(amount) {
    this.health -= amount;
    if (this.health <= 0) {
      //this.destroyed = true;
     // this.destroy();
    }
  }

  destroy() {
    this.destroyed = true;
    this.scene.remove(this.model); // Remove the model from the scene
    this.scene.remove(this.hitbox); // Remove the hitbox from the scene
  
  }
}
class ParachutePoint {
  constructor(scene) {
    this.model = null;
    this.modelTime = 0.0;
    this.currPoint = new THREE.Vector3(0, 60, 0);
    this.currPointLookAt = new THREE.Vector3(0, 60, 0);
    this.lerpedPoint = new THREE.Vector3(0, 60, 0);
    this.lerpedPointLookAt = new THREE.Vector3(0, 60, 0);
    this.pointParachute = new THREE.Vector3(0, 0, 0);
    this.particleEmitter = null;
    this.health = 100; // Add health to parachuter

    // Create hitbox
    this.hitbox = new THREE.Mesh(
      new THREE.BoxGeometry(3, 3, 3), // Adjust the size as needed
      new THREE.MeshBasicMaterial({ color: 0xff0000, visible: false }) // Make hitbox invisible
    );
    this.hitbox.position.copy(this.currPoint);
    this.hitbox.layers.set(COLLISION_LAYER); // Set collision layer
    scene.add(this.hitbox);
    this.destroyed = false;

   
  }
  takeDamage(amount) {
    this.health -= amount;
    if (this.health <= 0) {

      this.destroyed = true;
      //this.destroy();
    }
  }

  destroy() {
    this.destroyed = true;
    this.scene.remove(this.model); // Remove the model from the scene
    this.scene.remove(this.hitbox); // Remove the hitbox from the scene
  }
}
class JetPoint {
  constructor(scene) {
    this.scene = scene;
    this.model = null;
    this.modelTime = 0.0;
    this.currPoint = new THREE.Vector3(0, 35, 0);
    this.currPointLookAt = new THREE.Vector3(0, 35, 0);
    this.lerpedPoint = new THREE.Vector3(0, 35, 0);
    this.lerpedPointLookAt = new THREE.Vector3(0, 35, 0);
    this.pointJet = new THREE.Vector3(0, 0, 0);
    this.health=100;

    // Create hitbox
    this.hitbox = new THREE.Mesh(
      new THREE.BoxGeometry(3, 3, 3), // Adjust the size as needed
      new THREE.MeshBasicMaterial({ color: 0xff0000, visible: false }) // Make hitbox invisible
    );
    this.hitbox.position.copy(this.currPoint);
    this.hitbox.layers.set(COLLISION_LAYER); // Set collision layer
    scene.add(this.hitbox);
    this.destroyed = false;
  }
  takeDamage(amount) {
    this.health -= amount;
    if (this.health <= 0) {
      //this.destroyed = true;
     // this.destroy();
    }
  }
}

class Firework {
  constructor(scene, pos) {
    this.scene = scene;
    this.done = false;
    this.dest = [];
    this.colors = [];
    this.geometry = null;
    this.points = null;
    this.initialPosition = pos;
    this.material = new THREE.PointsMaterial({
      size: 0.4,
      color: 0xffffff,
      opacity: 1,
      vertexColors: true,
      transparent: true,
      depthTest: false,
    });
    this.launch();
  }

  reset() {
    this.scene.remove(this.points);
    if (this.explosionLight) {
      this.scene.remove(this.explosionLight);
    } this.dest = [];
    this.colors = [];
    this.geometry = null;
    this.points = null;
    this.explosionLight = null; // Reset the point light
  }

  launch() {
    const x = this.initialPosition.x;
    const y = this.initialPosition.y;
    const z = this.initialPosition.z;

    const from = new THREE.Vector3(x, 2, z);
    const to = new THREE.Vector3(x + THREE.MathUtils.randFloat(-3.9, 3.9)*10, y, z + THREE.MathUtils.randFloat(-3.9, 3.9)*10);

    const color = new THREE.Color();
    color.setHex('#ffffff');
    //color.setHSL(THREE.MathUtils.randFloat(0.1, 0.9), 1, 0.9);
    this.colors.push(color);

    this.geometry = new THREE.BufferGeometry();
    this.points = new THREE.Points(this.geometry, this.material);

    this.geometry.setAttribute('position', new THREE.Float32BufferAttribute([from.x, from.y, from.z], 3));
    this.geometry.setAttribute('color', new THREE.Float32BufferAttribute(color.toArray(), 3));

    this.dest.push(to);

    this.scene.add(this.points);

  }

  explode(vector) {
    this.scene.remove(this.points);
    this.dest = [];
    this.colors = [];
    this.geometry = new THREE.BufferGeometry();
    this.points = new THREE.Points(this.geometry, this.material);

    const positions = [];
    const colors = [];

    for (let i = 0; i < 80; i++) {
      const color = new THREE.Color('#ffffff');

      this.colors.push(color);

      const from = vector;
      const to = new THREE.Vector3(
        THREE.MathUtils.randInt(vector.x - 10, vector.x + 10),
        THREE.MathUtils.randInt(vector.y - 10, vector.y + 10),
        THREE.MathUtils.randInt(vector.z - 10, vector.z + 10)
      );

      positions.push(from.x, from.y, from.z);
      colors.push(color.r, color.g, color.b);
      this.dest.push(to);
    }

    this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    this.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    this.scene.add(this.points);
    // Add point light at the explosion point
    this.explosionLight = new THREE.PointLight(0xffffff, 50, 100, 25);
    this.explosionLight.position.copy(vector);
    this.scene.add(this.explosionLight);
  }

  update() {
    if (this.points && this.geometry) {
      const positions = this.geometry.attributes.position.array;
      const total = positions.length / 3;

      for (let i = 0; i < total; i++) {
        positions[i * 3] += (this.dest[i].x - positions[i * 3]) / 50;
        positions[i * 3 + 1] += (this.dest[i].y - positions[i * 3 + 1]) / 50;
        positions[i * 3 + 2] += (this.dest[i].z - positions[i * 3 + 2]) / 50;
      }
      this.geometry.attributes.position.needsUpdate = true;

      if (total === 1) {
        if (Math.ceil(positions[1]) > (this.dest[0].y - 15)) {
          this.explode(new THREE.Vector3(positions[0], positions[1], positions[2]));
          return;
        }
      }

      if (total > 1) {
        this.material.opacity -= 0.015;
        this.material.needsUpdate = true;
      }

      if (this.material.opacity <= 0) {
        this.reset();
        this.done = true;
        return;
      }
    }
    // Update explosion light intensity
    if (this.explosionLight) {
      this.explosionLight.intensity -= 5.5;
      if (this.explosionLight.intensity <= 0) {
        this.scene.remove(this.explosionLight);
        this.explosionLight = null;
      }
    }
  }
}

class WaveManager {
  constructor(scene) {
    this.scene = scene;
    this.currentWave = 0;
    this.waves = [
      { parachuters: 4, helicopters: 0, jets: 0 },
      { parachuters: 8, helicopters: 1, jets: 0 },
      { parachuters: 12, helicopters: 2, jets: 1 },
      { parachuters: 15, helicopters: 3, jets: 2 },

      { parachuters: 17, helicopters: 4, jets: 3 },

      { parachuters: 20, helicopters: 5, jets: 4 },
      { parachuters: 23, helicopters: 5, jets: 4},
      { parachuters: 26, helicopters: 6, jets: 5 },
      { parachuters: 28, helicopters: 6, jets:6 },
      { parachuters: 35, helicopters: 7, jets: 7 },
      { parachuters: 50, helicopters: 8, jets: 8 },
      { parachuters: 70, helicopters: 12, jets: 12 },
      { parachuters: 75, helicopters: 12, jets: 12 },
      { parachuters: 80, helicopters: 12, jets: 12 },
      { parachuters: 85, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },


    ];
    this.activeEnemies = [];
    this.spawnIntervals = {
      parachuters: 550, // 3 seconds
      helicopters: 6000, // 5 seconds
      jets: 8000,        // 7 seconds
    };
    this.spawnCounters = {
      parachuters: 0,
      helicopters: 0,
      jets: 0,
      target:0
    };
    this.lastSpawnTimes = {
      parachuters: 0,
      helicopters: 0,
      jets: 0,
    };

    this.waveStarted = false;
  }

  startWave() {

    // Apply delay for each enemy type
    const parachuteDelay = Math.random() * 500;  // Random delay between 0 and 3 seconds
    const helicopterDelay = Math.random() * 4000;
    const jetDelay = Math.random() *6000;

    setTimeout(() => {
      this.lastSpawnTimes.parachuters = performance.now();
    }, parachuteDelay);

    setTimeout(() => {
      this.lastSpawnTimes.helicopters = performance.now();
    }, helicopterDelay);

    setTimeout(() => {
      this.lastSpawnTimes.jets = performance.now();
    }, jetDelay);
  
    setTimeout(() => {
      this.noShoot = false;
      const wave = this.waves[this.currentWave];
      this.waveStarted = true;
      this.spawnCounters.parachuters = wave.parachuters;
      this.spawnCounters.helicopters = wave.helicopters;
      this.spawnCounters.jets = wave.jets;
      this.spawnCounters.target = wave.parachuters + wave.helicopters+wave.jets;
    }, 1000); // 1-second delay
    this.noShoot= false;
    this.scene.ammoCount = 8 + this.scene.upgradeManager.getAmmoBonus();
    this.scene.createBulletCounter(); 
  }

  update() {
  
    if (this.waveStarted) {
  
      // Remove destroyed enemies from the activeEnemies array
      this.activeEnemies = this.activeEnemies.filter(enemy => {
        if (!enemy.destroyed) {
          // Optionally, update enemy position or perform other operations here
          return true; // Keep enemy in the array
        }
        return false; // Remove enemy from the array
      });  
   
      // Check if the wave is complete
      if (this.isWaveComplete()) {
  this.scene.bullets.forEach(bullet => {
    if (bullet.parent) bullet.parent.remove(bullet);
});

       this.waveStarted = false;
        this.nextWave();
      }
    }
  }

  handleSpawning(now, type, spawnFunction) {
    if (this.spawnCounters[type] > 0 && now - this.lastSpawnTimes[type] > this.spawnIntervals[type]) {
      spawnFunction();
      this.spawnCounters[type]--;
      this.lastSpawnTimes[type] = now;
    }
  }

  isWaveComplete() {
    return this.spawnCounters.parachuters === 0 && 
           this.spawnCounters.helicopters === 0 && 
           this.spawnCounters.jets === 0 && this.spawnCounters.target<1;
  }
  nextWave() {
    this.currentWave++;
   
      setTimeout(() => {
        this.showUpgradeMenu();
      }, 1000); // 1-second delay before showing the upgrade menu
   
  }
  
  showUpgradeMenu() {
    // Remove any existing bullets from the scene

    setTimeout(() => {
      this.scene.onWaveComplete();
    }, 1000); // 1-second delay before showing the upgrade menu
   }
}

class StormTheRoof extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.onWaveComplete = null; // Initialize callback as null
    this.gunners = [
      new Gunner(this.scene, new THREE.Vector3(5, -5, 15)),
      new Gunner(this.scene, new THREE.Vector3(-5, 0, 15)),

    new Gunner(this.scene, new THREE.Vector3(-15, 0, -5)),
      //new Gunner(this.scene, new THREE.Vector3(-20, 0, -10))
    ];

    const skyMaterial = new THREE.ShaderMaterial({
      vertexShader: AtmosphericSkyShader.vertexShader,
      fragmentShader: AtmosphericSkyShader.fragmentShader,
      uniforms: THREE.UniformsUtils.clone(AtmosphericSkyShader.uniforms),
      side: THREE.BackSide // Ensure the sky is rendered on the inside of the sphere
  });

  this.bloodEmitter = new ParticleEmitter(this.scene);
  //this.bloodEmitter.setColor(0xFF0000); // Set color to red for blood
  
  const skyGeometry = new THREE.SphereGeometry(100, 32, 32); // Large enough to enclose the entire scene
  const skyMesh = new THREE.Mesh(skyGeometry, skyMaterial);
  this.scene.add(skyMesh);
  
  this.skyMaterial = skyMaterial; // Store it for updating
    this.muzzleFlashes = [];
    this.bullets = []; // Initialize the bullets array


    this.recoilStrength = new THREE.Vector3(0.1, 2.0, 0.1); // Strength of recoil on x, y, z axes
    this.recoilRecoverySpeed = 0.5; // Speed at which recoil recovers
    this.recoilOffset = new THREE.Vector3(); // Current offset due to recoil
    this.recoilVelocity = new THREE.Vector3(); // Velocity for recoil blending


    this.upgradeManager = new UpgradeManager(this);  // Initialize UpgradeManager
    this.ammoCount = 8 ;  // Apply initial upgrade bonus
    this.playerHealth = 100;  // Apply initial health bonus
    this.reloadTime = 1.0;  // Apply initial reload speed bonus
    this.isReloading = false; // Flag to track if reloading is in progress

    this.setupClickHandlers(); 

    this.jetSpawnInterval = 8000; // 7 seconds
    this.lastJetSpawnTime = performance.now();

    this.bombs = []; // Array to keep track of bombs
    this.helicopterSpawnInterval = 5000; // 5 seconds
    this.lastHelicopterSpawnTime = performance.now();

    this.parachuteSpawnInterval = 1000; // 3 seconds
    this.lastParachuteSpawnTime = performance.now();
     
    this.ammoCount = 8; // Player starts with 8 bullets
    this.playerHealth = 100; // Initialize player health
    this.buttonVisibilityCallback = null;


    // Define colors for day and night
    this.dayAmbientColor = new THREE.Color(0xffffff);
    this.nightAmbientColor = new THREE.Color(0x545863);
    this.noShoot= false;

    this.fireworks = [];
    this.lastFireworkTime = 0;
    this.fireworkInterval = 600; // Launch a new firework every 2 seconds
    this.fireworkselection = 0;

    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.modelLoad = _options.modelcallback;

    // call back for mouse collision detection
    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;
    this.collision_none = _options.collision_none_callback;

    this.parachutePoints = [];
    this.helicopterPoints = [];
    this.jetPoints = [];
    this.platformPoints = [];

    this.hoverAnimationsEnabled = false;

    this.hide = false;
    this.current = 0;

    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = performance.now()
    this.totalTime = 0.0;
    this.startTime = performance.now()

    // helicopter
    this.helicopterMove = false;

    //camera vectors
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);
    this.canMoveCamera = false;
    this.score=0;
 // Initial progress variable for color transition
 this.colorTransitionProgress = 0;
    let d = 50;
    let r = 1;
    let mapSize = 2048;
    this.ambient = new THREE.AmbientLight(0xffffff, 0.5, 0);

    this.dirLight = new THREE.DirectionalLight(0xffffff, 3.95);
    this.dirLight.castShadow = false;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(40, 20, 20);
   
    this.waveManager = new WaveManager(this);

    //const helper = new THREE.CameraHelper(this.dirLight.shadow.camera);
    this.scene.add(this.ambient);


    this.scene.add(this.dirLight);



    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;

    this.platformToggle = false;
    this.platformsInitialized = false;

    //add event listeners
    this.loadListener();
    this.startRender();
    this.generateCamCurve();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.dronePointLerped = new Vector3(0, 30, 0);

    this.lookDown = new Vector3(0, -30, 0);
    this.currPointLerped.set
      (-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set
      (-0.605, 0.054, 0.281);

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();

    this.requiredAssets = [


      // background asset
      { name: 'paradeModel', source: '/assets/parade.glb' },
      { name: 'paradeTexture', source: '/assets/parade_render.jpg', type: 'texture' },

      // parachute asset
      { name: 'parachuteModel', source: '/assets/parachute.glb' },
      { name: 'parachuteTexture', source: '/assets/parade_render.jpg', type: 'texture' },

      // helicopter asset
      { name: 'helicopterModel', source: '/assets/helicopter.glb' },
      { name: 'helicopterBladeModel', source: '/assets/helicopter_blade.glb' },

      //jet asset
      { name: 'jetModel', source: '/assets/jet.glb' },

      //platform asset
      { name: 'platformModel', source: '/assets/platform.glb' },

      //logo asset
      { name: 'logoModel', source: '/assets/logo.glb' },

      // parade background
      { name: 'paradeBackgroundModel', source: '/assets/parade_background.glb' },

      // storm the roof model
      { name: 'stormTheRoofModel', source: '/assets/storm_the_roof_1.glb' },
      { name: 'stormTheRoofTexture', source: '/assets/storm_the_roof.jpg', type: 'texture' },
      { name: 'muzzleTexture', source: '/assets/muzzle_flash.png', type: 'texture' },


      // bullet model
      { name: 'bulletFullModel', source: '/assets/bullet_full.glb' },
      { name: 'bulletEmptyModel', source: '/assets/bullet_empty.glb' },

      // bomb model
      { name: 'bombModel', source: '/assets/bomb.glb' },


    ];
    this.isDay = true;
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    // Track which text is hovered over
    this.hoveredText = null;

  }

  setOnReloadStart(callback) {
    this.onReloadStart = callback;
  }
 
  setOnReloadComplete(callback) {
    this.onReloadComplete = callback;
  }
  startNextWave() {
    console.log('apply upgrade');
    // Reset values with bonuses applied
    this.ammoCount = 8 + this.upgradeManager.getAmmoBonus();
    this.playerHealth = Math.min(100, this.playerHealth + 50);

    // Restart wave logic here...
  }
  getScore() {
    return this.score;
  }
  getWaveCount() {
    return this.waveManager.currentWave;
  }
  handleUpgradeSelection(upgrade) {
    this.upgradeManager.applyUpgrade(upgrade);
    this.startNextWave();  // Start the next wave after upgrade selection
  }
  // Method to spawn jets
  spawnJet() {
    let temp = this.jetModelMain.all_model[0].clone();
    const jetPoint = new JetPoint(this.scene);
    jetPoint.model = temp;
    jetPoint.path = Math.floor(Math.random() * 3);
    this.jetPoints.push(jetPoint);
    this.waveManager.activeEnemies.push(jetPoint);

    this.scene.add(temp);
  }

 // Method to drop bombs from helicopters
 dropBomb(helicopterPoint) {
  AudioManager.playSound('beep'); // Play reload sound

  const bomb = new Bomb(this.scene, helicopterPoint.body.position.clone(),this, this.removeBomb.bind(this),this.fireworks,this.bombModel);
  this.waveManager.activeEnemies.push(bomb);

  this.bombs.push(bomb);
}

// Callback to remove bomb from the array
removeBomb(bomb) {
  const index = this.bombs.indexOf(bomb);
  if (index !== -1) {
    this.bombs.splice(index, 1);
  }
}

  setupClickHandlers() {
    window.addEventListener('click', (event) => {
      if (this.ammoCount > 0 && !this.isReloading) {
        this.ammoCount--; // Reduce ammo on each shot
        console.log(this.ammoCount);
        this.handleShooting(event); // Handle the shooting logic
        if(this.ammoCount<1)
          this.startReload();
        return;
      } 
    });
  }
 // Method to handle reloading
 startReload() {
  if (this.onReloadStart) this.onReloadStart();
  this.reloadTime = this.upgradeManager.getReloadDuration()/1000 ;
  setTimeout(() => {
    if (this.onReloadComplete){
       this.onReloadComplete();
      this. setOnReloadComplete();
    }
  }, this.upgradeManager.getReloadDuration());
    // Remove any existing bullets from the scene
    this.bullets.forEach(bullet => {
      if (bullet.parent) bullet.parent.remove(bullet);
  });

  this.bullets = [];
  this.isReloading = true;
  AudioManager.playSound('reload_out'); // Play reload sound

  setTimeout(() => {
    AudioManager.playRandomPrepareSound(); // Play reload sound

  }, this.upgradeManager.reloadDuration/2); // Wait for reload duration

  
  setTimeout(() => {
    this.ammoCount = 8 + this.upgradeManager.getAmmoBonus(); // Refill ammo with upgrade
        this.isReloading = false;
        console.log('Reload complete');
        AudioManager.playSound('reload_in');
        this.createBulletCounter(); // Refresh the bullet counter

  }, this.upgradeManager.reloadDuration); // Wait for reload duration
}

weaponReload() {
     
  this.isReloading = false;
  console.log('Reload complete');
  AudioManager.playSound('reload_in');
  this.createBulletCounter(); // Refresh the bullet counter
}

animateBullet(bullet) {
  if (!bullet || !bullet.position) {
    console.warn('Attempted to animate a bullet that no longer exists.');
    return;
  }

  // Replace bullet with an empty bullet model during flight
  const emptyBullet = this.bulletEmptyModel.all_model[0].clone();
  emptyBullet.position.copy(bullet.position);
  emptyBullet.rotation.copy(bullet.rotation);
  emptyBullet.scale.set(0.5, 0.5, 0.5);

  this.scene.add(emptyBullet);
  this.scene.remove(bullet);

  // Remove the bullet from the bullets array and decrease the ammo count
  const bulletIndex = this.bullets.indexOf(bullet);
  if (bulletIndex > -1) {
    this.bullets.splice(bulletIndex, 1);
    this.ammoCount = this.bullets.length;
  }

  // Generate random values for upward movement and fall position
  const upwardDistance = THREE.MathUtils.randFloat(1.0, 2.0); // Random upward distance
  const finalZOffset = THREE.MathUtils.randFloat(-1.0, -1.5); // Random Z offset for landing
  const finalXOffset = THREE.MathUtils.randFloat(-2.0, 2.0); // Random X offset for landing

  // Animate the bullet to move up before going down and rotate
  gsap.to(emptyBullet.position, {
    y: `+=${upwardDistance}`, // Random upward movement
    duration: 0.2,
    ease: "power.out",
    onComplete: () => {
      gsap.to(emptyBullet.position, {
        y: '-=6', // Move downwards after reaching the peak
        z: `+=${finalZOffset}`, // Random final Z position
        x: `+=${finalXOffset}`, // Random final X position
        duration: 0.9,
        ease: "power.out",
        onComplete: () => {
          if (emptyBullet && emptyBullet.parent) {
            emptyBullet.parent.remove(emptyBullet); // Safely remove the bullet after animation
          }
        }
      });
    }
  });

  // Rotate the bullet during flight
  const randomRotationSpeed = THREE.MathUtils.randFloat(1.0, 2.0); // Random rotation speed
  gsap.to(emptyBullet.rotation, {
    y: Math.PI * 2 * randomRotationSpeed, // Full rotation along Y-axis
    x: Math.PI * 2 * randomRotationSpeed, // Full rotation along X-axis
    duration: 1.3,
    ease: "none" // Constant rotation speed
  });
}

createBulletCounter() {
  // Remove any existing bullets from the scene
  this.bullets.forEach(bullet => {
    if (bullet.parent) bullet.parent.remove(bullet);
  });

  this.bullets = [];

  // Create bullets based on current ammo count
  for (let i = 0; i < this.ammoCount; i++) {
    const bullet = this.bulletFullModel.all_model[0].clone();
    bullet.position.x = -99;
    bullet.rotation.x = Math.PI / 2; // Rotate to make it horizontal
    this.scene.add(bullet);
    this.bullets.push(bullet);
  }

  this.updateBulletPositions();
}
updateBulletPositions() {
  if (!this.newCamera || !this.newCamera.threeCamera) {
      console.warn('Camera not initialized yet.');
      return;
  }

  const aspectRatio = window.innerWidth / window.innerHeight;
  const spacing = 0.1125;  // Vertical spacing between bullets
  const slantSpacing = 0.025;  // Slight horizontal slant for a magazine effect
  const startY = -((this.bullets.length - 1) * spacing);  // Position at the bottom

  // Calculate the horizontal position based on the camera's frustum size
  const frustumSize = 1; // Adjust this value if needed to match your scene scale
  let baseXPosition = (frustumSize * aspectRatio) - 0.2 ;  // Keep it near the right edge, subtract to offset inside
if(window.innerWidth<window.innerHeight)
  baseXPosition+=0.4;


  this.bullets.forEach((bullet, index) => {
      const targetPosition = new THREE.Vector3(
          baseXPosition - index * slantSpacing,  // Adjust based on calculated X position
          startY + index * spacing,              // Vertically stack bullets with the correct spacing
          -4                                     // Move bullets further away from the camera
      );

      targetPosition.applyMatrix4(this.newCamera.threeCamera.matrixWorld);

      // Use GSAP to animate the bullet's position smoothly
      gsap.to(bullet.position, {
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          duration: 0.25,
          ease: "power.inOut"
      });

      // Rotate the bullet to point left (90 degrees around Z-axis)
      bullet.rotation.set(0, -Math.PI / 2, -Math.PI / 2);
      bullet.scale.set(0.5, 0.5, 0.5);
  });
}





  handleShooting(event) {
  
    if(this.playerHealth<=0 || this.waveManager.spawnCounters.target < 1)
      return;
    this.recoilStrength = new THREE.Vector3(Math.random() -0.5, 2.0,Math.random() -0.5); // Strength of recoil on x, y, z axes

    const shotBullet = this.bullets.pop(); // Get the last bullet
    this.animateBullet(shotBullet); // Animate the bullet falling

    this.updateBulletPositions(); // Update positions of remaining bullets
 // Apply recoil force
 const verticalRecoil = new THREE.Vector3(0, this.recoilStrength.y, 0);
 const horizontalRecoil = new THREE.Vector3(this.recoilStrength.x * (Math.random() - 0.5), 0, 0); // Random horizontal kick
 const depthRecoil = new THREE.Vector3(0, 0, -this.recoilStrength.z);

 this.recoilVelocity.add(verticalRecoil).add(horizontalRecoil).add(depthRecoil);
   // play shooting sound
    AudioManager.playSound('shoot');

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
    raycaster.layers.set(COLLISION_LAYER); // Detect only the hitboxes


        // Get the position to place the muzzle flash
        const flashPosition = raycaster.ray.origin.clone().add(raycaster.ray.direction.clone().multiplyScalar(10));

        // Create a black plane for the muzzle flash
        const geometry = new THREE.PlaneGeometry(5, 5);
        const material = new THREE.MeshBasicMaterial({
          map: this.loader.getAsset('muzzleTexture'), // Simple texture map for the muzzle flash
          transparent: true, // Allow transparency
          opacity: 0.15, // Start fully opaque
          blending: THREE.AdditiveBlending, // Enable additive blending for a glowing effect
          depthWrite: false  // Prevent writing to the depth buffer for proper blending
      });
        const muzzleFlash = new THREE.Mesh(geometry, material);
        // Set the initial scale very small
        muzzleFlash.scale.set(0.1, 0.1, 0.1);
        // Set the muzzle flash position and make it look at the camera
        muzzleFlash.position.copy(flashPosition);
        muzzleFlash.lookAt(this.newCamera.threeCamera.position);

        // Add to the scene
        this.scene.add(muzzleFlash);

        // Animate the fade-out
        gsap.timeline()
        .to(muzzleFlash.scale, {
            x: 1, 
            y: 1, 
            z: 1,
            duration: 0.1, // Fast scaling up
            ease: "power2.out"
        })
        .to(muzzleFlash.material, {
            opacity: 0, // Fade out
            duration: 0.4,
            ease: "power2.out",
            onComplete: () => {
                this.scene.remove(muzzleFlash); // Remove the mesh after fading out
            }
        }, "<"); // Start fading as soon as the scaling starts



        const muzzleFlash2 = new THREE.Mesh(geometry, material);
        // Set the initial scale very small
        muzzleFlash2.scale.set(5.1, 5.1, 5.1);
        // Set the muzzle flash position and make it look at the camera
        muzzleFlash2.position.copy(flashPosition);
        muzzleFlash2.lookAt(this.newCamera.threeCamera.position);

        // Add to the scene
        this.scene.add(muzzleFlash2);

        // Animate the fade-out
        gsap.timeline()
        .to(muzzleFlash2.scale, {
            x: 8, 
            y: 8, 
            z: 8,
            duration: 0.1, // Fast scaling up
            ease: "power2.out"
        })
        .to(muzzleFlash2.material, {
            opacity: 0, // Fade out
            duration: 0.4,
            ease: "power2.out",
            onComplete: () => {
                this.scene.remove(muzzleFlash2); // Remove the mesh after fading out
            }
        }, "<"); // Start fading as soon as the scaling starts


    const intersects = raycaster.intersectObjects(this.scene.children, true);
    if (intersects.length > 0) {
      const intersectedObject = intersects[0].object;
  
        // Handle bomb clicks
        this.bombs.forEach(bomb => {
          if (bomb.hitbox === intersectedObject) {
            bomb.health -= 101;
            if (bomb.health <= 0) {
              this.triggerBloodEffect(bomb.hitbox.position);  // Trigger blood effect
              bomb.destroyed = true;
              bomb.explode();
            }
          }
        });
      // Handle parachuter clicks
      this.parachutePoints.forEach((parachutePoint) => {
        if (parachutePoint.hitbox === intersectedObject) {
          parachutePoint.takeDamage(101);
          if (parachutePoint.health <= 0 || !parachutePoint.destroyed) {
            AudioManager.playSound('shoot_pistol');
            this.incrementScore(10); 

            this.waveManager.spawnCounters.target--;
            parachutePoint.destroyed= true;

            this.triggerBloodEffect(parachutePoint.model.position);  // Trigger blood effect
            this.scene.remove(parachutePoint.model);
            this.scene.remove(parachutePoint.hitbox);
            this.parachutePoints.splice(this.parachutePoints.indexOf(parachutePoint), 1);
          }
        }
      });

      // Handle helicopter clicks
      this.helicopterPoints.forEach((helicopterPoint) => {
        if (helicopterPoint.hitbox === intersectedObject) {
          helicopterPoint.takeDamage(51);
          if (helicopterPoint.health <= 0 && !helicopterPoint.destroyed) {
            AudioManager.playSound('shoot_pistol');
            this.incrementScore(20); 

            this.triggerBloodEffect(helicopterPoint.body.position);  // Trigger blood effect
            helicopterPoint.destroyed= true;
            this.waveManager.spawnCounters.target--;
            this.scene.remove(helicopterPoint.body);
            this.scene.remove(helicopterPoint.blade_1);
            this.scene.remove(helicopterPoint.blade_2);
            this.scene.remove(helicopterPoint.hitbox);
            this.helicopterPoints.splice(this.helicopterPoints.indexOf(helicopterPoint), 1);
          }
        }
      });
        // Handle jet clicks
        this.jetPoints.forEach((jetPoint) => {
          if (jetPoint.hitbox === intersectedObject) {
            jetPoint.takeDamage(34);
            this.triggerBloodEffect(jetPoint.model.position,3);  // Trigger blood effect

            if (jetPoint.health <= 0) {
              this.incrementScore(50); 

              this.triggerBloodEffect(jetPoint.model.position);  // Trigger blood effect
              jetPoint.destroyed= true;
              this.waveManager.spawnCounters.target--;
              this.scene.remove(jetPoint.model);
            
              this.jetPoints.splice(this.jetPoints.indexOf(jetPoint), 1);
            }
          }
        });
    }
  }

  reloadAmmo() {
    console.log("Ammo reloaded!");
  }
  triggerBloodEffect(position,count=20) {
    if (this.bloodEmitter) {
        this.bloodEmitter.spawn(position,count);  // Spawn blood particles at the given position
    } else {
        console.warn("Blood emitter is not initialized.");
    }
}
handleDamageEffect() {
  console.log("Taking damage");
  const redColor = new THREE.Color(0xff0000);
  const originalColor =  new THREE.Color(0x595959);

  // Trigger the color change using GSAP
  gsap.to(this.skyMaterial.uniforms.bottomColor.value, {
    r: redColor.r,
    g: redColor.g,
    b: redColor.b,
    duration: 0.1,
    onComplete: () => {
      gsap.to(this.skyMaterial.uniforms.bottomColor.value, {
        r: originalColor.r,
        g: originalColor.g,
        b: originalColor.b,
        duration: 0.5,
        ease: "power2.out"
      });
    }
  });
}

takeDamage(amount) {
  this.playerHealth -= amount;
  if (this.playerHealth <= 0) {
    this.playerHealth = 0;
    console.log("Player is dead.");
    // Handle player death logic here
  }
  console.log(`Player health: ${this.playerHealth}`);
  
  // Trigger the damage effect
  this.handleDamageEffect();
}


  // Function to interpolate colors
  interpolateColors(color1, color2, factor) {
    const result = new THREE.Color();
    result.r = THREE.MathUtils.lerp(color1.r, color2.r, factor);
  result.g = THREE.MathUtils.lerp(color1.g, color2.g, factor);
    result.b = THREE.MathUtils.lerp(color1.b, color2.b, factor);
    return result;
  }

  toggleDayNight = () => {
    this.isDay = !this.isDay;

    const targetAmbientColor = this.isDay ? this.dayAmbientColor : this.nightAmbientColor;
    //const targetBackgroundColor = this.isDay ? this.dayBackgroundColor : this.nightBackgroundColor;
    const targetIntensity = 0.65;

    gsap.to(this.ambient.color, {
      r: targetAmbientColor.r,
      g: targetAmbientColor.g,
      b: targetAmbientColor.b,
      duration: 1
    });

    gsap.to(this.ambient, {
      intensity: targetIntensity,
      duration: 1
    });

  }

  
  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'paradeAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  async onAllAssetsLoaded() {

    this.initialCameraPosition = this.newCamera.threeCamera.position.clone();

    console.log("All assets loaded");
    this.createSceneObjects();
   
    // load all required assets
    await this.setupParachute();
    this.setupHelicopter();
    this.setupJet();

    // setup stage interaction
    this.setupStageInteractions();

    // Start the introduction sequence
    this.startIntroSequence();

    let clickEnabled = false;

    function onMouseClick(event) {
      if (clickEnabled) {
        // Your click handling code here
        console.log("Mouse clicked");

        // Disable further clicks
        clickEnabled = false;
 
        // Remove the event listener to prevent further clicks
        //window.removeEventListener('click', onMouseClick);
       // window.removeEventListener('touchstart', onMouseClick);
      }
    }

    function onMouseUp(event) {
      // Re-enable clicks
      clickEnabled = true;
      console.log("Mouse up");

      // Re-attach the event listener
      //window.addEventListener('click', onMouseClick, false);
      //window.addEventListener('touchstart', onMouseClick, false);
    }

    // Initial event listeners
    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);

    // Event listeners to re-enable clicks
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }
  // Introduction sequence with scaling in the logo from 0 to full size
  startIntroSequence() {
    //this.newCamera.threeCamera.position.set(20, 5, 20); // Set fixed camera position
    console.log(this.introLogo.all_model[0]);
    this.newCamera.threeCamera.lookAt(this.introLogo.all_model[0].position); // Look at the logo mesh
    const logoSize=this.detectDevice() == 'computer' ? 3 : 1
    gsap.to(this.introLogo.all_model[0].scale, {
      duration: 1,
      ease: "elastic.inOut",
      x: logoSize,
      y: logoSize,
      z: logoSize,
      onComplete: () => {
        gsap.to(this.introLogo.all_model[0].scale, {
          duration: 1,
          ease: "elastic.inOut",
          x: 0,
          y: 0,
          z: 0,
          delay: 0.5,
          onComplete: () => {
            this.scene.remove(this.introLogo.all_model[0]);
            this.startParadeModelAnimation();
          }
        });
      

      }
    });
  }

  // Start hover animation after intro sequence
  startParadeModelAnimation() {
    this.ambient.intensity = 0.65;
    this.dirLight.intensity = 3;
    this.orbitalCamera.target = new Vector3(0, -2, 0);
    gsap.to(this.p1model.all_model[0].position, {
      duration: 1.1,
      ease: "power4.inOut",
      x: 0,
      y:0,
      z: 0,
      onComplete: () => {
        this.createBulletCounter(); // Initialize the bullet counter


        this.waveManager.startWave();
    // Start firing for all gunners
    this.gunners.forEach(gunner => gunner.startFiring());
        if (this.buttonVisibilityCallback) {
          this.buttonVisibilityCallback();
        }
        this.canMoveCamera = true;
      }
    });
  }
  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }


  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("stormTheRoofTexture");


    // bullet model

    const bulletMaterial = new THREE.MeshPhongMaterial({
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });
    this.bulletFullModel = new Model({
      modelLink: 'bulletFullModel',
      material: bulletMaterial,
      position: new THREE.Vector3(0, 99, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'bulletFullModel'
    });
  
    this.bulletEmptyModel = new Model({
      modelLink: 'bulletEmptyModel',
      material: bulletMaterial,
      position: new THREE.Vector3(0, 99, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'bulletEmptyModel'
    });
    console.log(paletteTexture);
    this.p1Material = new Material({
      uniforms: {
        downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
      },
      vertexShader: PropShader.vertexShader,
      fragmentShader: PropShader.fragmentShader
    });

    const standardMaterial = new THREE.MeshBasicMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });

    standardMaterial.shininess = 5;
    const modelName = 'stormTheRoofModel';
    this.p1model = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'stormTheRoofModel'
    });
    this.p1model.all_model[0].position.set(0, -100, 0);

 // add parachute models
 const backgroundMaterial = new THREE.MeshPhongMaterial({
  receiveShadow: true,
  dithering: true,
  castShadow: true,
  color: '#ffffff'
});
    this.backgroundModel = new Model({
      modelLink: 'paradeBackgroundModel',
      material: backgroundMaterial,
      position: new THREE.Vector3(0,1, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(2, 2,2),
      scene: this.scene,
      name: 'bgmainScene'
  });
    this.backgroundModel.all_model[0].position.set(30, 0, 0);
    this.backgroundModel.all_model[0].scale.set(25, 25,25);





    const logoMaterial = new THREE.MeshStandardMaterial({
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });


    this.introLogo = new Model({
      modelLink: 'logoModel',
      material: logoMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'introLogo'
    });

    this.introLogo.all_model[0].scale.set(0, 0, 0);
    const bombMaterial = new THREE.MeshStandardMaterial({
      color:'#ff0044',
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });

    this.bombModel = new Model({
      modelLink: 'bombModel',
      material: bombMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'stormTheRoofModel'
    });
    this.bombModel.all_model[0].position.set(0, -100, 0);
    this.bombModel.all_model[0].scale.set(3, 3, 3);

    this.fullLoaded = true;

  }

  // Update create_collision_box to use the collision layer
  create_collision_box = (position, size, name) => {
    this.cube = new Mesh(
      new BoxGeometry(size.x, size.y, size.z),
      new MeshBasicMaterial({ color: 0x22ffffff, opacity: 0.1 }),
    );
    this.cube.position.set(position.x, position.y, position.z);

    this.cube.visible = false;

    this.cube.name = name;
    this.cube.layers.set(COLLISION_LAYER); // Set collision layer
    this.scene.add(this.cube);
  }

  generateCamCurve() {
    //position points
    var posPoints = [];
    var dronePoints = [];
    dronePoints.push(new THREE.Vector3(-50, 24, -40));
    dronePoints.push(new THREE.Vector3(-8, 4, -19));


    posPoints.push(new THREE.Vector3(50, 24, -40));
    posPoints.push(new THREE.Vector3(50, 24, 40));


    this.spline = new THREE.CatmullRomCurve3(posPoints);

    this.points = this.spline.getPoints(200);

    //rotation points
    var lookAtPoints = [];
    lookAtPoints.push(new THREE.Vector3(5, 3, -1));
    lookAtPoints.push(new THREE.Vector3(5, 3, 1));


    this.lookAtSpline = new THREE.CatmullRomCurve3(lookAtPoints);
    this.lookAtPoints = this.lookAtSpline.getPoints(200);

    this.droneSpline = new THREE.CatmullRomCurve3(dronePoints);
    this.dronePoints = this.droneSpline.getPoints(200);
  }
  async setupStageInteractions() {
    const textParachute = await createText('parachute',
      new THREE.Vector3(10.5, 3.75, 17),
      new THREE.Vector3(0, Math.PI / 4 * 4, 0));
    textParachute.name = 'text_parachute';
    textParachute.scale.set(0, 0, 0); // Hide initially
    this.scene.add(textParachute);
  }
  async setupJet() {

    this.jet_1 = this.parachute_1;
    this.jet_2 =this.parachute_2;
    this.jet_3 = this.parachute_3; 

    this.jet_1Points = this.jet_1.getPoints(200);
    this.jet_2Points = this.jet_2.getPoints(200);
    this.jet_3Points = this.jet_3.getPoints(200);

    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });
    standardMaterial.shininess = 5;

    this.jetModelMain = new Model({
      modelLink: 'jetModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'jetModel'
    });
console.log(this.jetModelMain);
  }

  async setupHelicopter() {
    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });

    // standardMaterial.map.encoding = THREE.sRGBEncoding;
    standardMaterial.shininess = 5;

    this.helicopterModelMain = new Model({
      modelLink: 'helicopterModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'helicopterModel'
    });

    this.helicopterBladeModelMain = new Model({
      modelLink: 'helicopterBladeModel',
      material: standardMaterial,
      position: new THREE.Vector3(999, 99999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'helicopterBladeModel'
    });
  }
  triggerHelicopter() {

      for (var i = 0; i < 1; ++i) {

        let heli_1 = this.helicopterModelMain.all_model[0].clone();
        let heli_blade_1 = this.helicopterBladeModelMain.all_model[0].clone();
        let heli_blade_2 = this.helicopterBladeModelMain.all_model[0].clone();
        this.scene.add(heli_1);
        this.scene.add(heli_blade_1);
        this.scene.add(heli_blade_2);

        var xSpawn = 40+ Math.floor(Math.random() * 50);
        heli_1.position.set(i, 20, xSpawn + i);
        heli_blade_1.position.set(i, 20, xSpawn + i);
        heli_blade_2.position.set(i, 20, xSpawn + 1 + i);

        const heliPoint = new HelicopterPoint(this.scene);
        heliPoint.body = heli_1;
        heliPoint.blade_1 = heli_blade_1;
        heliPoint.blade_2 = heli_blade_2;
        this.waveManager.activeEnemies.push(heliPoint);
        this.helicopterPoints.push(heliPoint);
      }
    

  }
  updateHelicopter() {
        for (let i = 0; i < this.helicopterPoints.length; ++i) {
            let helicopter = this.helicopterPoints[i];
            helicopter.blade_1.position.y = 20;
            helicopter.blade_2.position.y = 20;
            helicopter.body.position.y = 20;
            helicopter.blade_1.rotation.y += 0.5;
            helicopter.blade_2.rotation.y -= 0.8;

            helicopter.blade_1.position.z -= 0.1;
            helicopter.blade_2.position.z -= 0.1;
            helicopter.body.position.z -= 0.1;

            // Update hitbox position
            helicopter.hitbox.position.copy(helicopter.body.position);

            if (helicopter.body.position.z < -45) {
              helicopter.destroyed = true;
              this.scene.remove(helicopter.body);
              this.scene.remove(helicopter.blade_1);
              this.scene.remove(helicopter.blade_2);
              this.scene.remove(helicopter.hitbox);
              this.waveManager.spawnCounters.target--;
              this.helicopterPoints.splice(this.helicopterPoints.indexOf(helicopter), 1);
                // This condition can be used to remove the helicopter when it exits the scene
            }
        }
    }
  async setupParachute() {
    console.log('para setup');

    this.parachute_1 = await loadCurveFromJSON('/parade_parachute_1.json');
    this.parachute_2 = await loadCurveFromJSON('/parade_parachute_2.json');
    this.parachute_3 = await loadCurveFromJSON('/parade_parachute_3.json');
    console.log('para ok');

    this.parachute_1Points = this.parachute_1.getPoints(200);
    this.parachute_2Points = this.parachute_2.getPoints(200);
    this.parachute_3Points = this.parachute_3.getPoints(200);

    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });

    standardMaterial.shininess = 5;
    const modelName = 'parachuteModel';
    this.paraModelMain = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, -100, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(4, 4, 4),
      scene: this.scene,
      name: 'parachuteModel'
    });
    this.paraModelMain.all_model[0].scale.set(2, 2,2);
console.log('para ok');
    // add one parachute
    //this.addParachute();
    this.fullLoaded = true;
  }

 

  addJet() {
    let temp = this.jetModelMain.all_model[0].clone();
    const jetPoint = new JetPoint(this.scene);
    jetPoint.model = temp;
    jetPoint.path = Math.floor(Math.random() * 3);
    console.log(jetPoint.path);
    this.jetPoints.push(jetPoint);
    this.scene.add(temp);
  }

  addParachute() {
    let temp = this.paraModelMain.all_model[0].clone();
    const parachutePoint = new ParachutePoint(this.scene);
    parachutePoint.model = temp;
    parachutePoint.path = Math.floor(Math.random() * 3);
    parachutePoint.particleEmitter = new ParticleEmitter(this.scene, temp.position); // Initialize particle emitter

    console.log(parachutePoint.path);
    this.parachutePoints.push(parachutePoint);
    this.scene.add(temp);

    // Delay before adding to activeEnemies
    setTimeout(() => {
        this.waveManager.activeEnemies.push(parachutePoint);
    }, 2000); // 2 seconds delay
}
  updateParachute(parachutePoint, index) {
    parachutePoint.hitbox.position.copy(parachutePoint.model.position);
    if (!this.parachute_1Points || !this.parachute_2Points || !this.parachute_3Points) {
      return;
    }
    let nextIndex = index + 2;
    if (nextIndex > 199) {
      this.waveManager.spawnCounters.target--;
      AudioManager.playSound('explode'); // Play reload sound
      parachutePoint.destroyed = true;
      this.scene.remove(parachutePoint.model);
  
      // Deduct health and trigger fireworks
      this.takeDamage(5);
      this.fireworks.push(new Firework(this.scene, parachutePoint.model.position));
  
      const parachuteIndex = this.parachutePoints.indexOf(parachutePoint);
      if (parachuteIndex !== -1) {
        this.parachutePoints.splice(parachuteIndex, 1);
      }
      nextIndex = 0;
  
      // Check if player health reaches 0
      if (this.playerHealth <= 0) {
        console.log("Game Over");
        // Add additional logic for game over if necessary
      }
  
      return;
    }
    let parseint = parseInt(nextIndex, 10);
    parachutePoint.currPointLookAt.copy(new Vector3(0, 0, 0));
    // parachute 1
    switch (parachutePoint.path) {
      case 0:
        parachutePoint.pointParachute = this.parachute_1Points[parseInt(index, 10)];
        parachutePoint.currPointLookAt.add(this.parachute_1Points[parseint]);
        break;
      case 1:
        parachutePoint.pointParachute = this.parachute_2Points[parseInt(index, 10)];
        parachutePoint.currPointLookAt.add(this.parachute_2Points[parseint]);
        break;
      default:
        parachutePoint.pointParachute = this.parachute_3Points[parseInt(index, 10)];
        parachutePoint.currPointLookAt.add(this.parachute_3Points[parseint]);
    }
  
    parachutePoint.currPoint.copy(new Vector3(0, 0, 0));
    parachutePoint.currPoint.add(parachutePoint.pointParachute);
  
    parachutePoint.lerpedPoint.lerp(parachutePoint.currPoint, 0.05);
    parachutePoint.lerpedPointLookAt.lerp(parachutePoint.currPointLookAt, 0.055);
  
    if (parachutePoint.model) {
      parachutePoint.model.position.setX(parachutePoint.lerpedPoint.x);
      parachutePoint.model.position.setY(parachutePoint.lerpedPoint.y);
      parachutePoint.model.position.setZ(parachutePoint.lerpedPoint.z);
      parachutePoint.model.lookAt(parachutePoint.lerpedPointLookAt);
    }
  
    if (parachutePoint.particleEmitter) {
     // parachutePoint.particleEmitter.update(parachutePoint.model.position); // Update particle emitter
    }
  }
  
  
  updateJet(jetPoint, index) {
    let nextIndex = index + 2;
    if (nextIndex > 199) {
      AudioManager.playSound('explode'); // Play reload sound
      jetPoint.destroyed = true;
      this.waveManager.spawnCounters.target--;

      // Deduct health and trigger fireworks
      this.takeDamage(10);
      this.fireworks.push(new Firework(this.scene, jetPoint.model.position));
      this.scene.remove(jetPoint.model);
      const jetIndex = this.jetPoints.indexOf(jetPoint);
      if (jetIndex !== -1) {
        this.jetPoints.splice(jetIndex, 1);
      }
      nextIndex = 0;
      return;
    }
    jetPoint.hitbox.position.copy(jetPoint.model.position);

    let parseint = parseInt(nextIndex, 10);
    jetPoint.currPointLookAt.copy(new Vector3(0, 0, 0));

    // update jet lerping points
    switch (jetPoint.path) {
      case 0:
        jetPoint.pointJet = this.jet_1Points[parseInt(index, 10)];
        jetPoint.currPointLookAt.add(this.jet_1Points[parseint]);
        break;
      case 1:
        jetPoint.pointJet = this.jet_2Points[parseInt(index, 10)];
        jetPoint.currPointLookAt.add(this.jet_2Points[parseint]);
        break;
      default:
        jetPoint.pointJet = this.jet_3Points[parseInt(index, 10)];
        jetPoint.currPointLookAt.add(this.jet_3Points[parseint]);
    }

    jetPoint.currPoint.copy(new Vector3(0, 0, 0));
    jetPoint.currPoint.add(jetPoint.pointJet);

    jetPoint.lerpedPoint.lerp(jetPoint.currPoint, 0.05);
    jetPoint.lerpedPointLookAt.lerp(jetPoint.currPointLookAt, 0.055);

    if (jetPoint.model) {
      jetPoint.model.position.setX(jetPoint.lerpedPoint.x);
      jetPoint.model.position.setY(jetPoint.lerpedPoint.y);
      jetPoint.model.position.setZ(jetPoint.lerpedPoint.z);
      jetPoint.model.lookAt(jetPoint.lerpedPointLookAt);
    }
  }

  triggerFireworksLeft() {
    let position_left = new Vector3(-9, 26, -15);
    this.fireworks.push(new Firework(this.scene, position_left));
  }
  triggerFireworksRight() {
    let position_right = new Vector3(-9, 26, 15);
    this.fireworks.push(new Firework(this.scene, position_right));
  }
  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance }) {
    if (this.postProcess) {
        if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
            this.postProcess.bloomPass.strength = bloomIntensity;
        }
  
        if (bokehAperture !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['aperture'].value = bokehAperture;
        }

        if (focusDistance !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['focus'].value = focusDistance;
        }
    }
}
incrementScore(points) {
  this.score += points;
}
  componentDidMount() {
    console.log("scene 1 mounted");
    AudioManager.loadSounds();

    this.SceneMount();
    this.setCamera(40, 40, this.detectDevice() == 'computer' ? 35 : 60);
    this.setPostProcess();
    this.orbitalCamera = new OrbitalCameraGame(this.newCamera.threeCamera);
    this.orbitalCamera.setPosition(30, 12, -30); // Set the camera position to (30, 20, 50)


    //this.orbitalCamera.spherical.set(40, Math.PI / 3, 40);



    this.cameraTarget = new THREE.Vector3(0, 0, 0);
  }
  componentWillUnmount() {
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    //document.addEventListener("mousedown", this.onPointerDown.bind(this));
    //document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));

    // Touch events
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));

    // Zoom event
    document.addEventListener("wheel", this.onZoom.bind(this));
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

   
    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
  }

  trigger_parachute() {
    console.log('spawn one parachute');
    //this.addParachute();
  }
   // Method to set the onWaveComplete callback
   setOnWaveCompleteCallback(callback) {
    this.onWaveComplete = callback;
  }

  // Example method that triggers when a wave is complete
  handleWaveComplete() {
    if (this.onWaveComplete) {
      this.onWaveComplete(); // Call the callback when a wave is complete
    }
  }
  
  Update() {
    if(this.playerHealth<=0)
      return;
    this.updatePostProcessingSettings({
      bloomIntensity: 0.2,
      focusDistance: 10,
      bokehAperture: 0.000025,
  });
    this.gunners.forEach(gunner => gunner.update());
    this.bloodEmitter.update();  // Update the particle emitter every frame
    this.skyMaterial.uniforms.time.value += this.dt * 0.001;
    this.skyMaterial.uniforms.sunPosition.value.set(
        100 * Math.sin(3),
        100 * Math.cos(6),
        100
    );
  // Keep bullets in front of the camera
  this.updateBulletPositions();
    // Smoothly apply recoil using lerp

  // Gradually reduce the recoil velocity for smooth recoil recovery
  this.recoilVelocity.lerp(new THREE.Vector3(0, 0, 0), this.recoilRecoverySpeed);
    this.waveManager.update();
    //update delta time
    const now = performance.now();
    this.dt = (now - this.lastUpdate); // Delta time in seconds
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime); // Total time in seconds
    this.muzzleFlashes = this.muzzleFlashes.filter(flash => {
      flash.material.uniforms.time.value += this.dt;
      if (flash.material.uniforms.time.value >= flash.material.uniforms.duration.value) {
        this.scene.remove(flash);
        return false;
      }
      return true;
    });
    // Existing update logic...
    if (now - this.lastJetSpawnTime > this.jetSpawnInterval && this.waveManager.spawnCounters.jets > 0) {
      this.spawnJet();
      this.lastJetSpawnTime = now;
      this.waveManager.spawnCounters.jets--;
    }

    if (now - this.lastHelicopterSpawnTime > this.helicopterSpawnInterval && this.waveManager.spawnCounters.helicopters > 0) {
      this.triggerHelicopter();
      this.lastHelicopterSpawnTime = now;
      this.waveManager.spawnCounters.helicopters--;
    }

    if (now - this.lastParachuteSpawnTime > this.parachuteSpawnInterval && this.waveManager.spawnCounters.parachuters > 0) {
      this.addParachute();
      this.lastParachuteSpawnTime = now;
      this.waveManager.spawnCounters.parachuters--;
    }
  


    // Update bombs
    this.bombs.forEach(bomb => bomb.update());

    // Helicopters drop bombs periodically
    this.helicopterPoints.forEach(helicopterPoint => {
      if(helicopterPoint &&  helicopterPoint.body)
        {
      if (Math.random() < 0.002 &&helicopterPoint.body.position.z <35&&helicopterPoint.body.position.z>-35) { // Random chance to drop a bomb
        
        this.dropBomb(helicopterPoint);
      }
    }
    });
    for (let i = this.fireworks.length - 1; i >= 0; i--) {
      if (this.fireworks[i].done) {
        this.fireworks.splice(i, 1);
      } else {
        this.fireworks[i].update();
      }
    }
      if (this.fullLoaded) {
      this.updateHelicopter();
  
      // update parachute
      for (var i = 0; i < this.parachutePoints.length; ++i) {
        this.updateParachute(this.parachutePoints[i], this.parachutePoints[i].modelTime);
        if (this.parachutePoints[i]) {
          this.parachutePoints[i].modelTime += this.dt / 50;
          if (this.parachutePoints[i].modelTime > 199.0) {
            this.parachutePoints[i].modelTime = 0.0;
          }
        }
      }
  
      //update jet
      for (var i = 0; i < this.jetPoints.length; ++i) {
        this.updateJet(this.jetPoints[i], this.jetPoints[i].modelTime);
        if (this.jetPoints[i]) {
          this.jetPoints[i].modelTime += this.dt / 80;
          if (this.jetPoints[i].modelTime > 199.0) {
            this.jetPoints[i].modelTime = 0.0;
          }
        }
      }
  
      //update platform
      for (var i = 0; i < this.platformPoints.length; ++i) {
        this.updatePlatform(this.platformPoints[i], this.platformPoints[i].modelTime);
        if (this.platformPoints[i]) {
          if (this.platformPoints[i].modelTime < 199) {
            //this.platformPoints[i].modelTime += this.dt / 40;
  
          }
        }
      }
    }
  
    if (this.storeTop > 0.1 ) {
      //console.log("scroll target : " + this.storeTop);
      //document.getElementById('scc').scrollTop = this.storeTop;
      // this.storeTop = 0;
    }
    // const t2 = document.getElementById('scc').scrollTop / window.innerHeight / 2;
    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
   
      this.append = 0;
      if (this.detectDevice() == 'computer') {
        this.lerpedMouse.x = MathUtils.lerp(this.lerpedMouse.x, this.mouseNormalized.x, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouse.y = MathUtils.lerp(this.lerpedMouse.y, this.mouseNormalized.y, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.x = MathUtils.lerp(this.lerpedMouseRaw.x, this.mouse.x, 0.01 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.y = MathUtils.lerp(this.lerpedMouseRaw.y, this.mouse.y, 0.01 * 1 / this.dt * 1.5);
      }
    
  
    //get page position and lerp camera 
  
    this.pageLerp = 0.005;
    this.pageLerp2 = 0.005;
    this.pageLerp3 = 0.005;
  
    var index = MathUtils.clamp(this.pageLerp2, 0.0, 0.99);
  
    let currPoint = this.points[parseInt(index * 200, 10)];
    let currPointLookAt = this.lookAtPoints[parseInt(index * 200, 10)];
    let currPointDrone = this.dronePoints[parseInt(index * 200, 10)];
    this.tempPoint = currPoint;
    this.tempPointLookAt = currPointLookAt;
  
    this.sendPoint.copy(this.camPos);
    this.sendPoint.add(currPoint);
    this.sendPoint.add(this.camDirection);
    this.sendPoint.add(this.camSide);
    this.sendPoint.add(this.camUp);
  
    this.currDronePoint.copy(new Vector3(0, 0, 0));
    this.currDronePoint.add(currPointDrone);
  
    this.sendPointLookAt.copy(this.camLookAt);
    this.sendPointLookAt.add(currPointLookAt);
  
  
    this.currPointLerped.lerp(this.sendPoint, 0.05);
    this.dronePointLerped.lerp(this.currDronePoint, 0.01);
  
    this.currPointLookAtLerped.lerp(this.sendPointLookAt, 0.05);

    // Update orbital camera
    this.orbitalCamera.update(this.totalTime*0.0001,this.waveManager.activeEnemies,this.recoilOffset);
    // Ensure the Three.js camera is updated
   // this.newCamera.threeCamera.position.copy(this.orbitalCamera.camera.position);

    if (this.waveManager.activeEnemies.length > 0) {
      const averagePosition = new THREE.Vector3();
      let validEnemyCount = 0;

      this.waveManager.activeEnemies.forEach(enemy => {
          if (enemy && enemy.model&& enemy.model.position) {
              averagePosition.add(enemy.model.position);
              validEnemyCount++;
          }else if(enemy && enemy.hitbox && enemy.hitbox.position) {
            averagePosition.add(enemy.hitbox.position);
            validEnemyCount++;
          }
      });

      if (validEnemyCount > 0) {
        this.avgPos = averagePosition;
          averagePosition.divideScalar(validEnemyCount);
          this.lerpFactor=0.01;
          this.orbitalCamera.updateTargetWithDoubleLerp(averagePosition, this.recoilOffset);

          //this.orbitalCamera.target.lerp(averagePosition.add(this.recoilOffset), this.lerpFactor);
      }
  }
    //this.newCamera.threeCamera.lookAt(this.orbitalCamera.target.add(this.recoilOffset));
    this.recoilOffset.lerp(this.recoilVelocity, this.recoilRecoverySpeed);


    // Force the camera to update its matrix
    this.newCamera.threeCamera.updateMatrixWorld(true);
  
  if(this.avgPos)
      this.orbitalCamera.target =  this.avgPos;
    
  
    this.boot = true;
  }
}

export default React.forwardRef((props, ref) => <StormTheRoof {...props} ref={ref} />);
