import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';
import React, { Component } from 'react';

//renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { PropShader } from "../../Shaders/PropShader";
import { loadFont, loadCurveFromJSON, createText } from "./Helper";
import ParticleEmitter from './ParticleEmitter';
import OrbitalCamera from './OrbitalCamera';
import gsap from "gsap";

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
let COLLISION_LAYER = 1;
class HelicopterPoint {
  constructor() {
    this.body = null;
    this.blade_1 = null;
    this.blade_2 = null;

    this.position = new THREE.Vector3(0, 0, 0);
  }
}
class ParachutePoint {
  constructor() {
    this.model = null;
    this.modelTime = 0.0;
    this.currPoint = new THREE.Vector3(0, 15, 0);
    this.currPointLookAt = new THREE.Vector3(0, 15, 0);
    this.lerpedPoint = new THREE.Vector3(0, 15, 0);
    this.lerpedPointLookAt = new THREE.Vector3(0, 15, 0);
    this.pointParachute = new THREE.Vector3(0, 0, 0);
    this.particleEmitter = null; // Add particle emitter

  }
}
class JetPoint {
  constructor() {
    this.model = null;
    this.modelTime = 0.0;
    this.currPoint = new THREE.Vector3(0, 15, 0);
    this.currPointLookAt = new THREE.Vector3(0, 15, 0);
    this.lerpedPoint = new THREE.Vector3(0, 15, 0);
    this.lerpedPointLookAt = new THREE.Vector3(0, 15, 0);
    this.pointJet = new THREE.Vector3(0, 0, 0);
  }
}

class PlatformPoint {
  constructor() {
    this.model = null;
    this.modelTime = 0.0;
    this.currPoint = new THREE.Vector3(0, 0, 0);
    this.currPointLookAt = new THREE.Vector3(-4, 0, 0);
    this.lerpedPoint = new THREE.Vector3(-4, 0, 0);
    this.lerpedPointLookAt = new THREE.Vector3(-4, 0, 0);
    this.pointPlatform = new THREE.Vector3(0, 0, 0);
  }
}
class Firework {
  constructor(scene, pos) {
    this.scene = scene;
    this.done = false;
    this.dest = [];
    this.colors = [];
    this.geometry = null;
    this.points = null;
    this.initialPosition = pos;
    this.material = new THREE.PointsMaterial({
      size: 0.4,
      color: 0xffffff,
      opacity: 1,
      vertexColors: true,
      transparent: true,
      depthTest: false,
    });
    this.launch();
  }

  reset() {
    this.scene.remove(this.points);
    if (this.explosionLight) {
      this.scene.remove(this.explosionLight);
    } this.dest = [];
    this.colors = [];
    this.geometry = null;
    this.points = null;
    this.explosionLight = null; // Reset the point light
  }

  launch() {
    const x = this.initialPosition.x;
    const y = this.initialPosition.y;
    const z = this.initialPosition.z;

    const from = new THREE.Vector3(x, 2, z);
    const to = new THREE.Vector3(x + THREE.MathUtils.randFloat(-3.9, 3.9)*10, y, z + THREE.MathUtils.randFloat(-3.9, 3.9)*10);

    const color = new THREE.Color();
    color.setHSL(THREE.MathUtils.randFloat(0.1, 0.9), 1, 0.9);
    this.colors.push(color);

    this.geometry = new THREE.BufferGeometry();
    this.points = new THREE.Points(this.geometry, this.material);

    this.geometry.setAttribute('position', new THREE.Float32BufferAttribute([from.x, from.y, from.z], 3));
    this.geometry.setAttribute('color', new THREE.Float32BufferAttribute(color.toArray(), 3));

    this.dest.push(to);

    this.scene.add(this.points);

  }

  explode(vector) {
    this.scene.remove(this.points);
    this.dest = [];
    this.colors = [];
    this.geometry = new THREE.BufferGeometry();
    this.points = new THREE.Points(this.geometry, this.material);

    const positions = [];
    const colors = [];

    for (let i = 0; i < 80; i++) {
      const color = new THREE.Color();
      color.setHSL(THREE.MathUtils.randFloat(0.1, 0.9), 1, 0.5);
      this.colors.push(color);

      const from = vector;
      const to = new THREE.Vector3(
        THREE.MathUtils.randInt(vector.x - 10, vector.x + 10),
        THREE.MathUtils.randInt(vector.y - 10, vector.y + 10),
        THREE.MathUtils.randInt(vector.z - 10, vector.z + 10)
      );

      positions.push(from.x, from.y, from.z);
      colors.push(color.r, color.g, color.b);
      this.dest.push(to);
    }

    this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    this.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    this.scene.add(this.points);
    // Add point light at the explosion point
    this.explosionLight = new THREE.PointLight(0xffffff, 50, 100, 25);
    this.explosionLight.position.copy(vector);
    this.scene.add(this.explosionLight);
  }

  update() {
    if (this.points && this.geometry) {
      const positions = this.geometry.attributes.position.array;
      const total = positions.length / 3;

      for (let i = 0; i < total; i++) {
        positions[i * 3] += (this.dest[i].x - positions[i * 3]) / 50;
        positions[i * 3 + 1] += (this.dest[i].y - positions[i * 3 + 1]) / 50;
        positions[i * 3 + 2] += (this.dest[i].z - positions[i * 3 + 2]) / 50;
      }
      this.geometry.attributes.position.needsUpdate = true;

      if (total === 1) {
        if (Math.ceil(positions[1]) > (this.dest[0].y - 15)) {
          this.explode(new THREE.Vector3(positions[0], positions[1], positions[2]));
          return;
        }
      }

      if (total > 1) {
        this.material.opacity -= 0.015;
        this.material.needsUpdate = true;
      }

      if (this.material.opacity <= 0) {
        this.reset();
        this.done = true;
        return;
      }
    }
    // Update explosion light intensity
    if (this.explosionLight) {
      this.explosionLight.intensity -= 5.5;
      if (this.explosionLight.intensity <= 0) {
        this.scene.remove(this.explosionLight);
        this.explosionLight = null;
      }
    }
  }
}
class Island extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.buttonVisibilityCallback = null;


    // Define colors for day and night
    this.dayAmbientColor = new THREE.Color(0xffffff);
    this.nightAmbientColor = new THREE.Color(0x545863);
 

    this.fireworks = [];
    this.lastFireworkTime = 0;
    this.fireworkInterval = 600; // Launch a new firework every 2 seconds
    this.fireworkselection = 0;

    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    // call back for mouse collision detection
    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;
    this.collision_none = _options.collision_none_callback;

    this.parachutePoints = [];
    this.helicopterPoints = [];
    this.jetPoints = [];
    this.platformPoints = [];

    this.hoverAnimationsEnabled = false;

    this.hide = false;
    this.current = 0;

    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = performance.now()
    this.totalTime = 0.0;
    this.startTime = performance.now()

    // helicopter
    this.helicopterMove = false;

    //camera vectors
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);
    this.canMoveCamera = false;
 // Initial progress variable for color transition
 this.colorTransitionProgress = 0;
    let d = 50;
    let r = 1;
    let mapSize = 2048;
    this.ambient = new THREE.AmbientLight(0xffffff, 0.5, 0);

    this.dirLight = new THREE.DirectionalLight(0xffffff, 3.95);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(40, 20, 20);
    this.spot = new THREE.SpotLight(0xffffff, 600.0, 100.0, 50, 1, 1);
    this.spot2 = new THREE.SpotLight(0xffffff, 600.0, 100.0, 50, 1, 1);

    //const helper = new THREE.CameraHelper(this.dirLight.shadow.camera);
    this.scene.add(this.ambient);
    this.scene.add(this.spot);
    this.scene.add(this.spot2);

    this.scene.add(this.dirLight);

    this.backgroundPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(200, 200),
      new THREE.MeshBasicMaterial({ color: 0xe71d88 })
    );
    this.backgroundPlane.position.set(0, -4, 0); // Slightly behind the introLogo
    //this.backgroundPlane.lookAt(30, 50, 0);
    this.backgroundPlane.rotation.set(Math.PI / 2 - 0.25, Math.PI / 2 + 1.5, -Math.PI / 2);
    this.backgroundPlane.scale.set(20, window.innerHeight, 20); // Scale to fill the screen

    this.scene.add(this.backgroundPlane);



    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;

    this.platformToggle = false;
    this.platformsInitialized = false;

   // this.create_collision_box(new THREE.Vector3(6, 3, 16), new THREE.Vector3(7, 6, 5), 'collision_parachute');
    //this.create_collision_box(new THREE.Vector3(-3, 2, -17), new THREE.Vector3(6, 8, 10), 'collision_fireworks_left');
   // this.create_collision_box(new THREE.Vector3(-3, 2, 17), new THREE.Vector3(6, 8, 10), 'collision_fireworks_right');
   // this.create_collision_box(new THREE.Vector3(-6, 2, 6), new THREE.Vector3(6, 8, 10), 'collision_helicopter');
   // this.create_collision_box(new THREE.Vector3(10, 2, -19), new THREE.Vector3(7, 6, 5), 'collision_jet');
   // this.create_collision_box(new THREE.Vector3(-6, 2, -6), new THREE.Vector3(6, 8, 10), 'collision_platform');



    //add event listeners
    this.loadListener();
    this.startRender();
    this.generateCamCurve();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.dronePointLerped = new Vector3(0, 30, 0);

    this.lookDown = new Vector3(0, -30, 0);
    this.currPointLerped.set
      (-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set
      (-0.605, 0.054, 0.281);

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();

    this.requiredAssets = [


      // background asset
      { name: 'paradeModel', source: '/assets/parade.glb' },
      { name: 'paradeTexture', source: '/assets/parade_render.jpg', type: 'texture' },

      // parachute asset
      { name: 'parachuteModel', source: '/assets/parachute.glb' },
      { name: 'parachuteTexture', source: '/assets/parade_render.jpg', type: 'texture' },

      // helicopter asset
      { name: 'helicopterModel', source: '/assets/helicopter.glb' },
      { name: 'helicopterBladeModel', source: '/assets/helicopter_blade.glb' },

      //jet asset
      { name: 'jetModel', source: '/assets/jet.glb' },

      //platform asset
      { name: 'platformModel', source: '/assets/platform.glb' },

      //logo asset
      { name: 'logoModel', source: '/assets/logo.glb' },

      // parade background
      { name: 'paradeBackgroundModel', source: '/assets/parade_background.glb' },
    ];
    this.isDay = true;
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    // Track which text is hovered over
    this.hoveredText = null;

    // Start spotlight animation
    this.animateSpotlights();
  }

  // Function to interpolate colors
  interpolateColors(color1, color2, factor) {
    const result = new THREE.Color();
    result.r = THREE.MathUtils.lerp(color1.r, color2.r, factor);
  result.g = THREE.MathUtils.lerp(color1.g, color2.g, factor);
    result.b = THREE.MathUtils.lerp(color1.b, color2.b, factor);
    return result;
  }

  toggleDayNight = () => {
    this.isDay = !this.isDay;

    const targetAmbientColor = this.isDay ? this.dayAmbientColor : this.nightAmbientColor;
    //const targetBackgroundColor = this.isDay ? this.dayBackgroundColor : this.nightBackgroundColor;
    const targetIntensity = 2.65;

    gsap.to(this.ambient.color, {
      r: targetAmbientColor.r,
      g: targetAmbientColor.g,
      b: targetAmbientColor.b,
      duration: 1
    });

    gsap.to(this.ambient, {
      intensity: targetIntensity,
      duration: 1
    });

  }


  animateSpotlights() {
    const updateSpotlightPosition = () => {
      const lookAtTarget1 = new THREE.Vector3(
        MathUtils.randFloat(-20, 20)/3,
        MathUtils.randFloat(0, 30)/3,
        MathUtils.randFloat(-20, 20)/3
      );
      const lookAtTarget2 = new THREE.Vector3(
        MathUtils.randFloat(-20, 20)/3,
        MathUtils.randFloat(0, 30)/3,
        MathUtils.randFloat(-20, 20)/3
      );
  
      gsap.to(this.spot.target.position, {
        x: lookAtTarget1.x,
        y: lookAtTarget1.y,
        z: lookAtTarget1.z,
        duration: 2,
        ease: "power2.inOut",
        onUpdate: () => {
          this.spot.target.updateMatrixWorld();
        },
        onComplete: updateSpotlightPosition // Continue the animation cycle
      });
  
      gsap.to(this.spot2.target.position, {
        x: lookAtTarget2.x,
        y: lookAtTarget2.y,
        z: lookAtTarget2.z,
        duration: 2,
        ease: "power2.inOut",
        onUpdate: () => {
          this.spot2.target.updateMatrixWorld();
        }
      });
  
      const color1 = new THREE.Color(`hsl(${MathUtils.randInt(0, 360)}, 100%, 50%)`);
      const color2 = new THREE.Color(`hsl(${MathUtils.randInt(0, 360)}, 100%, 50%)`);
  
      gsap.to(this.spot.color, {
        r: color1.r,
        g: color1.g,
        b: color1.b,
        duration: 2
      });
  
      gsap.to(this.spot2.color, {
        r: color2.r,
        g: color2.g,
        b: color2.b,
        duration: 2
      });
    };
  
    updateSpotlightPosition(); // Start the animation cycle
  }
  
  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'paradeAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  onAllAssetsLoaded() {
    console.log("All assets loaded");
    this.createSceneObjects();

    // load all required assets
    this.setupPlatform();
    this.setupParachute();
    this.setupHelicopter();
    this.setupJet();

    // setup stage interaction
    this.setupStageInteractions();

    // Start the introduction sequence
    this.startIntroSequence();

    let clickEnabled = false;


    // Update raycaster logic in t function and updateHoverAnimation method
    const t = (event) => {
      if (this.hoverAnimationsEnabled == false)
        return;
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
      raycaster.layers.set(COLLISION_LAYER); // Set raycaster layer to collision layer
      const intersects = raycaster.intersectObjects(this.scene.children, true);
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name === 'collision_parachute') {
          console.log('adding parachute');
          this.addParachute();
          break;
        }
        if (intersects[i].object.name === 'collision_fireworks_left') {
          console.log('trigger fireworks left');
          this.triggerFireworksLeft();
          break;
        }
        if (intersects[i].object.name === 'collision_fireworks_right') {
          console.log('trigger fireworks right');
          this.triggerFireworksRight();
          break;
        }
        if (intersects[i].object.name === 'collision_helicopter') {
          this.triggerHelicopter();
          console.log('trigger helicopter');
          break;
        }
        if (intersects[i].object.name === 'collision_jet') {
          console.log('trigger jet');
          this.addJet();
          break;
        }
        if (intersects[i].object.name === 'collision_platform') {
          this.togglePlatform();
          break;
        }
      }
    }

    function onMouseClick(event) {
      if (clickEnabled) {
        // Your click handling code here
        console.log("Mouse clicked");

        // Disable further clicks
        clickEnabled = false;
        t(event);
        // Remove the event listener to prevent further clicks
        window.removeEventListener('click', onMouseClick);
        window.removeEventListener('touchstart', onMouseClick);
      }
    }

    function onMouseUp(event) {
      // Re-enable clicks
      clickEnabled = true;
      console.log("Mouse up");

      // Re-attach the event listener
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('touchstart', onMouseClick, false);
    }

    // Initial event listeners
    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);

    // Event listeners to re-enable clicks
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }
  // Introduction sequence with scaling in the logo from 0 to full size
  startIntroSequence() {
    this.newCamera.threeCamera.position.set(20, 5, 20); // Set fixed camera position
    console.log(this.introLogo.all_model[0]);
    this.newCamera.threeCamera.lookAt(this.introLogo.all_model[0].position); // Look at the logo mesh
    gsap.to(this.introLogo.all_model[0].scale, {
      duration: 1,
      ease: "elastic.inOut",
      x: 3,
      y: 3,
      z: 3,
      onComplete: () => {
        gsap.to(this.introLogo.all_model[0].scale, {
          duration: 1,
          ease: "elastic.inOut",
          x: 0,
          y: 0,
          z: 0,
          delay: 0.4,
          onComplete: () => {
            this.scene.remove(this.introLogo.all_model[0]);
          }
        });
        gsap.to(this.backgroundPlane.scale, {
          duration: 2,
          ease: "power4.Out",
          x: 0,
          y: 0,
          z: 0,
          delay: 0,
          onComplete: () => {
            //this.scene.remove(this.backgroundPlane);
            this.startParadeModelAnimation();
          }
        });

      }
    });
  }

  // Start hover animation after intro sequence
  startParadeModelAnimation() {
    this.ambient.intensity = 2.65;
    this.dirLight.intensity = 0;
    this.orbitalCamera.target = new Vector3(0, -2, 0);
    gsap.to(this.p1model.all_model[0].position, {
      duration: 1.1,
      ease: "power4.inOut",
      x: 19,
      y: 1,
      z: 0,
      onComplete: () => {
        // this.p1model.all_model[0].position.set(17, 0, 0);
        if (this.buttonVisibilityCallback) {
          this.buttonVisibilityCallback();
        }
        this.canMoveCamera = true;
        this.animateTextGeometries();
        this.enableHoverAnimations(); // Enable hover animations after intro sequence
      }
    });
  }
  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }
  // Enable hover animations
  enableHoverAnimations() {
    this.hoverAnimationsEnabled = true;
  }

  // Update hover animation logic
  animateTextHover(textObject) {
    if (this.hoverAnimationsEnabled) { // Only enable hover animations if allowed
      if (!textObject.userData.initialPosition) {
        textObject.userData.initialPosition = textObject.position.clone();
      }
      gsap.to(textObject.position, { y: textObject.userData.initialPosition.y + 1, duration: 0.5, yoyo: true, repeat: -1 });
      gsap.to(textObject.scale, { x: 1.2, y: 1.2, z: 1.2, duration: 0.5, yoyo: true, repeat: -1 });
    }
  }

  animateTextGeometries() {
    // Assuming textParachute, textJet, textHelicopter, textPlatform are added to the scene
    const textObjects = ['text_parachute', 'text_jet', 'text_helicopter', 'text_platform'];
    let delay = 0;

    textObjects.forEach((name) => {
      const textObject = this.scene.getObjectByName(name);
      if (textObject) {
        gsap.to(textObject.scale, {
          duration: 1,
          ease: "elastic.inOut",
          x: 1,
          y: 1,
          z: 1,
          delay: delay
        });
        delay += 0.25; // Half second gap between each animation
      }
    });
  }

  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("paradeTexture");

    console.log(paletteTexture);
    this.p1Material = new Material({
      uniforms: {
        downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
      },
      vertexShader: PropShader.vertexShader,
      fragmentShader: PropShader.fragmentShader
    });

    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });

    standardMaterial.shininess = 5;
    const modelName = 'paradeModel';
    this.p1model = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(17, 1, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'bgmainScene'
    });
    this.p1model.all_model[0].position.set(0, -100, 0);

 // add parachute models
 const backgroundMaterial = new THREE.MeshPhongMaterial({
  receiveShadow: true,
  dithering: true,
  castShadow: true,
  color: '#ffffff'
});
    this.backgroundModel = new Model({
      modelLink: 'paradeBackgroundModel',
      material: backgroundMaterial,
      position: new THREE.Vector3(0,1, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(2, 2,2),
      scene: this.scene,
      name: 'bgmainScene'
  });
    this.backgroundModel.all_model[0].position.set(30, 0, 0);
    this.backgroundModel.all_model[0].scale.set(25, 25,25);







    this.introLogo = new Model({
      modelLink: 'logoModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'introLogo'
    });

    this.introLogo.all_model[0].scale.set(0, 0, 0);




    this.fullLoaded = true;

  }

  // Update create_collision_box to use the collision layer
  create_collision_box = (position, size, name) => {
    this.cube = new Mesh(
      new BoxGeometry(size.x, size.y, size.z),
      new MeshBasicMaterial({ color: 0x22ffffff, opacity: 0.1 }),
    );
    this.cube.position.set(position.x, position.y, position.z);

    this.cube.visible = false;

    this.cube.name = name;
    this.cube.layers.set(COLLISION_LAYER); // Set collision layer
    this.scene.add(this.cube);
  }

  generateCamCurve() {
    //position points
    var posPoints = [];
    var dronePoints = [];
    dronePoints.push(new THREE.Vector3(-50, 24, -40));
    dronePoints.push(new THREE.Vector3(-8, 4, -19));


    posPoints.push(new THREE.Vector3(50, 24, -40));
    posPoints.push(new THREE.Vector3(50, 24, 40));


    this.spline = new THREE.CatmullRomCurve3(posPoints);

    this.points = this.spline.getPoints(200);

    //rotation points
    var lookAtPoints = [];
    lookAtPoints.push(new THREE.Vector3(5, 3, -1));
    lookAtPoints.push(new THREE.Vector3(5, 3, 1));


    this.lookAtSpline = new THREE.CatmullRomCurve3(lookAtPoints);
    this.lookAtPoints = this.lookAtSpline.getPoints(200);

    this.droneSpline = new THREE.CatmullRomCurve3(dronePoints);
    this.dronePoints = this.droneSpline.getPoints(200);
  }
  async setupStageInteractions() {
    const textParachute = await createText('parachute',
      new THREE.Vector3(10.5, 1.75, 17),
      new THREE.Vector3(0, Math.PI / 4 * 4, 0));
    textParachute.name = 'text_parachute';
    textParachute.scale.set(0, 0, 0); // Hide initially
    this.scene.add(textParachute);

    const textJet = await createText('fighter jet',
      new THREE.Vector3(5.5, 1.75, -19),
      new THREE.Vector3(0, Math.PI * 2, 0));
    textJet.name = 'text_jet';
    textJet.scale.set(0, 0, 0); // Hide initially
    this.scene.add(textJet);

    const textHelicopter = await createText('helicopter',
      new THREE.Vector3(-2.5, 1.75, 7),
      new THREE.Vector3(0, Math.PI / 2, 0));
    textHelicopter.name = 'text_helicopter';
    textHelicopter.scale.set(0, 0, 0); // Hide initially
    this.scene.add(textHelicopter);

    const textPlatform = await createText('platform',
      new THREE.Vector3(-2.5, 1.75, -7),
      new THREE.Vector3(0, Math.PI / 2, 0));
    textPlatform.name = 'text_platform';
    textPlatform.scale.set(0, 0, 0); // Hide initially
    this.scene.add(textPlatform);
  }

  // Add the following method to handle the toggle logic
  togglePlatform() {
    this.platformToggle = !this.platformToggle;
    if (!this.platformsInitialized) {
      this.platformsInitialized = true;
      for (let platform = 0; platform < 4; ++platform) {
        setTimeout(() => {
          this.addPlatform(platform);
        }, platform * 1000); // 1-second delay between each platform
      }
    } else {
      for (let i = 0; i < this.platformPoints.length; ++i) {
        this.platformPoints[i].modelTime = this.platformToggle ? 0 : 200; // Toggle modelTime between 0 and 200
      }
    }
  }
  async setupPlatform() {


    this.platform_1 = await loadCurveFromJSON('parade_platform_1.json');
    this.platform_2 = await loadCurveFromJSON('parade_platform_2.json');
    this.platform_3 = await loadCurveFromJSON('parade_platform_3.json');
    this.platform_4 = await loadCurveFromJSON('parade_platform_4.json');

    this.platform_1Points = this.platform_1.getPoints(200);
    this.platform_2Points = this.platform_2.getPoints(200);
    this.platform_3Points = this.platform_3.getPoints(200);
    this.platform_4Points = this.platform_4.getPoints(200);

    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });

    // standardMaterial.map.encoding = THREE.sRGBEncoding;
    standardMaterial.shininess = 5;

    this.platformModelMain = new Model({
      modelLink: 'platformModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'platformModel'
    });


  }
  async setupJet() {
    this.jet_1 = await loadCurveFromJSON('parade_parachute_1.json');
    this.jet_2 = await loadCurveFromJSON('parade_parachute_2.json');
    this.jet_3 = await loadCurveFromJSON('parade_parachute_3.json');

    this.jet_1Points = this.jet_1.getPoints(200);
    this.jet_2Points = this.jet_2.getPoints(200);
    this.jet_3Points = this.jet_3.getPoints(200);

    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });

    // standardMaterial.map.encoding = THREE.sRGBEncoding;
    standardMaterial.shininess = 5;

    this.jetModelMain = new Model({
      modelLink: 'jetModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'jetModel'
    });

  }

  async setupHelicopter() {
    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });

    // standardMaterial.map.encoding = THREE.sRGBEncoding;
    standardMaterial.shininess = 5;

    this.helicopterModelMain = new Model({
      modelLink: 'helicopterModel',
      material: standardMaterial,
      position: new THREE.Vector3(0, 999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'helicopterModel'
    });

    this.helicopterBladeModelMain = new Model({
      modelLink: 'helicopterBladeModel',
      material: standardMaterial,
      position: new THREE.Vector3(999, 99999, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'helicopterBladeModel'
    });
  }
  triggerHelicopter() {
    if (this.helicopterMove == false) {
      this.helicopterMove = true;
      for (var i = 0; i < 3; ++i) {

        let heli_1 = this.helicopterModelMain.all_model[0].clone();
        let heli_blade_1 = this.helicopterBladeModelMain.all_model[0].clone();
        let heli_blade_2 = this.helicopterBladeModelMain.all_model[0].clone();
        this.scene.add(heli_1);
        this.scene.add(heli_blade_1);
        this.scene.add(heli_blade_2);

        heli_1.position.set(i, 0, 40 + i);
        heli_blade_1.position.set(i, 0, 40 + i);
        heli_blade_2.position.set(i, 0, 41 + i);

        const heliPoint = new HelicopterPoint();
        heliPoint.body = heli_1;
        heliPoint.blade_1 = heli_blade_1;
        heliPoint.blade_2 = heli_blade_2;
        this.helicopterPoints.push(heliPoint);
      }
    }

  }
  updateHelicopter() {
    if (this.helicopterMove) {
      for (var i = 0; i < this.helicopterPoints.length; ++i) {


        this.helicopterPoints[i].blade_1.position.y = 4;
        this.helicopterPoints[i].blade_2.position.y = 4;
        this.helicopterPoints[i].body.position.y = 4;
        this.helicopterPoints[i].blade_1.rotation.y += 0.5;
        this.helicopterPoints[i].blade_2.rotation.y -= 0.8;

        this.helicopterPoints[i].blade_1.position.z -= 0.1;
        this.helicopterPoints[i].blade_2.position.z -= 0.1;
        this.helicopterPoints[i].body.position.z -= 0.1;

        if (this.helicopterPoints[i].body.position.z < -45) {
          //this.helicopterMove = false;
        }
      }
    }
  }
  async setupParachute() {

    this.parachute_1 = await loadCurveFromJSON('parade_parachute_1.json');
    this.parachute_2 = await loadCurveFromJSON('parade_parachute_2.json');
    this.parachute_3 = await loadCurveFromJSON('parade_parachute_3.json');

    this.parachute_1Points = this.parachute_1.getPoints(200);
    this.parachute_2Points = this.parachute_2.getPoints(200);
    this.parachute_3Points = this.parachute_3.getPoints(200);

    const paletteTexture = this.loader.getAsset("paradeTexture");

    // add parachute models
    const standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: '#ffffff'
    });
    // standardMaterial.map.encoding = THREE.sRGBEncoding;
    standardMaterial.shininess = 5;
    const modelName = 'parachuteModel';
    this.paraModelMain = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, -100, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'parachuteModel'
    });

    // add one parachute
    //this.addParachute();
    this.fullLoaded = true;
  }

  addPlatform(path) {
    let temp = this.platformModelMain.all_model[0].clone();
    const platformPoint = new PlatformPoint();
    platformPoint.model = temp;
    platformPoint.path = path;
    console.log(platformPoint.path);
    this.platformPoints.push(platformPoint);
    this.scene.add(temp);
  }

  addJet() {
    let temp = this.jetModelMain.all_model[0].clone();
    const jetPoint = new JetPoint();
    jetPoint.model = temp;
    jetPoint.path = Math.floor(Math.random() * 3);
    console.log(jetPoint.path);
    this.jetPoints.push(jetPoint);
    this.scene.add(temp);
  }

  addParachute() {
    let temp = this.paraModelMain.all_model[0].clone();
    const parachutePoint = new ParachutePoint();
    parachutePoint.model = temp;
    parachutePoint.path = Math.floor(Math.random() * 3);
    parachutePoint.particleEmitter = new ParticleEmitter(this.scene, temp.position); // Initialize particle emitter

    console.log(parachutePoint.path);
    this.parachutePoints.push(parachutePoint);
    this.scene.add(temp);
  }

  updateParachute(parachutePoint, index) {
    if (!this.parachute_1Points || !this.parachute_2Points || !this.parachute_3Points) {
      return;
    }
    let nextIndex = index + 2;
    if (nextIndex > 199) {
      this.scene.remove(parachutePoint.model);
      if (parachutePoint.particleEmitter) {
        parachutePoint.particleEmitter.remove(); // Remove particle emitter
      }
      const parachuteIndex = this.parachutePoints.indexOf(parachutePoint);
      if (parachuteIndex !== -1) {
        this.parachutePoints.splice(parachuteIndex, 1);
      }
      nextIndex = 0;
      return;
    }
    let parseint = parseInt(nextIndex, 10);
    parachutePoint.currPointLookAt.copy(new Vector3(0, 0, 0));
    // parachute 1
    switch (parachutePoint.path) {
      case 0:
        parachutePoint.pointParachute = this.parachute_1Points[parseInt(index, 10)];
        parachutePoint.currPointLookAt.add(this.parachute_1Points[parseint]);
        break;
      case 1:
        parachutePoint.pointParachute = this.parachute_2Points[parseInt(index, 10)];
        parachutePoint.currPointLookAt.add(this.parachute_2Points[parseint]);
        break;
      default:
        parachutePoint.pointParachute = this.parachute_3Points[parseInt(index, 10)];
        parachutePoint.currPointLookAt.add(this.parachute_3Points[parseint]);
    }
  
    parachutePoint.currPoint.copy(new Vector3(0, 0, 0));
    parachutePoint.currPoint.add(parachutePoint.pointParachute);
  
    parachutePoint.lerpedPoint.lerp(parachutePoint.currPoint, 0.05);
    parachutePoint.lerpedPointLookAt.lerp(parachutePoint.currPointLookAt, 0.055);
  
    if (parachutePoint.model) {
      parachutePoint.model.position.setX(parachutePoint.lerpedPoint.x);
      parachutePoint.model.position.setY(parachutePoint.lerpedPoint.y);
      parachutePoint.model.position.setZ(parachutePoint.lerpedPoint.z);
      parachutePoint.model.lookAt(parachutePoint.lerpedPointLookAt);
    }
  
    if (parachutePoint.particleEmitter) {
     // parachutePoint.particleEmitter.update(parachutePoint.model.position); // Update particle emitter
    }
  }
  
  
  updatePlatform(platformPoint, index) {
    let nextIndex = platformPoint.modelTime + (this.platformToggle ? 2 : -2) * 0.35;
    if (nextIndex > 199) nextIndex = 199;
    if (nextIndex < 0) nextIndex = 0;

    let parseint = parseInt(nextIndex, 10);
    platformPoint.currPointLookAt.copy(new Vector3(0, 0, 0));

    // update platform lerping points
    switch (platformPoint.path) {
      case 0:
        platformPoint.pointPlatform = this.platform_1Points[parseInt(index, 10)];
        platformPoint.currPointLookAt.add(this.platform_1Points[parseint]);
        break;
      case 1:
        platformPoint.pointPlatform = this.platform_2Points[parseInt(index, 10)];
        platformPoint.currPointLookAt.add(this.platform_2Points[parseint]);
        break;
      case 2:
        platformPoint.pointPlatform = this.platform_3Points[parseInt(index, 10)];
        platformPoint.currPointLookAt.add(this.platform_3Points[parseint]);
        break;
      default:
        platformPoint.pointPlatform = this.platform_4Points[parseInt(index, 10)];
        platformPoint.currPointLookAt.add(this.platform_4Points[parseint]);
    }

    platformPoint.currPoint.copy(new Vector3(0, 0, 0));
    platformPoint.currPoint.add(platformPoint.pointPlatform);

    platformPoint.lerpedPoint.lerp(platformPoint.currPoint, 0.05);
    platformPoint.lerpedPointLookAt.lerp(platformPoint.currPointLookAt, 0.055);

    if (platformPoint.model) {
      platformPoint.model.position.setX(platformPoint.lerpedPoint.x);
      platformPoint.model.position.setY(platformPoint.lerpedPoint.y);
      platformPoint.model.position.setZ(platformPoint.lerpedPoint.z);
      platformPoint.model.lookAt(platformPoint.lerpedPointLookAt);
    }
    platformPoint.modelTime = nextIndex; // Update modelTime
  }

  updateJet(jetPoint, index) {
    let nextIndex = index + 2;
    if (nextIndex > 199) {
      this.scene.remove(jetPoint.model);
      const jetIndex = this.jetPoints.indexOf(jetPoint);
      if (jetIndex !== -1) {
        this.jetPoints.splice(jetIndex, 1);
      }
      nextIndex = 0;
      return;
    }
    let parseint = parseInt(nextIndex, 10);
    jetPoint.currPointLookAt.copy(new Vector3(0, 0, 0));

    // update jet lerping points
    switch (jetPoint.path) {
      case 0:
        jetPoint.pointJet = this.jet_1Points[parseInt(index, 10)];
        jetPoint.currPointLookAt.add(this.jet_1Points[parseint]);
        break;
      case 1:
        jetPoint.pointJet = this.jet_2Points[parseInt(index, 10)];
        jetPoint.currPointLookAt.add(this.jet_2Points[parseint]);
        break;
      default:
        jetPoint.pointJet = this.jet_3Points[parseInt(index, 10)];
        jetPoint.currPointLookAt.add(this.jet_3Points[parseint]);
    }

    jetPoint.currPoint.copy(new Vector3(0, 0, 0));
    jetPoint.currPoint.add(jetPoint.pointJet);

    jetPoint.lerpedPoint.lerp(jetPoint.currPoint, 0.05);
    jetPoint.lerpedPointLookAt.lerp(jetPoint.currPointLookAt, 0.055);

    if (jetPoint.model) {
      jetPoint.model.position.setX(jetPoint.lerpedPoint.x);
      jetPoint.model.position.setY(jetPoint.lerpedPoint.y);
      jetPoint.model.position.setZ(jetPoint.lerpedPoint.z);
      jetPoint.model.lookAt(jetPoint.lerpedPointLookAt);
    }
  }

  triggerFireworksLeft() {
    let position_left = new Vector3(-9, 26, -15);
    this.fireworks.push(new Firework(this.scene, position_left));
  }
  triggerFireworksRight() {
    let position_right = new Vector3(-9, 26, 15);
    this.fireworks.push(new Firework(this.scene, position_right));
  }
  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }

  
  componentDidMount() {
    console.log("scene 1 mounted")
    this.SceneMount();
    this.setCamera(40, 40, this.detectDevice() == 'computer' ? 55 : 55);
    this.setPostProcess();
    this.orbitalCamera = new OrbitalCamera(this.newCamera.threeCamera);
    this.orbitalCamera.minPhi =0.77;
    this.orbitalCamera.maxPhi =1.45;
    this.orbitalCamera.minTheta =38.5;
    this.orbitalCamera.maxTheta =40.0;

    this.orbitalCamera.spherical.set(40, Math.PI / 3, 40);



    this.cameraTarget = new THREE.Vector3(0, 0, 0);
  }
  componentWillUnmount() {
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    document.addEventListener("mousedown", this.onPointerDown.bind(this));
    document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));

    // Touch events
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));

    // Zoom event
    document.addEventListener("wheel", this.onZoom.bind(this));
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    const deltaX = clientX - this.lastPointerPosition.x;
    const deltaY = clientY - this.lastPointerPosition.y;

    const deltaTheta = deltaX * 0.05;
    const deltaPhi = deltaY * 0.05;

    if(this.canMoveCamera)
      this.orbitalCamera.rotate(deltaTheta, deltaPhi);

    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    const delta = event.deltaY * 0.05;
    this.orbitalCamera.zoom(delta);
  }

  lerp_model() {
    this.spot.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));
    this.spot.position.set(40, 25, 65);
    this.spot2.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));
    this.spot2.position.set(40, 25, -65);
  }
  trigger_parachute() {
    console.log('spawn one parachute');
    //this.addParachute();
  }
  Update() {
    //update delta time
    const now = performance.now();
    this.dt = (now - this.lastUpdate); // Delta time in seconds
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime); // Total time in seconds
  
    for (let i = this.fireworks.length - 1; i >= 0; i--) {
      if (this.fireworks[i].done) {
        this.fireworks.splice(i, 1);
      } else {
        this.fireworks[i].update();
      }
    }
  
    this.lerp_model();
    if (this.fullLoaded) {
      this.updateHelicopter();
  
      // update parachute
      for (var i = 0; i < this.parachutePoints.length; ++i) {
        this.updateParachute(this.parachutePoints[i], this.parachutePoints[i].modelTime);
        if (this.parachutePoints[i]) {
          this.parachutePoints[i].modelTime += this.dt / 100;
          if (this.parachutePoints[i].modelTime > 199.0) {
            this.parachutePoints[i].modelTime = 0.0;
          }
        }
      }
  
      //update jet
      for (var i = 0; i < this.jetPoints.length; ++i) {
        this.updateJet(this.jetPoints[i], this.jetPoints[i].modelTime);
        if (this.jetPoints[i]) {
          this.jetPoints[i].modelTime += this.dt / 40;
          if (this.jetPoints[i].modelTime > 199.0) {
            this.jetPoints[i].modelTime = 0.0;
          }
        }
      }
  
      //update platform
      for (var i = 0; i < this.platformPoints.length; ++i) {
        this.updatePlatform(this.platformPoints[i], this.platformPoints[i].modelTime);
        if (this.platformPoints[i]) {
          if (this.platformPoints[i].modelTime < 199) {
            //this.platformPoints[i].modelTime += this.dt / 40;
  
          }
        }
      }
    }
  
    if (this.storeTop > 0.1 && this.getState() === false) {
      //console.log("scroll target : " + this.storeTop);
      //document.getElementById('scc').scrollTop = this.storeTop;
      // this.storeTop = 0;
    }
    // const t2 = document.getElementById('scc').scrollTop / window.innerHeight / 2;
    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
    if (this.getState() === false) {
      this.append = 0;
      if (this.detectDevice() == 'computer') {
        this.lerpedMouse.x = MathUtils.lerp(this.lerpedMouse.x, this.mouseNormalized.x, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouse.y = MathUtils.lerp(this.lerpedMouse.y, this.mouseNormalized.y, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.x = MathUtils.lerp(this.lerpedMouseRaw.x, this.mouse.x, 0.01 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.y = MathUtils.lerp(this.lerpedMouseRaw.y, this.mouse.y, 0.01 * 1 / this.dt * 1.5);
      }
    }
  
    //get page position and lerp camera 
  
    this.pageLerp = 0.005;
    this.pageLerp2 = 0.005;
    this.pageLerp3 = 0.005;
  
    var index = MathUtils.clamp(this.pageLerp2, 0.0, 0.99);
  
    let currPoint = this.points[parseInt(index * 200, 10)];
    let currPointLookAt = this.lookAtPoints[parseInt(index * 200, 10)];
    let currPointDrone = this.dronePoints[parseInt(index * 200, 10)];
    this.tempPoint = currPoint;
    this.tempPointLookAt = currPointLookAt;
  
    this.sendPoint.copy(this.camPos);
    this.sendPoint.add(currPoint);
    this.sendPoint.add(this.camDirection);
    this.sendPoint.add(this.camSide);
    this.sendPoint.add(this.camUp);
  
    this.currDronePoint.copy(new Vector3(0, 0, 0));
    this.currDronePoint.add(currPointDrone);
  
    this.sendPointLookAt.copy(this.camLookAt);
    this.sendPointLookAt.add(currPointLookAt);
  
  
    this.currPointLerped.lerp(this.sendPoint, 0.05);
    this.dronePointLerped.lerp(this.currDronePoint, 0.01);
  
    this.currPointLookAtLerped.lerp(this.sendPointLookAt, 0.05);
  
    if (this.getState() === 0) {
      this.clickTimeout++;
    } else {
      this.clickTimeout = 0;
    }
    
    // Update orbital camera
    this.orbitalCamera.update();
    // Ensure the Three.js camera is updated
    this.newCamera.threeCamera.position.copy(this.orbitalCamera.camera.position);
    this.newCamera.threeCamera.lookAt(this.orbitalCamera.target);
  
    // Force the camera to update its matrix
    this.newCamera.threeCamera.updateMatrixWorld(true);
  
    let targeted = false;
    // Optionally, update the camera target if needed
    if (this.jetPoints.length > 0) {
      if (this.jetPoints.at(0)) {
        this.orbitalCamera.setTarget(this.jetPoints[0].model.position);
        targeted = true;
      }
    }
    if (targeted == false) {
      this.orbitalCamera.target = new Vector3(0, -2, 0);
    }
    if (document.getElementById('scontent')) {
  
      // const contentTop = document.getElementById('scontent').scrollTop / window.innerHeight / 2;
      // console.log(contentTop);
    }
  
    // Update hover animation
    this.updateHoverAnimation();
  
    this.boot = true;
  }
  

  // Update updateHoverAnimation method
  updateHoverAnimation() {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (this.mouse.x / window.innerWidth) * 2 - 1;
    mouse.y = - (this.mouse.y / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
    raycaster.layers.set(COLLISION_LAYER); // Set raycaster layer to collision layer
    const intersects = raycaster.intersectObjects(this.scene.children, true);


    if (intersects.length > 0 && this.hoverAnimationsEnabled) {
      const intersectedObject = intersects[0].object;

      if (intersectedObject.name.startsWith('collision_')) {
        const textName = intersectedObject.name.replace('collision_', 'text_');
        const textObject = this.scene.getObjectByName(textName);

        if (textObject) {
          if (this.hoveredText !== textObject) {
            if (this.hoveredText) {
              this.resetTextAnimation(this.hoveredText);
            }
            this.hoveredText = textObject;
            this.animateTextHover(textObject);
          }
        }
      } else if (this.hoveredText) {
        this.resetTextAnimation(this.hoveredText);
        this.hoveredText = null;
      }
    } else if (this.hoveredText) {
      this.resetTextAnimation(this.hoveredText);
      this.hoveredText = null;
    }
  }

  animateTextHover(textObject) {
    if (!textObject.userData.initialPosition) {
      textObject.userData.initialPosition = textObject.position.clone();
    }
    gsap.to(textObject.position, { y: textObject.userData.initialPosition.y + 1, duration: 0.5, yoyo: true, repeat: -1 });
    gsap.to(textObject.scale, { x: 1.2, y: 1.2, z: 1.2, duration: 0.5, yoyo: true, repeat: -1 });
  }

  resetTextAnimation(textObject) {
    gsap.killTweensOf(textObject.position);
    gsap.killTweensOf(textObject.scale);
    gsap.to(textObject.position, { y: textObject.userData.initialPosition.y, duration: 0.5 });
    gsap.to(textObject.scale, { x: 1, y: 1, z: 1, duration: 0.5 });
  }


}

export default React.forwardRef((props, ref) => <Island {...props} ref={ref} />);
