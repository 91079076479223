import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Button, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box } from '@mui/material';
import { useScroll } from 'framer-motion';
import gsap from 'gsap';

import './Home.css';
import ContentUob from './ContentUob';
import TopbarParade from '../components/TopBarParade';
import Bottombar from '../components/BottomBar';
import LoadingManager from '../Render/LoadingManager';
import Island from 'src/components/Scenes/Island';
import Overlay from './Overlay';

import ParachuteIcon from '@mui/icons-material/Paragliding';
import HelicopterIcon from '@mui/icons-material/AirplanemodeActive';
import PlatformIcon from '@mui/icons-material/Landscape';
import JetIcon from '@mui/icons-material/FlightTakeoff';
import NightIcon from '@mui/icons-material/Brightness3';
import FlareIcon from '@mui/icons-material/Flare';

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const IslandPage: React.FC = () => {
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const islandRef = useRef<any>(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const buttonColors = {

    fireworksRight: '#f54254', // Pastel pink
    parachute: '#ed8434', // Pastel red
    helicopter: '#19c5cf', // Pastel orange
    platform: '#98cf21', // Pastel blue
    jet: '#98cf21', // Pastel green
    fireworksLeft: '#c23ca0', // Pastel purple
    dayNightToggle: '#5221cf' // Pastel yellow for day/night toggle

  };

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  }

  const { scrollYProgress } = useScroll();

  const handleButtonClick = (action: string) => {
    if (islandRef.current && islandRef.current[action]) {
      islandRef.current[action]();
    }
  };

  useEffect(() => {
    if (islandRef.current) {
      islandRef.current.setButtonVisibilityCallback(() => {
        setShowButtons(true);
        const buttons = document.querySelectorAll('.action-button');
        buttons.forEach((button, index) => {
          gsap.fromTo(button, { scale: 0 }, { scale: 1, duration: 0.5, delay: index * 0.1 });
        });
      });
    }
  }, []);

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{ position: 'fixed', zIndex: -1 }}>
          <Island
            ref={islandRef}
          />
        </Grid>

        <HideOnScroll>
          <AppBar position={'fixed'} sx={{ boxShadow: 'none', backgroundColor: '#00000000' }}>
            <TopbarParade />
          </AppBar>
        </HideOnScroll>

        <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
          <Bottombar />
        </AppBar>

        <Overlay show={showOverlay} />

        <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
          {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Button
              variant='contained'
              onClick={() => {
                setInfoValue(contentValue);
                setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
              }}
              sx={{
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 100,
                mb: '30px',
                height: '40px',
                fontFamily: 'DMSans',
                fontSize: '1.0rem',
                fontWeight: '100',
                color: '#000000',
                backgroundColor: '#ffffff',
                borderRadius: '30px',
                boxShadow: 'none',
              }}>
              Click to see {buttonInfo}
            </Button>
          }
          {infoValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <ContentUob
              content_panel_value={contentValue}
              content_open={contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN}
              content_close={CloseContent}
            />
          }
          <Box component="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: 2 }}>
          </Box>
        </Grid>

        {showButtons && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              position: 'fixed',
              top: '90%', // 3/4 of the screen height
              left: '50%', // Center horizontally
              transform: 'translate(-50%, -50%)', // Center transform
              zIndex: 1, // Ensure the buttons are above the scene
            }}
          >
            <Grid item>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => handleButtonClick('triggerFireworksRight')}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: buttonColors.fireworksRight,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FlareIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => handleButtonClick('addParachute')}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: buttonColors.parachute,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ParachuteIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => handleButtonClick('triggerHelicopter')}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: buttonColors.helicopter,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <HelicopterIcon />
              </Button>
            </Grid>
            
            <Grid item>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => handleButtonClick('addJet')}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: buttonColors.jet,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <JetIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => handleButtonClick('triggerFireworksLeft')}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: buttonColors.fireworksLeft,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FlareIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="action-button"
                variant="contained"
                onClick={() => handleButtonClick('toggleDayNight')}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: buttonColors.dayNightToggle,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NightIcon />
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default IslandPage;
