import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const STEPS = [
    {
        title: 'Mobile-First Design',
        description: 'We start building your site for mobile devices first, then we add on to it to make tablet and desktop.',
        icon: '/assets/icons/ic_resume_job.svg',
        color: '#87cdff'
    },
    {
        title: 'Fully Responsive',
        description: 'Your website will fit all mobile screens sizes, tablets, and desktop sizes so new clients can access your site from anywhere.',
        icon: '/assets/icons/ic_resume_job.svg',
        color: '#ffba6b'
    },
    {
        title: 'Optimization',
        description: '60% of all internet traffic is on mobile devices, so we optimize your mobile to perform their best in search engines.',
        icon: '/assets/icons/ic_resume_job.svg',
        color: '#ff5ec9'
    },
];

// ----------------------------------------------------------------------

export default function Info() {
    return (
        <Box
            sx={{
                textAlign: 'center',
                pt: { xs: 10, md: 15 },
                pb: { xs: 5, md: 10 },

            }}
        >
            <Container >
                <Grid item>
                    <Typography color={"#cebaff"} fontFamily="Glacial" variant="h3" fontWeight="900" sx={{ my: 3 }}>
                        What Do We Build
                    </Typography>
                </Grid>
                <Typography fontSize='1.25rem' fontFamily="Glacial" variant="subtitle1" fontWeight="100" sx={{ color: '#ffffff', maxWidth: 550, mx: 'auto' }}>
                    We specialize in <b>transforming your ideas</b> into captivating digital experiences, combining cutting-edge design with seamless functionality to create websites that not only look stunning but also drive <b>engagement and conversions</b>.
                </Typography>

                <Box
                    sx={{
                        display: 'grid',
                        my: { xs: 8, md: 10 },
                        gap: { xs: 8, md: 5 },
                        backgroundColor: '#2f1c4d44',
                        borderRadius: '25px',
                        borderColor: '#613c99ff',
                        border: '#613c99ff',
                        textAlign: 'center',
                        borderStyle: 'double',

                        borderWidth: '2px 2px 2px 2px',
                        gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            md: 'repeat(3, 1fr)',
                        },
                    }}
                >
                    {STEPS.map((value, index) => (
                        <div key={value.title}>
                            <Box
                                sx={{

                                    minHeight: '300px',
                                    borderColor: value.color,
                                    gap: 4,

                                    padding: '10px',
                                    paddingTop: '50px',
                                    gridTemplateColumns: {
                                        xs: 'repeat(1, 1fr)',
                                        sm: 'repeat(2, 1fr)',
                                        md: 'repeat(4, 1fr)',
                                    },
                                }}>
                                <SvgColor
                                    src={value.icon}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        mx: 'auto',
                                        color: value.color,
                                    }}
                                />


                                <Typography variant="h5" fontWeight='900' sx={{ mt: 2, mb: 1 }}>
                                    {value.title}
                                </Typography>

                                <Typography variant="body2" sx={{ color: '#ffffff' }}>
                                    {value.description}
                                </Typography>
                            </Box>
                        </div>
                    ))}
                </Box>
            </Container >
        </Box >
    );
}
