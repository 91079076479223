import React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';

interface GameOverProps {
  onRestart: () => void;
  score: number;
}

const GameOver: React.FC<GameOverProps> = ({ onRestart, score }) => {
  return (
    <Modal open={true}>
      <Box
        sx={{
          backdropFilter: 'blur(10px)',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <Typography fontFamily={'roboto-mono'} variant="h3" sx={{ mb: 2, fontWeight: 'bold' }}>
          Game Over
        </Typography>
        <Typography fontFamily={'roboto-mono'} variant="h6" sx={{ mb: 4 }}>
          Final Score: {score}
        </Typography>
        <Button
          variant="contained"
          sx={{
            fontWeight: 1000,
            width: 200,
            height: 50,
            bgcolor: 'white',
            color: 'black',
            '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
            borderRadius: 2,
            fontFamily: 'roboto-mono',
          }}
          onClick={onRestart}
        >
          Start Over
        </Button>
      </Box>
    </Modal>
  );
};

export default GameOver;
