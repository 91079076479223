

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

const Topbar = () => {
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Link
                    underline="none"
                    component="a"
                    href="/"
                    fontFamily={'Glacial'}
                    style={{ letterSpacing: '5px' }}
                >
                    <Typography fontFamily="Glacial"
                        paddingBottom="30px"
                        paddingTop="25px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'0.0rem'}
                        fontSize="1.95rem"
                        fontWeight="100"
                        fontStyle={'regular'}>
                        artcode.
                    </Typography>


                </Link>
            </Box>

            <Box paddingRight="0px">
                <Link
                    underline="none"
                    component="a"
                    href="mailto: theon@artcodestudio.co" target="_blank"

                    fontFamily={'Glacial'}
                    style={{ letterSpacing: '5px' }}
                >
                    <Button size="large" variant="contained" sx={{ borderRadius: '30px', color: '#000000', backgroundColor: '#ffffff' }}>
                        Contact Us
                    </Button>
                </Link>
            </Box>

        </Box>
    );
};

Topbar.propTypes = {
};

export default Topbar;
