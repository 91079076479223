import React from 'react';
import { Box, Typography } from '@mui/material';
import gsap from 'gsap';

interface OverlayProps {
  show: boolean;
}

const Overlay: React.FC<OverlayProps> = ({ show }) => {
  React.useEffect(() => {
    if (show) {
      gsap.to(".overlay", { y: 0, duration: 0.8, ease: "power4.inOut" });
      gsap.fromTo(
        ".overlay-title",
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, delay: 1, duration: 1, ease: "power4.inOut" }
      );
      gsap.fromTo(
        ".overlay-info",
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, delay: 1.5, duration: 1, ease: "power4.inOut" }
      );
    } else {
      gsap.to(".overlay", { y: "-100%", duration: 0.8, ease: "power4.inOut" });
    }
  }, [show]);

  return (
    <Box
      className="overlay"
      sx={{
        position: 'fixed',
        top: '-100%',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
    
    </Box>
  );
};

export default Overlay;
