import * as THREE from 'three';

export class BulletEmitter {
    constructor(scene, position) {
        this.scene = scene;
        this.position = position;
        this.geometry = new THREE.BoxGeometry(0.05, 0.05, 0.5); // Small elongated box for the trail
        this.material = new THREE.MeshBasicMaterial({
            color: 0xf7e1b0,
       
        });

        this.particles = [];
    }

    shootBullet() {
        for (let i = 0; i < 1; i++) { // Fire 5 bullets per shot
            const bullet = new THREE.Mesh(this.geometry, this.material);
            bullet.position.copy(this.position);

            // Set initial velocity with slight randomness
            bullet.velocity = new THREE.Vector3(
                THREE.MathUtils.randFloat(-0.05, 0.05),
                0.25, // Speed upwards
                THREE.MathUtils.randFloat(-0.05, 0.05)
            );

            bullet.lifetime = 500; // Number of frames the bullet exists
            this.scene.add(bullet);
            this.particles.push(bullet);
        }
    }

    update() {
        this.particles.forEach((bullet, index) => {
            bullet.position.add(bullet.velocity);
            bullet.lifetime--;

            // Stretch the bullet mesh to create a trail effect
            const length = 1.0 + (100 - bullet.lifetime) * 0.02;
            bullet.scale.set(1, 1, length);

            // Rotate to align with the direction of motion
            bullet.lookAt(bullet.position.clone().add(bullet.velocity));

            // Remove the bullet if its lifetime ends
            if (bullet.lifetime <= 0) {
                this.scene.remove(bullet);
                this.particles.splice(index, 1);
            }
        });
    }
}

export class Gunner {
    constructor(scene, position) {
        this.scene = scene;
        this.position = position;
        this.emitter = new BulletEmitter(scene, position);
        this.isFiring = false;

        this.shotInterval = 200; // Time between each shot in a burst (ms)
        this.pauseBetweenBursts = THREE.MathUtils.randInt(2000, 5000); // Random pause time between bursts (ms)
    }

    startFiring() {
        if (!this.isFiring) {
            this.isFiring = true;
            this.fireBurst();
        }
    }

    fireBurst() {
        const burstCount = THREE.MathUtils.randInt(5, 10); // Random number of shots per burst
        let shotsFired = 0;

        const fireInterval = setInterval(() => {
            if (shotsFired < burstCount) {
                this.emitter.shootBullet();
                shotsFired++;
            } else {
                clearInterval(fireInterval);
                setTimeout(() => {
                    this.fireBurst(); // Restart the next burst after a pause
                }, this.pauseBetweenBursts);
            }
        }, this.shotInterval);
    }

    update() {
        this.emitter.update();
    }
}
